import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  largeIcon: {
    width: 40,
    height: 40,
    color: "rgb(94, 94, 94)",
  },
  usernameText: {
    fontWeight: "bold",
    color: "rgb(255, 163, 15)",
  },
  roleText: {
    color: "rgb(94, 94, 94)",
  },
}));

const UserAvatar = (props) => {
  const { t } = useTranslation("administration_bar");
  const classes = useStyles();

  let role = "User";
  if (props.isAdmin) {
    role = "Administrator";
  }
  return (
    <React.Fragment>
      <Box display={{ xs: "none", lg: "block", md: "block" }}>
        <IconButton color="inherit">
          <AccountCircle className={classes.largeIcon} />
        </IconButton>
      </Box>

      <Box display={{ xs: "none", lg: "block", md: "block" }}>
        <span className={classes.usernameText}>
          {props.firstName + " " + props.lastName}
        </span>
        <br />
        <span className={classes.roleText}>{t(role)}</span>
      </Box>
    </React.Fragment>
  );
};

export default UserAvatar;
