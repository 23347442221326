import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

import { SERVER_URL } from "../utils/api_calls/common/routes";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    border: "1px solid black",
    borderRadius: "0px",
    height: "255px",
  },
  machineImage: {
    maxHeight: "160px",
    maxWidth: "160px",
    [theme.breakpoints.between("md", "lg")]: {
      maxHeight: "120px",
      maxWidth: "120px",
    },
  },
  machineTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    marginTop: "4px",
    wordBreak: "break-all",
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "20px",
    },
  },
  componentRisk: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 26,
  },
  componentRiskTitle: {
    textAlign: "center",
    color: "rgb(94, 94, 94)",
    fontSize: 11,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  machineRisk: {
    marginTop: "-25px",
  },
}));

const MachineImage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("predictive");

  let riskStyle;
  switch (props.risk) {
    case 1:
      riskStyle = { color: "green" };
      break;
    case 2:
      riskStyle = { color: "#ffcc80" };
      break;
    case 3:
      riskStyle = { color: "red" };
      break;
    default:
      riskStyle = { color: "green" };
  }

  const borderStyle = {
    borderLeft: "8px solid " + props.stateColor,
    width: "100%",
    height: "110%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Card className={classes.card}>
      <div style={borderStyle}>
        <div className={classes.machineImageInnerContainer}>
          <div className={classes.machineRisk}>
            <Box>
              <Box className={classes.componentRiskTitle}>
                {t("MachineImage.risk")}
                <br />
              </Box>
              <Box className={classes.componentRisk} style={riskStyle}>
                {props.risk_description}
              </Box>
            </Box>
          </div>
          <img
            className={classes.machineImage}
            src={SERVER_URL + props.img_path}
            alt={"comp"}
          />
          <div className={classes.machineTitle}>{props.title}</div>
        </div>
      </div>
    </Card>
  );
};

export default MachineImage;
