// React imports
import React from "react";

// Third-Party imports
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "rgb(237, 237, 237)",
    height: "90%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  text: {
    flexGrow: 4,
    fontSize: 20,
  },
  link: {
    textDecoration: "none",
    color: "Black",
  },
}));

const BlankView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.content}>
      <div className={classes.text}>
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/"
        >
          {t("BlankView.UpgradeYourPlan")}
        </a>
      </div>
    </div>
  );
};

export default BlankView;
