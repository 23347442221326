import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MoreVert from "@material-ui/icons/MoreVert";

import MoreOptionsMenu from "./MoreOptionsMenu";

const useStyles = makeStyles((theme) => ({
  largeIcon: {
    width: 40,
    height: 40,
    color: "rgb(94, 94, 94)",
  },
  largeIconContainer: {
    padding: "12px 8px",
  },
}));

const MoreOptionsButton = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={handleClick}
        className={classes.largeIconContainer}
      >
        <Badge badgeContent={props.numberOfOpenAlerts} color="secondary">
          <MoreVert className={classes.largeIcon} />
        </Badge>
      </IconButton>

      <MoreOptionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        numberOfOpenAlerts={props.numberOfOpenAlerts}
        loggedUser={props.loggedUser}
        alertsHash={props.alertsHash}
        distributorsWithGroups={props.distributorsWithGroups}
        on_logout_click={props.on_logout_click}
        user_profile={props.user_profile}
      />
    </div>
  );
};

export default MoreOptionsButton;
