import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Delete from "@material-ui/icons/Delete";
import Restore from "@material-ui/icons/Restore";
import Edit from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core";

import SearchField from "../../utils/components/SearchField";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import ContractPlansModal from "./ContractPlansModal";
import InformationModal from "../../utils/components/InformationModal";

import { useTranslation } from "react-i18next";

import ReactTable from "react-table-6";
import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
}));

const ContractPlansView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [showNonActiveContractPlans, showNonActiveContractPlansSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [editContractPlanModalOpen, editContractPlanModalSetState] =
    React.useState(false);
  const [showNotAbleToDeleteModal, setShowNotAbleToDeleteModal] =
    React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);

  const get_contract_plans = props.getContractPlans;
  const distributors = props.distributors;
  const get_distributors = props.getDistributors;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_contract_plans(auth_token);
  }, [get_contract_plans, auth_token]);

  React.useEffect(() => {
    get_distributors(auth_token);
  }, [get_distributors, auth_token]);

  const distributorsHavePlan = (contract_plan_id) => {
    let haveIt = false;
    distributors.forEach((dist, i) => {
      if (dist.contract_plan !== null) {
        if (dist.contract_plan.id === contract_plan_id) {
          haveIt = true;
        }
      }
    });
    return haveIt;
  };

  try {
    let contract_plans = props.contractPlans;
    if (!showNonActiveContractPlans) {
      contract_plans = contract_plans.filter(
        (contract_plan) => contract_plan.is_active
      );
    }

    contract_plans = contract_plans.filter((contract_plan) =>
      dict_contains_search_input(contract_plan, props.searchInput)
    );

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={3} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newUserButton}
              onClick={() => editContractPlanModalSetState(true)}
            >
              <div style={{ textAlign: "right" }}>
                {t("contractPlansView.newContractPlanButton")}
              </div>
            </Button>
          </Grid>
          <Grid item lg={5} md={2} xs={false} />
          <Grid item lg={4} md={5} xs={6}>
            <div style={{ textAlign: "right" }}>
              {t("contractPlansView.showSetDownContractPlans")}
            </div>
            <Switch
              className={classes.showSetDownUsers}
              checked={showNonActiveContractPlans}
              onChange={(event) =>
                showNonActiveContractPlansSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={contract_plans}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            if (distributorsHavePlan(row._original.id)) {
                              setShowNotAbleToDeleteModal(true);
                            } else {
                              selectedElementSetState(row._original);
                              deleteModalSetState(true);
                            }
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            props.updateContractPlanState(
                              row._original.id,
                              true
                            );
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => (
                    <Edit
                      className={classes.icon}
                      onClick={() => {
                        selectedElementSetState(row._original);
                        editContractPlanModalSetState(true);
                      }}
                    />
                  ),
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("contractPlansView.id"),
                  accessor: "id",
                },
                {
                  Header: t("contractPlansView.name"),
                  accessor: "name",
                },
                {
                  Header: t("contractPlansView.max_users"),
                  accessor: "max_users",
                  Cell: ({ row }) => {
                    if (row._original.max_users === -1) {
                      return (
                        <div>
                          {t(
                            "contractPlansView.contractPlansModal.infinite_users"
                          )}
                        </div>
                      );
                    } else {
                      return <div>{row._original.max_users}</div>;
                    }
                  },
                },
                {
                  Header: t("contractPlansView.max_groups"),
                  accessor: "max_groups",
                  Cell: ({ row }) => {
                    if (row._original.max_groups === -1) {
                      return (
                        <div>
                          {t(
                            "contractPlansView.contractPlansModal.infinite_groups"
                          )}
                        </div>
                      );
                    } else {
                      return <div>{row._original.max_groups}</div>;
                    }
                  },
                },
                {
                  Header: t("contractPlansView.max_machines"),
                  accessor: "max_machines",
                  Cell: ({ row }) => {
                    if (row._original.max_machines === -1) {
                      return (
                        <div>
                          {t(
                            "contractPlansView.contractPlansModal.infinite_machines"
                          )}
                        </div>
                      );
                    } else {
                      return <div>{row._original.max_machines}</div>;
                    }
                  },
                },
              ]}
              defaultSorted={[
                {
                  id: "name",
                  desc: false,
                },
              ]}
              loading={props.loadingContractPlans}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="viewsTable"
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <ContractPlansModal
          open={editContractPlanModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            editContractPlanModalSetState(false);
          }}
          contractPlans={contract_plans}
          contractPlanData={selectedElement}
          createOrUpdateContractPlan={props.createOrUpdateContractPlan}
        />
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateContractPlanState(selectedElement.id, false);
            deleteModalSetState(false);
            selectedElementSetState(null);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
        <InformationModal
          open={showNotAbleToDeleteModal}
          handleClose={() => setShowNotAbleToDeleteModal(false)}
          title={t("notAbleToDeleteModal.title")}
          description={t("notAbleToDeleteModal.description")}
          buttonDescription={t("notAbleToDeleteModal.accept")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default ContractPlansView;
