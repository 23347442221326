import React from "react";

import { push } from "connected-react-router";
import { connect } from "react-redux";

import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import ReorderIcon from "@material-ui/icons/Reorder";
import FastForwardIcon from "@material-ui/icons/FastForward";
import Divider from "@material-ui/core/Divider";

import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

import { is_predictive_module } from "../utils/js/common_functions";

const useStyles = makeStyles((theme) => ({
  icons: {
    color: "rgb(133, 187, 19)",
    width: 30,
    height: 30,
  },
  listItem: {
    paddingLeft: "22px",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  link: {
    fontWeight: "bold",
    color: "rgb(152, 152, 152)",
    fontSize: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selected: {
    color: "white",
  },
  selected_item: {
    paddingLeft: "16px",
    borderLeft: "6px solid rgb(255, 163, 15)",
  },
  divider: {
    height: "2px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
}));

const NavigationLinks = (props) => {
  const { t } = useTranslation("navigation_bar");
  const classes = useStyles();

  const selected = props.view_path;
  return (
    <React.Fragment>
      <ListItem
        className={clsx(
          classes.listItem,
          selected === "Dashboard" && classes.selected_item
        )}
        button
        onClick={() => props.push("/")}
      >
        <ListItemIcon>
          <DashboardIcon className={classes.icons} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          className={clsx(
            classes.link,
            selected === "Dashboard" && classes.selected
          )}
          primary={t("Dashboard")}
        />
      </ListItem>

      <Divider className={classes.divider} />

      <ListItem
        className={clsx(
          classes.listItem,
          selected === "Machines List" && classes.selected_item
        )}
        button
        onClick={() => props.push("/machine_list")}
      >
        <ListItemIcon>
          <ReorderIcon className={classes.icons} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          className={clsx(
            classes.link,
            selected === "Machines List" && classes.selected
          )}
          primary={t("Machines List")}
        />
      </ListItem>

      <Divider className={classes.divider} />

      <ListItem
        className={clsx(
          classes.listItem,
          selected === "Scoring" && classes.selected_item
        )}
        button
        onClick={() => props.push("/scoring")}
      >
        <ListItemIcon>
          <ScatterPlotIcon className={classes.icons} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          className={clsx(
            classes.link,
            selected === "Scoring" && classes.selected
          )}
          primary={t("Scoring")}
        />
      </ListItem>

      <Divider className={classes.divider} />

      {is_predictive_module(
        props.distributor_contract_plan.contract_plan,
        props.isDistributorAdmin
      ) && (
        <React.Fragment>
          <ListItem
            className={clsx(
              classes.listItem,
              selected === "Predictive" && classes.selected_item
            )}
            button
            onClick={() => props.push("/predictive")}
          >
            <ListItemIcon>
              <FastForwardIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx(
                classes.link,
                selected === "Predictive" && classes.selected
              )}
              primary={t("Predictive")}
            />
          </ListItem>

          <Divider className={classes.divider} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(null, { push })(NavigationLinks);
