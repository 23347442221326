import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers/root_reducer";
import { RESET_APP } from "./actions/root_action_types";

export const history = createBrowserHistory();

const configureStore = (preloadedState) => {
  var rootReducer = createRootReducer(history);

  const rootReducerWithReset = (state, action) => {
    if (action.type === RESET_APP) {
      state = undefined;
    }
    return rootReducer(state, action);
  };

  const store = createStore(
    rootReducerWithReset,
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history)))
  );
  return store;
};

export default configureStore;
