import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Delete from "@material-ui/icons/Delete";
import Restore from "@material-ui/icons/Restore";
import Edit from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core";

import SearchField from "../../utils/components/SearchField";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import MachineSectorsModal from "./MachineSectorsModal";

import { useTranslation } from "react-i18next";

import ReactTable from "react-table-6";
import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
}));

const MachineSectorsView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [showNonActiveMachineSectors, showNonActiveMachineSectorsSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [editMachineSectorsModalOpen, editMachineSectorsModalSetState] =
    React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);

  const get_machine_sectors_types = props.getMachineSectorsTypes;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_machine_sectors_types(auth_token);
  }, [get_machine_sectors_types, auth_token]);

  try {
    let machine_sectors_types = props.machineSectorsTypes;
    if (!showNonActiveMachineSectors) {
      machine_sectors_types = machine_sectors_types.filter(
        (machine_sector) => machine_sector.is_active
      );
    }
    machine_sectors_types = machine_sectors_types.filter((machine_sector) =>
      dict_contains_search_input(machine_sector, props.searchInput)
    );

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={3} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newUserButton}
              onClick={() => editMachineSectorsModalSetState(true)}
            >
              <div style={{ textAlign: "right" }}>
                {t("machineSectorsView.newMachineSectorsButton")}
              </div>
            </Button>
          </Grid>
          <Grid item lg={5} md={2} xs={false} />
          <Grid item lg={4} md={5} xs={6}>
            <div style={{ textAlign: "right" }}>
              {t("machineSectorsView.showMachineSectorsChanges")}
            </div>
            <Switch
              className={classes.showSetDownUsers}
              checked={showNonActiveMachineSectors}
              onChange={(event) =>
                showNonActiveMachineSectorsSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={machine_sectors_types}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            selectedElementSetState(row._original);
                            deleteModalSetState(true);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            props.updateMachineSectorsState(
                              row._original.machine_sectors_id,
                              true
                            );
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => (
                    <Edit
                      className={classes.icon}
                      onClick={() => {
                        selectedElementSetState(row._original);
                        editMachineSectorsModalSetState(true);
                      }}
                    />
                  ),
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("machineSectorsView.description") + " ES",
                  accessor: "description_es",
                },
                {
                  Header: t("machineSectorsView.description") + " EN",
                  accessor: "description_en",
                },
                {
                  Header: t("machineSectorsView.description") + " CA",
                  accessor: "description_ca",
                },
              ]}
              defaultSorted={[
                {
                  id: "description_es",
                  desc: false,
                },
              ]}
              loading={props.loadingMachineSectorsTypes}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="viewsTable"
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <MachineSectorsModal
          open={editMachineSectorsModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            editMachineSectorsModalSetState(false);
          }}
          machineSectorsData={selectedElement}
          createOrUpdateMachineSectors={props.createOrUpdateMachineSectors}
        />
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateMachineSectorsState(
              selectedElement.machine_sectors_id,
              false
            );
            deleteModalSetState(false);
            selectedElementSetState(null);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default MachineSectorsView;
