import React from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  mapStateToPropsAdministrationModal,
  mapDispatchToPropsAdministrationModal,
} from "../utils/redux/mappers/administration_modals_mappers";

import UsersView from "./administration_modals/UsersView";
import DistributorsView from "./administration_modals/DistributorsView";
import GroupsView from "./administration_modals/GroupsView";
import AlertChangesView from "./administration_modals/AlertChangesView";
import MachineSectorsView from "./administration_modals/MachineSectorsView";
import ContractPlansView from "./administration_modals/ContractPlansView";
import MachinesView from "./administration_modals/MachinesView";
import ManualHeuristicView from "./administration_modals/ManualHeuristicView";
import Close from "@material-ui/icons/Close";

const USERS_VIEW = "USERS_VIEW";
const DISTRIBUTORS_VIEW = "DISTRIBUTORS_VIEW";
const GROUPS_VIEW = "GROUPS_VIEW";
const ALERT_CHANGES_VIEW = "ALERT_CHANGES_VIEW";
const MACHINE_SECTORS_VIEW = "MACHINE_SECTORS_VIEW";
const CONTRACT_PLANS_VIEW = "CONTRACT_PLANS_VIEW";
const MACHINES_VIEW = "MACHINES_VIEW";
const MANUAL_HEURISTIC_VIEW = "MANUAL_HEURISTIC_VIEW";

const useStyles = makeStyles((theme) => ({
  administrationModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: "721px",
    width: "1200px",
    outline: "none",
    [theme.breakpoints.down("md")]: {
      height: "80%",
      width: "80%",
      minHeight: "120px",
      maxHeight: "200px",
      overflowY: "hidden",
    },
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "32px",
  },
  administrationButton: {
    height: 80,
    borderBottom: "1px solid rgb(208, 208, 208)",
    display: "flex",
    cursor: "pointer",
  },
  administrationButtonText: {
    margin: "auto",
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
  },
  selectedButton: {
    backgroundColor: "rgb(158, 158, 158)",
  },
  closeButton: {
    padding: "8px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  divider: {
    [theme.breakpoints.down("md")]: {
      margin: "0px 20px",
    },
  },
  unableToDisplayAdmin: {
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
}));

const AdministrationModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [searchInput, searchInputSetState] = React.useState("");
  const [currentView, currentViewRawSetState] = React.useState(GROUPS_VIEW);

  const currentViewSetState = (new_state) => {
    searchInputSetState("");
    currentViewRawSetState(new_state);
  };

  const auth_token = props.auth_token;

  let current_view;
  let is_user_admin = false;
  let is_distributor_admin = false;
  if (
    Object.keys(props.logged_user).length > 0 &&
    Object.keys(props.distributors_with_groups).length > 0
  ) {
    is_user_admin = props.logged_user.is_admin;
    is_distributor_admin =
      props.distributors_with_groups[props.logged_user.distributor].is_admin;

    if (currentView === USERS_VIEW && (is_distributor_admin || is_user_admin)) {
      current_view = (
        <UsersView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          users={props.users}
          loggedUser={props.logged_user.username}
          updateUserState={(username, is_active) =>
            props.update_user_state(auth_token, username, is_active)
          }
          distributorsWithGroups={props.distributors_with_groups}
          createOrUpdateUserProfile={(user_profile) =>
            props.create_or_update_user_profile(auth_token, user_profile)
          }
          getUsers={props.get_users}
          authToken={props.auth_token}
          resetPassword={props.reset_password}
          loadingUsers={props.loading_users}
          loadingUserCreateOrUpdate={props.loading_user_create_or_update}
          successfulReturnUserCreateOrUpdate={
            props.successful_return_user_create_or_update
          }
          loadingPasswordChangeRequest={props.loading_password_change_request}
          successfulReturnPasswordChangeRequest={
            props.successful_return_password_change_request
          }
          errorMsg={props.error_msg}
          distributor_contract_plan={props.distributor_contract_plan}
          clear_error_msg={props.clear_error_msg}
        />
      );
    } else if (currentView === DISTRIBUTORS_VIEW && is_distributor_admin) {
      current_view = (
        <DistributorsView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          distributors={props.distributors}
          nonActiveDistributors={props.non_active_distributors}
          updateDistributorState={(distributor_id, is_active) =>
            props.update_distributor_state(
              auth_token,
              distributor_id,
              is_active
            )
          }
          getDistributorMachines={(distributor_id, signal) =>
            props.get_non_active_distributor_machines(
              auth_token,
              distributor_id,
              signal
            )
          }
          distributorMachines={props.non_active_distributor_machines}
          loggedDistributor={props.logged_user.distributor}
          removeNonActiveDistributorMachines={
            props.remove_non_active_distributor_machines
          }
          getDistributors={props.get_distributors}
          getNonActiveDistributors={props.get_non_active_distributors}
          createOrUpdateDistributor={(distributor_data) =>
            props.create_or_update_distributor(auth_token, distributor_data)
          }
          authToken={props.auth_token}
          contractPlans={props.contract_plans}
          getContractPlans={props.get_contract_plans}
          loadingNonActiveDistributorMachines={
            props.loading_non_active_distributor_machines
          }
          loadingDistributors={props.loading_distributors}
        />
      );
    } else if (currentView === ALERT_CHANGES_VIEW && is_distributor_admin) {
      current_view = (
        <AlertChangesView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          getAlertChangeTypes={props.get_alert_change_types}
          alertChangeTypes={props.alert_change_types}
          updateAlertChangeState={(alert_change_id, is_active) =>
            props.update_alert_change_state(
              auth_token,
              alert_change_id,
              is_active
            )
          }
          newStateOptions={props.new_state_options}
          loadingAlertChangeTypes={props.loading_alert_change_types}
          createOrUpdateAlertChange={(alert_change_data) =>
            props.create_or_update_alert_change(auth_token, alert_change_data)
          }
          authToken={props.auth_token}
        />
      );
    } else if (
      currentView === GROUPS_VIEW &&
      (is_distributor_admin || is_user_admin)
    ) {
      current_view = (
        <GroupsView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          groups={props.groups}
          machines={props.machines}
          getDistributors={props.get_distributors}
          distributors={props.distributors}
          distributorsWithGroups={props.distributors_with_groups}
          updateGroupState={(group_id, is_active) =>
            props.update_group_state(auth_token, group_id, is_active)
          }
          createOrUpdateGroup={(group_data) =>
            props.create_or_update_group(auth_token, group_data)
          }
          getGroups={props.get_groups}
          authToken={props.auth_token}
          loadingGroups={props.loading_groups}
          distributor_contract_plan={props.distributor_contract_plan}
        />
      );
    } else if (currentView === MACHINE_SECTORS_VIEW && is_distributor_admin) {
      current_view = (
        <MachineSectorsView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          machineSectorsTypes={props.machine_sectors_types}
          getMachineSectorsTypes={props.get_machine_sectors_types}
          updateMachineSectorsState={(machine_sectors_id, is_active) =>
            props.update_machine_sectors_state(
              auth_token,
              machine_sectors_id,
              is_active
            )
          }
          loadingMachineSectorsTypes={props.loading_machine_sectors_types}
          createOrUpdateMachineSectors={(machine_sectors_data) =>
            props.create_or_update_machine_sectors(
              auth_token,
              machine_sectors_data
            )
          }
          authToken={props.auth_token}
        />
      );
    } else if (currentView === CONTRACT_PLANS_VIEW && is_distributor_admin) {
      current_view = (
        <ContractPlansView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          contractPlans={props.contract_plans}
          getContractPlans={props.get_contract_plans}
          distributors={props.distributors}
          getDistributors={props.get_distributors}
          updateContractPlanState={(contract_plan_id, is_active) =>
            props.update_contract_plan_state(
              auth_token,
              contract_plan_id,
              is_active
            )
          }
          loadingMachineSectorsTypes={props.loading_contract_plans}
          createOrUpdateContractPlan={(contract_plan_data) =>
            props.create_or_update_contract_plan(auth_token, contract_plan_data)
          }
          authToken={props.auth_token}
        />
      );
    } else if (
      currentView === MACHINES_VIEW &&
      (is_distributor_admin || is_user_admin)
    ) {
      current_view = (
        <MachinesView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          allDistributorMachines={props.all_distributor_machines}
          getAllDistributorMachines={props.get_all_distributor_machines}
          loadingAllDistributorMachines={props.loading_all_distributor_machines}
          updateMachinesState={(machines_dict) =>
            props.update_machines_state(auth_token, machines_dict)
          }
          authToken={props.auth_token}
          distributor_contract_plan={props.distributor_contract_plan}
        />
      );
    } else if (currentView === MANUAL_HEURISTIC_VIEW && is_distributor_admin) {
      current_view = (
        <ManualHeuristicView
          searchInput={searchInput}
          searchInputSetState={searchInputSetState}
          getDevicesExtraInfo={props.get_devices_extra_info}
          devicesExtraInfo={props.devices_extra_info}
          getHeuristicRules={props.get_heuristic_rules}
          heuristicRules={props.heuristic_rules}
          loadingHeuristicRules={props.loading_heuristic_rules}
          loadingDevicesExtraInfo={props.loading_devices_extra_info}
          getHeuristicTypes={props.get_heuristic_types}
          heuristicTypes={props.heuristic_types}
          loadingHeuristicTypes={props.loading_heuristic_types}
          alertLevels={props.alert_levels}
          getTimeUnits={props.get_time_units}
          timeUnits={props.time_units}
          loadingTimeUnits={props.loading_time_units}
          getStateDescriptions={props.get_state_descriptions}
          stateDescriptions={props.state_descriptions}
          loadingStateDescriptions={props.loading_state_descriptions}
          getRelationalOperators={props.get_relational_operators}
          relationalOperators={props.relational_operators}
          loadingRelationalOperators={props.loading_relational_operators}
          createOrUpdateHeuristicRule={(heuristic_rule_data) =>
            props.create_or_update_heuristic_rule(
              auth_token,
              heuristic_rule_data
            )
          }
          updateHeuristicRuleState={(heuristic_rule_id, is_active) =>
            props.update_heuristic_rule_state(
              auth_token,
              heuristic_rule_id,
              is_active
            )
          }
          authToken={props.auth_token}
        />
      );
    } else {
      current_view = <div>Unable to show view</div>;
    }
  } else {
    current_view = null;
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.administrationModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <h2 className={classes.alignCenter}>
            {t("administrationModal.title")}
          </h2>
          <Divider className={classes.divider} />
          <Box display={{ xs: "none", md: "none", lg: "block" }}>
            <div
              style={{
                borderRight: "1px solid rgb(208, 208, 208)",
                float: "left",
                width: "20%",
                height: "625px",
              }}
            >
              {is_distributor_admin && (
                <React.Fragment>
                  <div
                    onClick={() => currentViewSetState(USERS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === USERS_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.users")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(ALERT_CHANGES_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === ALERT_CHANGES_VIEW &&
                        classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.alertChanges")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(MACHINE_SECTORS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === MACHINE_SECTORS_VIEW &&
                        classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t(
                        "administrationModal.navigationButtons.machineSectors"
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(DISTRIBUTORS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === DISTRIBUTORS_VIEW &&
                        classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.distributors")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(CONTRACT_PLANS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === CONTRACT_PLANS_VIEW &&
                        classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.contractPlans")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(GROUPS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === GROUPS_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.groups")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(MACHINES_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === MACHINES_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.machines")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(MANUAL_HEURISTIC_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === MANUAL_HEURISTIC_VIEW &&
                        classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t(
                        "administrationModal.navigationButtons.manualHeuristic"
                      )}
                    </p>
                  </div>
                </React.Fragment>
              )}
              {is_user_admin && !is_distributor_admin && (
                <React.Fragment>
                  <div
                    onClick={() => currentViewSetState(USERS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === USERS_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.users")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(GROUPS_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === GROUPS_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.groups")}
                    </p>
                  </div>
                  <div
                    onClick={() => currentViewSetState(MACHINES_VIEW)}
                    className={clsx(
                      classes.administrationButton,
                      currentView === MACHINES_VIEW && classes.selectedButton
                    )}
                  >
                    <p className={classes.administrationButtonText}>
                      {t("administrationModal.navigationButtons.machines")}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
            {current_view}
          </Box>
          <Box
            display={{ xs: "block", md: "block", lg: "none" }}
            className={classes.unableToDisplayAdmin}
          >
            {t("administrationModal.unableToDisplayAdmin")}
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToPropsAdministrationModal,
  mapDispatchToPropsAdministrationModal
)(AdministrationModal);
