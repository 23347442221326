import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

import TextInput from "../../utils/components/TextInput";

import { useTranslation } from "react-i18next";

import Close from "@material-ui/icons/Close";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    height: "75%",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  blockedUserCheckbox: {
    textAlign: "right",
    paddingRight: "0px",
    float: "left",
    paddingLeft: "8px",
  },
  textInput: {
    width: "100%",
  },
  resetPasswordContainer: {
    textAlign: "right",
    paddingRight: "0px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  errorMsg: {
    color: "red",
  },
  sentMsg: {
    color: "green",
  },
}));

const MachineSectorsModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [descriptionEs, setDescriptionEs] = React.useState("");
  const [descriptionCa, setDescriptionCa] = React.useState("");
  const [descriptionEn, setDescriptionEn] = React.useState("");
  const [hasChanges, setHasChanges] = React.useState(false);
  const [showNotAllFieldsFilledModal, setShowNotAllFieldsFilledModal] =
    React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);

  const machine_sectors_data = props.machineSectorsData;

  const setNewState = React.useCallback((machine_sectors_data) => {
    setDescriptionEs(machine_sectors_data.description_es);
    setDescriptionEn(machine_sectors_data.description_en);
    setDescriptionCa(machine_sectors_data.description_ca);
  }, []);

  const resetStateToDefault = React.useCallback(() => {
    setDescriptionEn("");
    setDescriptionEs("");
    setDescriptionCa("");
    setHasChanges(false);
  }, []);

  const handlePreviousModalClose = () => {
    setShowSureToCancelModal(false);
    resetStateToDefault();
    props.handleClose();
  };

  const handleCancelButton = () => {
    if (!hasChanges) {
      props.handleClose();
    } else {
      setShowSureToCancelModal(true);
    }
  };

  const are_all_fields_filled = () => {
    return (
      descriptionEs.length !== 0 &&
      descriptionEn.length !== 0 &&
      descriptionCa.length !== 0
    );
  };

  const handleAcceptButton = () => {
    if (!are_all_fields_filled()) {
      setShowNotAllFieldsFilledModal(true);
    } else {
      let new_machine_sectors = {
        description_es: descriptionEs,
        description_ca: descriptionCa,
        description_en: descriptionEn,
      };
      if (machine_sectors_data !== null) {
        new_machine_sectors["id"] = machine_sectors_data.alert_change_id;
      }
      props.createOrUpdateMachineSectors(new_machine_sectors);
      resetStateToDefault();
      props.handleClose();
    }
  };

  React.useEffect(() => {
    if (machine_sectors_data != null) {
      setNewState(machine_sectors_data);
    } else {
      resetStateToDefault();
    }
  }, [machine_sectors_data, setNewState, resetStateToDefault]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick={true}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("machineSectorsView.machineSectorsModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.textInput}
                label={
                  t("machineSectorsView.machineSectorsModal.description") +
                  " ES"
                }
                value={descriptionEs}
                onChange={(value) => {
                  setHasChanges(true);
                  setDescriptionEs(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.textInput}
                label={
                  t("machineSectorsView.machineSectorsModal.description") +
                  " EN"
                }
                value={descriptionEn}
                onChange={(value) => {
                  setHasChanges(true);
                  setDescriptionEn(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.textInput}
                label={
                  t("machineSectorsView.machineSectorsModal.description") +
                  " CA"
                }
                value={descriptionCa}
                onChange={(value) => {
                  setHasChanges(true);
                  setDescriptionCa(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("machineSectorsView.machineSectorsModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("machineSectorsView.machineSectorsModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
      <InformationModal
        open={showNotAllFieldsFilledModal}
        handleClose={() => setShowNotAllFieldsFilledModal(false)}
        title={t("notAllFieldsFilledModal.title")}
        description={t("notAllFieldsFilledModal.description")}
        buttonDescription={t("notAllFieldsFilledModal.keepEditing")}
      />
    </div>
  );
};

export default MachineSectorsModal;
