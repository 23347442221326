import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import MachinesMap from "../utils/components/MachinesMap";
import ComponentCard from "./ComponentCard";
import MachineCard from "./MachineCard";

import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  componentGrid: {
    height: "446px",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
}));

/**
 *  Meta container based on Material-UI Grid that serve as structure of _MachinesMap_ and _Machine/ComponentCards_ in
 *  **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function MachinesInfo(props) {
  const classes = useStyles();

  const generate_component_cards = () => {
    let cards = [];
    if (props.selectedMachine == null) return null;
    cards.push(
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={4}
        key={props.selectedMachine.ckserial}
      >
        <MachineCard
          model={props.selectedMachine.model}
          img_path={props.selectedMachine.img_path}
          installation_date={props.selectedMachine.installation_date}
          warranty_date={props.selectedMachine.warranty_date}
          working_range={props.selectedMachine.working_range_exceeded}
          last_timestamp={props.selectedMachine.last_timestamp}
          last_cleaning_date={props.selectedMachine.last_cleaning_date}
          ckserial={props.selectedMachine.ckserial}
        />
      </Grid>
    );

    let components = JSON.parse(
      JSON.stringify(props.selectedMachine.components)
    );
    components.sort(function (a, b) {
      if (a.nom_dispositiu < b.nom_dispositiu) {
        return -1;
      } else if (a.nom_dispositiu > b.nom_dispositiu) {
        return 1;
      } else {
        if (a.serial < b.serial) {
          return -1;
        } else return 1;
      }
    });

    for (let i = 0; i < components.length; ++i) {
      cards.push(
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          key={components[i].serial}
        >
          <ComponentCard
            serial={components[i].serial}
            nomDispositiu={components[i].nom_dispositiu}
            img_path={components[i].img_path}
            state={components[i].state}
            stateLevel={components[i].state_level}
            stateColor={components[i].state_color}
            stateDescription={components[i].state_description}
            risk={components[i].risk}
            riskDescription={components[i].risk_description}
            isDistributorAdmin={props.isDistributorAdmin}
            distributor_contract_plan={props.distributor_contract_plan}
          />
        </Grid>
      );
    }
    return cards;
  };

  return (
    <Grid container spacing={2} alignItems="flex-start" justify="flex-start">
      <Grid item xs={12}>
        <MachinesMap
          machines={props.machines}
          selectedMachine={props.selectedMachine}
        />
      </Grid>
      <Grid item xs={12} className={classes.componentGrid}>
        <Grid
          container
          spacing={1}
          alignItems="flex-start"
          justify="flex-start"
        >
          {generate_component_cards()}
        </Grid>
      </Grid>
    </Grid>
  );
}

MachinesInfo.propTypes = {
  /** List of dicts containing all the information of the machines to display. Info of the keys to insert on each machine
   * dict can be found on the examples.*/
  machines: PropTypes.array,
  /** Dict containing the information of a single machine. It should be used when the list of machines contains a single
   * element.*/
  selectedMachine: PropTypes.object,
};
