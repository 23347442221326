import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import ScoringSearchField from "./ScoringSearchField";
import ComponentSelector from "./ComponentSelector";
import ComponentInfoDisplayer from "./ComponentInfoDisplayer";
import ComponentActionsToDo from "./ComponentActionsToDo";
import ComponentStateDisplayer from "./ComponentStateDisplayer";
import MachineBasicData from "./MachineBasicData";
import MachineAdvancedData from "./MachineAdvancedData";
import MachineImage from "./MachineImage";

const useStyles = makeStyles((theme) => ({
  advancedSearchBox: {
    textAlign: "right",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  advancedSearch: {
    fontWeight: "bold",
    color: "blue",
  },
  searchInput: {
    paddingBottom: "16px",
  },
}));

const ScoringSearcher = (props) => {
  const classes = useStyles();

  let selectedComponent = null;
  if (props.selectedComponent !== -1) {
    selectedComponent = props.components[props.selectedComponentIndex];
  }

  return (
    <Grid container spacing={1} alignItems="flex-start" justify="center">
      <Grid item xs={12} className={classes.searchInput}>
        <ScoringSearchField
          selectedMachineSetState={props.selectedMachineSetState}
          machines={props.machines}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        {props.selectedMachine != null && (
          <MachineImage
            title={props.selectedMachine.model}
            img_path={props.selectedMachine.img_path}
            stateColor={props.selectedMachine.state_color}
          />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        <MachineBasicData selectedMachine={props.selectedMachine} />
      </Grid>
      <Grid item xs={12} md={5}>
        <MachineAdvancedData selectedMachine={props.selectedMachine} />
      </Grid>
      <Grid item xs={12}>
        <ComponentSelector
          components={props.components}
          selectedComponentIndex={props.selectedComponentIndex}
          selectedComponentIndexSetState={props.selectedComponentIndexSetState}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <ComponentStateDisplayer component={selectedComponent} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ComponentActionsToDo component={selectedComponent} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ComponentInfoDisplayer
          component={selectedComponent}
          isDistributorAdmin={props.isDistributorAdmin}
        />
      </Grid>
    </Grid>
  );
};

export default ScoringSearcher;
