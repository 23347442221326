import { RISK_RANGES } from "../constants/common_constants";

class MachinesSet {
  constructor(machines, sort_input = false) {
    this.machines = machines;
    if (sort_input) {
      this.machines.sort(this._machines_sort_function);
    }
  }

  update_machine(machine) {
    let found = false;
    let index = 0;
    while (index < this.machines.length && !found) {
      if (machine.machine_id === this.machines[index].machine_id) {
        this.machines[index].address = machine.address;
        this.machines[index].latitude = machine.latitude;
        this.machines[index].longitude = machine.longitude;
        this.machines[index].installation_date = machine.installation_date;
        this.machines[index].tlf = machine.tlf;
        this.machines[index].contact_person = machine.contact_person;
        this.machines[index].alias = machine.alias;
        this.machines[index].observations = machine.observations;
        this.machines[index].is_test_machine = machine.is_test_machine;
        this.machines[index].sector = machine.sector;
        this.machines[index].last_cleaning_date = machine.last_cleaning_date;

        if ("distributor" in machine && "group" in machine) {
          this.machines[index].distributor = machine.distributor;
          this.machines[index].group = machine.group;
        }
        found = true;
      }
      index++;
    }
  }

  get_machines(t = null) {
    if (t != null) {
      return this.machines.map((machine) =>
        this._add_translated_risk_to_machine(machine, t)
      );
    }
    return this.machines;
  }

  get_filtered_number_of_machines_for_each_risk_level(distributor, group) {
    let modified_machines = JSON.parse(JSON.stringify(this.machines));

    modified_machines = modified_machines.filter((machine) =>
      this._is_machine_from_distributor_and_group(machine, distributor, group)
    );

    let machines_for_each_risk_level = {};
    for (let i = 0; i < RISK_RANGES.length; ++i) {
      machines_for_each_risk_level[RISK_RANGES[i][1]] = 0;
    }
    for (let i = 0; i < modified_machines.length; ++i) {
      machines_for_each_risk_level[
        this._get_risk_level(modified_machines[i].risk)
      ] += 1;
    }
    return machines_for_each_risk_level;
  }

  get_filtered_number_of_machines_for_each_state_level(
    distributor,
    group,
    alert_levels
  ) {
    let modified_machines = JSON.parse(JSON.stringify(this.machines));

    modified_machines = modified_machines.filter((machine) =>
      this._is_machine_from_distributor_and_group(machine, distributor, group)
    );

    let machines_for_each_state_level = {};

    if (Object.keys(alert_levels).length > 0) {
      Object.keys(alert_levels).forEach((item) => {
        machines_for_each_state_level[alert_levels[item].description] = 0;
      });
      for (let i = 0; i < modified_machines.length; ++i) {
        machines_for_each_state_level[
          alert_levels[modified_machines[i].state].description
        ] += 1;
      }
    }
    return machines_for_each_state_level;
  }

  get_medium_and_high_risk_machines(distributor, group) {
    let filtered_machines = this.get_filtered_machines(distributor, group);
    filtered_machines = filtered_machines.filter(
      (machine) => machine.risk >= RISK_RANGES[1][0]
    );
    return filtered_machines;
  }

  get_over_green_state_machines(distributor, group) {
    let filtered_machines = this.get_filtered_machines(distributor, group);
    filtered_machines = filtered_machines.filter(
      (machine) => machine.state > 1
    );
    return filtered_machines;
  }

  get_number_of_red_state_machines(distributor, group) {
    let filtered_machines = this.get_filtered_machines(distributor, group);
    filtered_machines = filtered_machines.filter(
      (machine) => machine.state > 2
    );
    return filtered_machines.length;
  }

  get_filtered_machines(distributor, group, t = null, ordered = false) {
    let modified_machines = JSON.parse(JSON.stringify(this.machines));

    modified_machines = modified_machines.filter((machine) =>
      this._is_machine_from_distributor_and_group(machine, distributor, group)
    );
    if (ordered) {
      modified_machines.sort(this._machines_sort_function);
    }

    return modified_machines;
  }

  _machines_sort_function(a, b) {
    if (a.state > b.state) {
      return -1;
    } else if (a.state < b.state) {
      return 1;
    } else {
      if (a.installation_date != null && b.installation_date != null) {
        let a_date = a.installation_date.split("/");
        a_date = new Date(a_date[2] + "-" + a_date[1] + "-" + a_date[0]);
        let b_date = b.installation_date.split("/");
        b_date = new Date(b_date[2] + "-" + b_date[1] + "-" + b_date[0]);

        if (a_date > b_date) {
          return -1;
        } else if (a_date < b_date) {
          return 1;
        } else {
          if (a.ckserial > b.ckserial) {
            return 1;
          } else if (a.ckserial < b.ckserial) {
            return -1;
          }
        }
      } else if (b.installation_date != null) {
        return 1;
      } else if (a.installation_date != null) {
        return -1;
      } else {
        if (a.ckserial > b.ckserial) {
          return 1;
        } else if (a.ckserial < b.ckserial) {
          return -1;
        }
      }
    }
    return 0;
  }

  _get_risk_level = (risk) => {
    for (let i = 0; i < RISK_RANGES.length - 1; ++i) {
      if (risk >= RISK_RANGES[i][0] && risk < RISK_RANGES[i + 1][0]) {
        return RISK_RANGES[i][1];
      }
    }
    return RISK_RANGES[RISK_RANGES.length - 1][1];
  };

  _is_machine_from_distributor_and_group = (machine, distributor, group) => {
    if (distributor.id !== -1 && group.id !== -1) {
      if (distributor.id === "0") {
        return true;
      } else {
        if (parseInt(distributor.id) === machine.distributor) {
          if (group.is_default) {
            return true;
          } else if (machine.group === parseInt(group.id)) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }
  };
}

export default MachinesSet;
