import { ALERT_PAGINATION } from "../constants/common_constants";

class AlertsSet {
  constructor(alerts) {
    this.alerts = alerts;
  }

  update_alert(alert) {
    let found = false;
    let index = 0;
    while (index < this.alerts.length && !found) {
      if (alert.id === this.alerts[index].alert_id) {
        this.alerts[index].alert_state = alert.new_state;
        this.alerts[index].user_dealing_with_alert =
          alert.user_dealing_with_alert;
        found = true;
      }
      index++;
    }
  }

  get_alerts(sorted = false) {
    if (!sorted) return this.alerts;
    else {
      let alerts = JSON.parse(JSON.stringify(this.alerts));
      alerts.sort(function (a, b) {
        let a_date = a.timestamp.split("/");
        a_date = new Date(a_date[2] + "-" + a_date[1] + "-" + a_date[0]);
        let b_date = b.timestamp.split("/");
        b_date = new Date(b_date[2] + "-" + b_date[1] + "-" + b_date[0]);

        if (a_date > b_date) {
          return -1;
        } else if (a_date < b_date) {
          return 1;
        } else {
          return 0;
        }
      });
      return alerts;
    }
  }

  get_top_5_alerts(distributor, group) {
    let filtered_alerts = this.get_filtered_alerts(distributor, group);
    filtered_alerts = filtered_alerts.filter(
      (alert) => alert.alert_state === "Open"
    );
    filtered_alerts.sort(function (a, b) {
      if (a.risk > b.risk) {
        return -1;
      } else if (a.risk < b.risk) {
        return 1;
      } else {
        let a_date = a.timestamp.split("/");
        a_date = new Date(a_date[2] + "-" + a_date[1] + "-" + a_date[0]);
        let b_date = b.timestamp.split("/");
        b_date = new Date(b_date[2] + "-" + b_date[1] + "-" + b_date[0]);

        if (a_date > b_date) {
          return -1;
        } else if (a_date < b_date) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    if (filtered_alerts.length >= 5) {
      return filtered_alerts.slice(0, 5);
    }
    return filtered_alerts;
  }

  get_filtered_alerts(distributor, group, search_field = "") {
    let filtered_alerts = JSON.parse(JSON.stringify(this.alerts));

    filtered_alerts = filtered_alerts.filter((alert) =>
      this._is_alert_from_distributor_and_group(alert, distributor, group)
    );
    filtered_alerts = filtered_alerts.filter((alert) =>
      this._alert_contains_search_input(alert, search_field)
    );

    return filtered_alerts;
  }

  get_number_of_open_alerts(
    distributor = null,
    group = null,
    search_field = ""
  ) {
    let filtered_alerts = JSON.parse(JSON.stringify(this.alerts));
    if (distributor != null && group != null) {
      filtered_alerts = filtered_alerts.filter((alert) =>
        this._is_alert_from_distributor_and_group(alert, distributor, group)
      );
    }
    if (search_field.lenght > 0) {
      filtered_alerts = filtered_alerts.filter((alert) =>
        this._alert_contains_search_input(alert, search_field)
      );
    }
    let num_alerts = 0;
    for (let i = 0; i < filtered_alerts.length; ++i) {
      if (filtered_alerts[i].alert_state === "Open") {
        num_alerts++;
      }
    }
    return num_alerts;
  }

  get_number_of_machines_with_alerts(
    distributor = null,
    group = null,
    search_field = ""
  ) {
    let filtered_alerts = JSON.parse(JSON.stringify(this.alerts));
    if (distributor != null && group != null) {
      filtered_alerts = filtered_alerts.filter((alert) =>
        this._is_alert_from_distributor_and_group(alert, distributor, group)
      );
    }
    if (search_field.length > 0) {
      filtered_alerts = filtered_alerts.filter((alert) =>
        this._alert_contains_search_input(alert, search_field)
      );
    }
    let different_machines = new Set();
    for (let i = 0; i < filtered_alerts.length; ++i) {
      if (filtered_alerts[i].alert_state === "Open") {
        different_machines.add(filtered_alerts[i].machine_ckserial);
      }
    }
    return different_machines.size;
  }

  get_paginated_alerts(alerts, alerts_page) {
    return alerts.slice(
      (alerts_page - 1) * ALERT_PAGINATION,
      alerts_page * ALERT_PAGINATION
    );
  }

  _alert_contains_search_input = (alert, search_input) => {
    if (search_input.length === 0) {
      return true;
    }

    let match_found = false;
    Object.keys(alert).forEach(function (key) {
      if (
        alert[key] !== null &&
        alert[key].toString().toLowerCase().includes(search_input.toLowerCase())
      ) {
        match_found = true;
      }
    });

    return match_found;
  };

  _is_alert_from_distributor_and_group = (alert, distributor, group) => {
    if (distributor.id !== -1 && group.id !== -1) {
      if (distributor.id === "0") {
        return true;
      } else {
        if (parseInt(distributor.id) === alert.distributor) {
          if (group.is_default) {
            return true;
          } else if (alert.group === parseInt(group.id)) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }
  };
}

export default AlertsSet;
