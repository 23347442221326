import React from "react";

import clsx from "clsx";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { SERVER_URL } from "../utils/api_calls/common/routes";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    icon: {
      height: 120,
      width: 120,
    },
    componentSelector: {
      height: "calc(255px - 33px)",
      overflowX: "auto",
      display: "flex",
      alignItems: "center",
    },
    componentContainer: {
      border: "1px solid black",
      display: "inline-block",
      verticalAlign: "top",
      margin: "0 10px",
      width: "200px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    componentText: {
      fontSize: 14,
      margin: 0,
    },
    selected: {
      borderTop: "8px solid rgb(255, 163, 15) !important",
    },
    borderLow: {
      borderLeft: "8px solid green",
      height: "110%",
      padding: "10px",
    },
    borderMedium: {
      borderLeft: "8px solid orange",
      height: "110%",
      padding: "10px",
    },
    borderHigh: {
      borderLeft: "8px solid red",
      height: "110%",
      padding: "10px",
    },
  })
);

const ComponentSelector = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  const generate_components = () => {
    let components = [];

    for (let i = 0; i < props.components.length; ++i) {
      const current_i = i;

      const borderStyle = {
        borderLeft: "8px solid " + props.components[current_i].state_color,
        height: "110%",
        padding: "10px",
      };

      components.push(
        <Box
          key={props.components[current_i].serial}
          onClick={() => props.selectedComponentIndexSetState(current_i)}
          className={clsx(
            classes.componentContainer,
            current_i === props.selectedComponentIndex && classes.selected
          )}
        >
          <div style={borderStyle}>
            <img
              className={classes.icon}
              src={SERVER_URL + props.components[current_i].img_path}
              alt={"comp"}
            />
            <p className={classes.componentText}>
              <b>
                {props.components[current_i].nom_dispositiu}
                <br />
              </b>
              {props.components[current_i].serial}
            </p>
          </div>
        </Box>
      );
    }
    return components;
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.componentTitle}>
        {t("componentSelector.title")}
      </Box>
      <Box className={classes.componentSelector}>{generate_components()}</Box>
    </Paper>
  );
};

export default ComponentSelector;
