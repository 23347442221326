import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { login_reducer } from "./login_reducer";
import { administration_modals_reducer } from "./administration_modals_reducer";
import { general_reducer } from "./general_reducer";
import { machines_reducer } from "./machines_reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    login: login_reducer,
    administration_modals: administration_modals_reducer,
    general: general_reducer,
    machines: machines_reducer,
  });

export default createRootReducer;
