import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
      fontSize: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
    },
    itemTitle: {
      fontWeight: "bold",
      textAlign: "right",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    itemContent: {
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    rmaButton: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "24px",
    },
    workingRangeImg: {
      height: 20,
      verticalAlign: "middle",
    },
  })
);

const ComponentInfoDisplayer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  if (props.component != null) {
    let warranty_date = t("componentInfoDisplayer.noWarranty");
    if (props.component.warranty_date != null) {
      warranty_date = props.component.warranty_date;
    }

    let last_timestamp = "-";
    if (props.component.last_timestamp != null) {
      last_timestamp = props.component.last_timestamp;
    }

    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("componentInfoDisplayer.title")}
        </Box>
        <Box className={classes.boxContainer}>
          <div>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid className={classes.itemTitle} item xs={6}>
                {t("componentInfoDisplayer.warrantyEnd") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {warranty_date}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                {t("componentInfoDisplayer.firmware") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {props.component.firmware}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                {t("componentInfoDisplayer.dataset") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {props.component.dataset}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                {t("componentInfoDisplayer.lastTimestamp") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {last_timestamp}
              </Grid>
              {props.isDistributorAdmin && (
                <Grid item className={classes.rmaButton} xs={12}>
                  <Button
                    variant="contained"
                    target="_blank"
                    href="http://www.google.com/"
                  >
                    Abrir RMA
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("componentInfoDisplayer.title")}
        </Box>
      </Paper>
    );
  }
};

export default ComponentInfoDisplayer;
