// React imports
import React from "react";

// Third-Party imports
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Local imports
import PredictiveSearcher from "./PredictiveSearcher";
import { mapStateToPropsPredictive } from "../utils/redux/mappers/predictive_mappers";

const useStyles = makeStyles((theme) => ({
  predictive: {
    backgroundColor: "rgb(237, 237, 237)",
    height: "calc(100% - 64px)",
    minHeight: "calc(100% - 64px)",
    overflowY: "auto",
    padding: "20px",
  },
}));

const Predictive = (props) => {
  const classes = useStyles();

  const [selectedMachine, selectedMachineRawSetState] = React.useState(null);
  const [selectedComponentIndex, selectedComponentIndexSetState] =
    React.useState(-1);

  React.useEffect(() => {
    if (
      props.defaultSelectedMachine != null &&
      props.defaultSelectedMachine.components.length > 0
    ) {
      selectedMachineRawSetState(props.defaultSelectedMachine);
      selectedComponentIndexSetState(0);
    } else {
      selectedMachineRawSetState(null);
      selectedComponentIndexSetState(-1);
    }
  }, [props.defaultSelectedMachine]);

  const selectedMachineSetState = (machine_state) => {
    selectedComponentIndexSetState(0);
    selectedMachineRawSetState(machine_state);
  };

  let components = [];
  if (selectedMachine !== null) {
    let is_selected_machine_in_filtered_machines = false;
    let filtered_machines_index = 0;
    while (
      filtered_machines_index < props.machines.length &&
      !is_selected_machine_in_filtered_machines
    ) {
      if (
        props.machines[filtered_machines_index].machine_id ===
        selectedMachine.machine_id
      ) {
        is_selected_machine_in_filtered_machines = true;
      }
      ++filtered_machines_index;
    }

    if (!is_selected_machine_in_filtered_machines) {
      if (props.machines.length > 0) {
        selectedMachineRawSetState(props.machines[0]);
        if (props.machines[0].components.length > 0) {
          selectedComponentIndexSetState(0);
        } else {
          selectedComponentIndexSetState(-1);
        }
      } else {
        selectedMachineRawSetState(null);
        selectedComponentIndexSetState(-1);
      }
    } else {
      components = selectedMachine.components;
    }
  } else {
    if (props.machines.length > 0) {
      selectedMachineRawSetState(props.machines[0]);
      if (props.machines[0].components.length > 0) {
        selectedComponentIndexSetState(0);
      } else {
        selectedComponentIndexSetState(-1);
      }
    }
  }

  components.sort(function (a, b) {
    if (a.nom_dispositiu < b.nom_dispositiu) {
      return -1;
    } else if (a.nom_dispositiu > b.nom_dispositiu) {
      return 1;
    } else {
      if (a.serial < b.serial) {
        return -1;
      } else return 1;
    }
  });

  return (
    <div className={classes.predictive}>
      <Grid container spacing={2} alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <PredictiveSearcher
            selectedMachine={selectedMachine}
            selectedMachineSetState={selectedMachineSetState}
            selectedComponentIndex={selectedComponentIndex}
            selectedComponentIndexSetState={selectedComponentIndexSetState}
            machines={props.machines}
            components={components}
            modelHistory={props.model_history}
            devicesExtraInfo={props.devices_extra_info}
            isDistributorAdmin={props.distributor_contract_plan.is_admin}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(mapStateToPropsPredictive, null)(Predictive);
