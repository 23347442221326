// React imports
import React from "react";

// Third-Party imports
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

// Local imports
import AlertItem from "./AlertItem";
import NumberOfAlerts from "./NumberOfAlerts";
import MachineForRiskLevelChart from "./MachineForRiskLevelChart";
import MachinesMap from "../utils/components/MachinesMap";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    backgroundColor: "rgb(237, 237, 237)",
    height: "calc(100% - 64px)",
    minHeight: "calc(100% - 64px)",
    overflowY: "auto",
    padding: "10px",
  },
  dashboardGrid: {
    padding: "8px",
    border: "1px ",
  },
  machinesMap: {
    height: "450px",
  },
  noAvailableAlerts: {
    height: "335px",
    border: "6px solid rgb(255, 163, 15)",
    borderRadius: "40px",
    color: "green",
    fontSize: "36px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["dashboard"]);

  const generate_alert_items = (alerts) => {
    let alert_items = [];
    if (alerts.length > 0) {
      for (let i = 0; i < alerts.length; ++i) {
        alert_items.push(
          <Grid item xs={12} key={alerts[i].alert_id}>
            <AlertItem
              alert={alerts[i]}
              machineIDText={t("dashboard:alertsList.machineIDText")}
              componentText={t("dashboard:alertsList.componentText")}
              changeWindowOnCkserialClick={props.changeWindowOnCkserialClick}
            />
          </Grid>
        );
      }
      return (
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justify="center"
          className={classes.dashboardGrid}
        >
          {alert_items}
        </Grid>
      );
    } else {
      return (
        <div className={classes.noAvailableAlerts}>
          {t("dashboard:alertsList.noAvailableAlerts")}
        </div>
      );
    }
  };

  return (
    <div className={classes.dashboard}>
      <Grid
        container
        spacing={1}
        alignItems="flex-start"
        justify="center"
        className={classes.dashboardGrid}
      >
        <Grid item xs={12} md={4}>
          <NumberOfAlerts
            numberOfOpenAlerts={props.numberOfOpenAlerts}
            criticalAlertsText={t(
              "dashboard:numberOfAlerts.criticalAlertsText"
            )}
            in={t("dashboard:numberOfAlerts.in")}
            numberOfMachines={props.numberOfMachines}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {generate_alert_items(props.top5Alerts)}
        </Grid>
        <Grid item xs={12} md={4}>
          <MachineForRiskLevelChart
            title={t("dashboard:machineForStateLevelChart.title")}
            data={props.chartData}
          />
        </Grid>
        <Grid item xs={12} className={classes.alertsMap}>
          {
            <MachinesMap
              className={classes.machinesMap}
              machines={props.mediumAndHighStateMachines}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
