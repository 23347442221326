import React from "react";
import "./MachinesMap.css";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Warning from "@material-ui/icons/Warning";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";

const CASHKEEPER_LATITUDE = 41.850899;
const CASHKEEPER_LONGITUDE = 3.08246;
const LATITUDE_DISPLACEMENT = 0.05;
const LONGITUDE_DISPLACEMENT = 0.05;

const greenIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const orangeIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const redIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

//Function that generate the different markers shown in the map
const generate_markers = (machines, classes) => {
  try {
    return machines.map((machine) => {
      let color_icon;
      let risk_icon;
      if (machine.state === 1) {
        color_icon = greenIcon;
        risk_icon = (
          <CheckCircleOutline className={clsx(classes.icon, classes.iconLow)} />
        );
      } else if (machine.state === 2) {
        color_icon = orangeIcon;
        risk_icon = (
          <Warning className={clsx(classes.icon, classes.iconMedium)} />
        );
      } else if (machine.state === 3) {
        color_icon = redIcon;
        risk_icon = (
          <ErrorOutline className={clsx(classes.icon, classes.iconHigh)} />
        );
      }
      return (
        <Marker
          key={machine.machine_id}
          position={[machine.latitude, machine.longitude]}
          icon={color_icon}
        >
          <Popup className={classes.popup}>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={3}>
                {risk_icon}
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={5}>
                    {machine.alias}
                  </Grid>
                  <Grid item xs={2}>
                    -
                  </Grid>
                  <Grid item xs={5}>
                    {machine.ckserial}
                  </Grid>
                  <Grid item xs={12}>
                    {machine.address}
                  </Grid>
                  <Grid item xs={5}>
                    {machine.installation_date}
                  </Grid>
                  <Grid item xs={2}>
                    -
                  </Grid>
                  <Grid item xs={5}>
                    {machine.risk_level}
                  </Grid>
                  <Grid item xs={5}>
                    {machine.last_cleaning_date}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Popup>
        </Marker>
      );
    });
  } catch (e) {
    return [];
  }
};

const min_and_max_longitude_bounds_are_equal = (
  min_longitude,
  max_longitude
) => {
  return min_longitude === max_longitude;
};

//Function that calculate the dimensions of the map in coordinates, based on the coordinates of the machines.
const calculate_map_bounds = (machines) => {
  try {
    let max_bound_latitude = machines[0].latitude;
    let max_bound_longitude = machines[0].longitude;
    let min_bound_latitude = machines[0].latitude;
    let min_bound_longitude = machines[0].longitude;

    for (let i = 0; i < machines.length; ++i) {
      max_bound_latitude = Math.max(max_bound_latitude, machines[i].latitude);
      max_bound_longitude = Math.max(
        max_bound_longitude,
        machines[i].longitude
      );
      min_bound_latitude = Math.min(min_bound_latitude, machines[i].latitude);
      min_bound_longitude = Math.min(
        min_bound_longitude,
        machines[i].longitude
      );
    }
    // This comparision is done in order to avoid a bug in which if the bounds are equal (e.g. only 1 marker) the map
    // component breaks.
    if (
      min_and_max_longitude_bounds_are_equal(
        min_bound_longitude,
        max_bound_longitude
      )
    ) {
      min_bound_longitude -= 0.005;
      max_bound_longitude += 0.005;
    }
    if (
      min_and_max_longitude_bounds_are_equal(
        min_bound_latitude,
        max_bound_latitude
      )
    ) {
      min_bound_latitude -= 0.005;
      max_bound_latitude += 0.005;
    }
    return [
      [min_bound_latitude, min_bound_longitude],
      [max_bound_latitude, max_bound_longitude],
    ];
  } catch (e) {
    return [
      [
        CASHKEEPER_LATITUDE - LATITUDE_DISPLACEMENT,
        CASHKEEPER_LONGITUDE - LONGITUDE_DISPLACEMENT,
      ],
      [
        CASHKEEPER_LATITUDE + LATITUDE_DISPLACEMENT,
        CASHKEEPER_LONGITUDE + LONGITUDE_DISPLACEMENT,
      ],
    ];
  }
};

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      height: 40,
      width: 40,
    },
    iconLow: {
      color: "green",
    },
    iconMedium: {
      color: "orange",
    },
    iconHigh: {
      color: "red",
    },
    popup: {
      width: "350px",
      height: "auto",
    },
  })
);

/**
 *  _React-Leaflet_ component used to display the machines location inside **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function MachinesMap(props) {
  const classes = useStyles();
  let markers;
  let bounds;
  if (props.selectedMachine != null) {
    bounds = calculate_map_bounds([props.selectedMachine]);
    markers = generate_markers([props.selectedMachine], classes);
  } else {
    bounds = calculate_map_bounds(props.machines);
    markers = generate_markers(props.machines, classes);
  }
  return (
    <Map bounds={bounds} className={props.className} maxZoom={16} minZoom={3}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="Datision"
      />
      {markers}
    </Map>
  );
}

MachinesMap.propTypes = {
  /** List of dicts containing all the information of the machines to display. Info of the keys to insert on each machine
   * dict can be found on the examples.*/
  machines: PropTypes.array,
  /** Dict containing the information of a single machine. It should be used when the list of machines contains a single
   * element.*/
  selectedMachine: PropTypes.object,
};
