import { update_machine } from "../../api_calls/machines_api_calls";
import { UPDATE_MACHINES } from "../actions/machines_action_types";
import {
  UPDATE_SELECTED_MACHINE,
  UPDATE_SEARCH_INPUT,
} from "../actions/general_action_types";

export const mapStateToPropsMachinesList = (state, ownProps) => {
  let user_distributor = null;
  if (Object.keys(state.general.user_profile).length > 0) {
    user_distributor = state.general.user_profile.distributor.toString();
  }
  return {
    distributors_with_groups: state.general.distributors_with_groups,
    distributor_contract_plan: state.general.distributor_contract_plan,
    auth_token: state.login.auth_token,
    loading_machines: state.machines.loading_machines,
    logged_user_distributor: user_distributor,
    active_machine_sectors: state.machines.active_machine_sectors,
    selected_distributor: state.general.selected_distributor,
  };
};

export const mapDispatchToPropsMachinesList = (dispatch) => {
  return {
    update_machine: (token, machine_data) => {
      update_machine(token, machine_data, dispatch);
    },
    update_machines_set: (machines_array) => {
      dispatch({
        type: UPDATE_MACHINES,
        machines: machines_array,
      });
    },
    update_selected_machine: (selected) => {
      dispatch({
        type: UPDATE_SELECTED_MACHINE,
        selected_machine: selected,
      });
    },
    update_search_input: (input) => {
      dispatch({
        type: UPDATE_SEARCH_INPUT,
        search_input: input,
      });
    },
  };
};
