import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Delete from "@material-ui/icons/Delete";
import Restore from "@material-ui/icons/Restore";
import Edit from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core";

import SearchField from "../../utils/components/SearchField";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import AlertChangesModal from "./AlertChangesModal";

import { useTranslation } from "react-i18next";

import ReactTable from "react-table-6";
import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
}));

const AlertChangesView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [showNonActiveAlertChanges, showNonActiveAlertChangesSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [editAlertChangeModalOpen, editAlertChangeModalSetState] =
    React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);

  const get_alert_change_types = props.getAlertChangeTypes;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_alert_change_types(auth_token);
  }, [get_alert_change_types, auth_token]);

  try {
    let alert_change_types = props.alertChangeTypes;
    if (!showNonActiveAlertChanges) {
      alert_change_types = alert_change_types.filter(
        (alert_change) => alert_change.is_active
      );
    }
    alert_change_types = alert_change_types.map((alert_change) =>
      Object.assign({}, alert_change, {
        translated_new_state: t(
          "alertChangesView.alertChangesStates." + alert_change.new_state
        ),
      })
    );
    alert_change_types = alert_change_types.filter((alert_change) =>
      dict_contains_search_input(alert_change, props.searchInput)
    );

    let new_state_options = {};
    for (let i = 0; i < props.newStateOptions.length; ++i) {
      new_state_options[props.newStateOptions[i]] = t(
        "alertChangesView.alertChangesStates." + props.newStateOptions[i]
      );
    }

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={3} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newUserButton}
              onClick={() => editAlertChangeModalSetState(true)}
            >
              <div style={{ textAlign: "right" }}>
                {t("alertChangesView.newAlertChangeButton")}
              </div>
            </Button>
          </Grid>
          <Grid item lg={5} md={2} xs={false} />
          <Grid item lg={4} md={5} xs={6}>
            <div style={{ textAlign: "right" }}>
              {t("alertChangesView.showSetDownAlertChanges")}
            </div>
            <Switch
              className={classes.showSetDownUsers}
              checked={showNonActiveAlertChanges}
              onChange={(event) =>
                showNonActiveAlertChangesSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={alert_change_types}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            selectedElementSetState(row._original);
                            deleteModalSetState(true);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            props.updateAlertChangeState(
                              row._original.alert_change_id,
                              true
                            );
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => (
                    <Edit
                      className={classes.icon}
                      onClick={() => {
                        selectedElementSetState(row._original);
                        editAlertChangeModalSetState(true);
                      }}
                    />
                  ),
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("alertChangesView.newState"),
                  accessor: "translated_new_state",
                },
                {
                  Header: t("alertChangesView.description") + " ES",
                  accessor: "description_es",
                },
                {
                  Header: t("alertChangesView.description") + " EN",
                  accessor: "description_en",
                },
                {
                  Header: t("alertChangesView.description") + " CA",
                  accessor: "description_ca",
                },
              ]}
              defaultSorted={[
                {
                  id: "translated_new_state",
                  desc: false,
                },
              ]}
              loading={props.loadingAlertChangeTypes}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="viewsTable"
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <AlertChangesModal
          open={editAlertChangeModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            editAlertChangeModalSetState(false);
          }}
          alertChangeData={selectedElement}
          newStates={new_state_options}
          createOrUpdateAlertChange={props.createOrUpdateAlertChange}
        />
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateAlertChangeState(
              selectedElement.alert_change_id,
              false
            );
            deleteModalSetState(false);
            selectedElementSetState(null);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default AlertChangesView;
