import {
  ATTEMPT_TO_GET_USERS,
  UPDATE_USERS,
  ERROR_GET_USERS,
  ERROR_UPDATE_USER_PROFILE,
  ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE,
  CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL,
  ERROR_CREATE_OR_UPDATE_USER_PROFILE,
  ATTEMPT_TO_GET_DISTRIBUTORS,
  UPDATE_DISTRIBUTORS,
  ERROR_GET_DISTRIBUTORS,
  ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS,
  UPDATE_NON_ACTIVE_DISTRIBUTORS,
  ERROR_GET_NON_ACTIVE_DISTRIBUTORS,
  ATTEMPT_TO_GET_GROUPS,
  UPDATE_GROUPS,
  ERROR_GET_GROUPS,
  ERROR_UPDATE_GROUP_STATE,
  ERROR_CREATE_OR_UPDATE_GROUP,
  ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  ERROR_CREATE_OR_UPDATE_DISTRIBUTOR,
  ATTEMPT_TO_GET_ALERT_CHANGE_TYPES,
  UPDATE_ALERT_CHANGE_TYPES,
  ERROR_GET_ALERT_CHANGE_TYPES,
  ATTEMPT_TO_GET_CONTRACT_PLANS,
  UPDATE_CONTRACT_PLANS,
  ERROR_GET_CONTRACT_PLANS,
  ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES,
  UPDATE_ALL_DISTRIBUTOR_MACHINES,
  ERROR_GET_ALL_DISTRIBUTOR_MACHINES,
  ERROR_UPDATE_MACHINES_STATE,
  INITIAL_STATE,
  ERROR_UPDATE_ALERT_CHANGE_STATE,
  ERROR_CREATE_OR_UPDATE_ALERT_CHANGE,
  ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES,
  UPDATE_MACHINE_SECTORS_TYPES,
  ERROR_GET_MACHINE_SECTORS_TYPES,
  ERROR_UPDATE_MACHINE_SECTORS_STATE,
  ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS,
  ATTEMPT_TO_GET_DEVICES_EXTRA_INFO,
  ERROR_GET_DEVICES_EXTRA_INFO,
  UPDATE_DEVICES_EXTRA_INFO,
  ATTEMPT_TO_GET_HEURISTIC_TYPES,
  ERROR_GET_HEURISTIC_TYPES,
  UPDATE_HEURISTIC_TYPES,
  ATTEMPT_TO_GET_ALERT_LEVELS,
  ERROR_GET_ALERT_LEVELS,
  UPDATE_ALERT_LEVELS,
  ATTEMPT_TO_GET_TIME_UNITS,
  ERROR_GET_TIME_UNITS,
  UPDATE_TIME_UNITS,
  ATTEMPT_TO_GET_STATE_DESCRIPTIONS,
  ERROR_GET_STATE_DESCRIPTIONS,
  UPDATE_STATE_DESCRIPTIONS,
  ATTEMPT_TO_GET_RELATIONAL_OPERATORS,
  ERROR_GET_RELATIONAL_OPERATORS,
  UPDATE_RELATIONAL_OPERATORS,
  ATTEMPT_TO_GET_HEURISTIC_RULES,
  ERROR_GET_HEURISTIC_RULES,
  UPDATE_HEURISTIC_RULES,
  CLEAR_ADMINISTRATION_MODALS_ERROR_MSG,
} from "../actions/administration_modals_action_types";

export const administration_modals_reducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ATTEMPT_TO_GET_USERS:
      return Object.assign({}, state, {
        users: [],
        loading_users: true,
      });
    case UPDATE_USERS:
      return Object.assign({}, state, {
        users: action.users,
        valid_users: true,
        loading_users: false,
      });
    case ERROR_GET_USERS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_users: false,
        loading_users: false,
      });
    case ERROR_UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });
    case ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        loading_user_create_or_update: true,
      });
    case CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL:
      return Object.assign({}, state, {
        successful_return_user_create_or_update: true,
        loading_user_create_or_update: false,
      });
    case ERROR_CREATE_OR_UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        successful_return_user_create_or_update: false,
        loading_user_create_or_update: false,
      });

    case ATTEMPT_TO_GET_DISTRIBUTORS:
      return Object.assign({}, state, {
        distributors: [],
        loading_distributors: true,
      });
    case UPDATE_DISTRIBUTORS:
      return Object.assign({}, state, {
        distributors: action.distributors,
        valid_distributors: false,
        loading_distributors: false,
      });
    case ERROR_GET_DISTRIBUTORS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_distributors: false,
        loading_distributors: false,
      });

    case ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS:
      return Object.assign({}, state, {
        non_active_distributors: {},
        loading_non_active_distributors: true,
      });
    case UPDATE_NON_ACTIVE_DISTRIBUTORS:
      return Object.assign({}, state, {
        non_active_distributors: action.non_active_distributors,
        valid_non_active_distributors: true,
        loading_non_active_distributors: false,
      });
    case ERROR_GET_NON_ACTIVE_DISTRIBUTORS:
      return Object.assign({}, state, {
        valid_non_active_distributors: false,
        loading_non_active_distributors: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_GROUPS:
      return Object.assign({}, state, {
        groups: [],
        loading_groups: true,
      });
    case UPDATE_GROUPS:
      return Object.assign({}, state, {
        groups: action.groups,
        valid_groups: false,
        loading_groups: false,
      });
    case ERROR_GET_GROUPS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_groups: false,
        loading_groups: false,
      });
    case ERROR_UPDATE_GROUP_STATE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });
    case ERROR_CREATE_OR_UPDATE_GROUP:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        non_active_distributor_machines: [],
        loading_non_active_distributor_machines: true,
      });
    case UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        non_active_distributor_machines: action.non_active_distributor_machines,
        valid_non_active_distributor_machines: true,
        loading_non_active_distributor_machines: false,
      });
    case ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        valid_non_active_distributor_machines: false,
        loading_non_active_distributor_machines: false,
        error_msg: action.error_msg,
      });
    case REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        non_active_distributor_machines: [],
      });
    case ERROR_CREATE_OR_UPDATE_DISTRIBUTOR:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        alert_change_types: [],
        loading_alert_change_types: true,
      });
    case UPDATE_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        alert_change_types: action.alert_change_types,
        new_state_options: action.new_state_options,
        valid_alert_change_types: false,
        loading_alert_change_types: false,
      });
    case ERROR_GET_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_alert_change_types: false,
        loading_alert_change_types: false,
      });
    case ERROR_UPDATE_ALERT_CHANGE_STATE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });
    case ERROR_CREATE_OR_UPDATE_ALERT_CHANGE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES:
      return Object.assign({}, state, {
        machine_sectors_types: [],
        loading_machine_sectors_types: true,
      });
    case UPDATE_MACHINE_SECTORS_TYPES:
      return Object.assign({}, state, {
        machine_sectors_types: action.machine_sectors_types,
        valid_machine_sectors_types: false,
        loading_machine_sectors_types: false,
      });
    case ERROR_GET_MACHINE_SECTORS_TYPES:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_machine_sectors_types: false,
        loading_machine_sectors_types: false,
      });
    case ERROR_UPDATE_MACHINE_SECTORS_STATE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });
    case ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case CLEAR_ADMINISTRATION_MODALS_ERROR_MSG:
      return Object.assign({}, state, {
        error_msg: "",
      });

    case ATTEMPT_TO_GET_CONTRACT_PLANS:
      return Object.assign({}, state, {
        contract_plans: [],
        loading_contract_plans: true,
      });
    case UPDATE_CONTRACT_PLANS:
      return Object.assign({}, state, {
        contract_plans: action.contract_plans,
        valid_contract_plans: false,
        loading_contract_plans: false,
      });
    case ERROR_GET_CONTRACT_PLANS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_contract_plans: false,
        loading_contract_plans: false,
      });

    case ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        all_distributor_machines: [],
        loading_all_distributor_machines: true,
      });
    case UPDATE_ALL_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        all_distributor_machines: action.all_distributor_machines,
        valid_all_distributor_machines: false,
        loading_all_distributor_machines: false,
      });
    case ERROR_GET_ALL_DISTRIBUTOR_MACHINES:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_all_distributor_machines: false,
        loading_all_distributor_machines: false,
      });
    case ERROR_UPDATE_MACHINES_STATE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_DEVICES_EXTRA_INFO:
      return Object.assign({}, state, {
        devices_extra_info: {},
        loading_devices_extra_info: true,
        error_msg: action.error_msg,
      });
    case UPDATE_DEVICES_EXTRA_INFO:
      return Object.assign({}, state, {
        devices_extra_info: action.devices_extra_info,
        loading_devices_extra_info: false,
        valid_devices_extra_info: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_DEVICES_EXTRA_INFO:
      return Object.assign({}, state, {
        valid_devices_extra_info: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_HEURISTIC_TYPES:
      return Object.assign({}, state, {
        heuristic_types: {},
        loading_heuristic_types: true,
        error_msg: action.error_msg,
      });
    case UPDATE_HEURISTIC_TYPES:
      return Object.assign({}, state, {
        heuristic_types: action.heuristic_types,
        loading_heuristic_types: false,
        valid_heuristic_types: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_HEURISTIC_TYPES:
      return Object.assign({}, state, {
        valid_heuristic_types: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_ALERT_LEVELS:
      return Object.assign({}, state, {
        alert_levels: {},
        loading_alert_levels: true,
        error_msg: action.error_msg,
      });
    case UPDATE_ALERT_LEVELS:
      return Object.assign({}, state, {
        alert_levels: action.alert_levels,
        loading_alert_levels: false,
        valid_alert_levels: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_ALERT_LEVELS:
      return Object.assign({}, state, {
        valid_alert_levels: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_TIME_UNITS:
      return Object.assign({}, state, {
        time_units: {},
        loading_time_units: true,
        error_msg: action.error_msg,
      });
    case UPDATE_TIME_UNITS:
      return Object.assign({}, state, {
        time_units: action.time_units,
        loading_time_units: false,
        valid_time_units: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_TIME_UNITS:
      return Object.assign({}, state, {
        valid_time_units: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_STATE_DESCRIPTIONS:
      return Object.assign({}, state, {
        state_descriptions: {},
        loading_state_descriptions: true,
        error_msg: action.error_msg,
      });
    case UPDATE_STATE_DESCRIPTIONS:
      return Object.assign({}, state, {
        state_descriptions: action.state_descriptions,
        loading_state_descriptions: false,
        valid_state_descriptions: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_STATE_DESCRIPTIONS:
      return Object.assign({}, state, {
        valid_state_descriptions: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_HEURISTIC_RULES:
      return Object.assign({}, state, {
        heuristic_rules: [],
        loading_heuristic_rules: true,
        error_msg: action.error_msg,
      });
    case UPDATE_HEURISTIC_RULES:
      return Object.assign({}, state, {
        heuristic_rules: action.heuristic_rules,
        loading_heuristic_rules: false,
        valid_heuristic_rules: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_HEURISTIC_RULES:
      return Object.assign({}, state, {
        valid_heuristic_rules: false,
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_RELATIONAL_OPERATORS:
      return Object.assign({}, state, {
        relational_operators: [],
        loading_relational_operators: true,
        error_msg: action.error_msg,
      });
    case UPDATE_RELATIONAL_OPERATORS:
      return Object.assign({}, state, {
        relational_operators: action.relational_operators,
        loading_relational_operators: false,
        valid_relational_operators: false,
        error_msg: action.error_msg,
      });
    case ERROR_GET_RELATIONAL_OPERATORS:
      return Object.assign({}, state, {
        valid_relational_operators: false,
        error_msg: action.error_msg,
      });

    default:
      return state;
  }
};
