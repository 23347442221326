import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./ViewsTable.css";

import SearchField from "../../utils/components/SearchField";
import InformationModal from "../../utils/components/InformationModal";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "1040px",
    height: "90%",
    maxHeight: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  linkMachinesModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectAll: {
    float: "left",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  textInput: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
}));

const generateNewDictOfSelectedMachines = (machines) => {
  let machines_dict = {};
  machines.forEach((item, i) => {
    machines_dict[item["machine_id"]] = item["is_active"];
  });
  return machines_dict;
};

const MachinesView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [showExcededMachineLimitModal, setShowExcededMachineLimitModal] =
    React.useState(false);
  const [selectedMachines, setSelectedMachines] = React.useState({});
  const [showNoWeekPassedModal, setShowNoWeekPassedModal] =
    React.useState(false);

  const get_all_distributor_machines = props.getAllDistributorMachines;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_all_distributor_machines(auth_token);
  }, [get_all_distributor_machines, auth_token]);

  React.useEffect(() => {
    setSelectedMachines(
      generateNewDictOfSelectedMachines(props.allDistributorMachines)
    );
  }, [props.allDistributorMachines]);

  try {
    const changeMachineState = (machine_id, new_state) => {
      let new_selected_elements_dict = { ...selectedMachines };
      new_selected_elements_dict[machine_id] = new_state;
      setSelectedMachines(new_selected_elements_dict);
    };

    let all_distributor_machines = props.allDistributorMachines;

    const handleAccept = () => {
      let output = [];
      Object.keys(selectedMachines).forEach((item, i) => {
        let machine_data = {
          machine_id: parseInt(item),
          is_active: selectedMachines[item],
        };
        output.push(machine_data);
      });
      props.updateMachinesState(output);
    };
    const handleCancel = () => {
      setSelectedMachines(
        generateNewDictOfSelectedMachines(props.allDistributorMachines)
      );
    };

    let machinesCount = "";
    let countSelectedMachines = 0;
    Object.keys(selectedMachines).forEach((item, i) => {
      if (selectedMachines[item]) {
        countSelectedMachines++;
      }
    });
    if (props.distributor_contract_plan.contract_plan !== null) {
      if (props.distributor_contract_plan.contract_plan.max_machines === -1) {
        machinesCount = t("machinesView.unlimitedMachines");
      } else {
        machinesCount =
          countSelectedMachines +
          t("machinesView.of") +
          (props.distributor_contract_plan.contract_plan.max_machines +
            props.distributor_contract_plan.extra_machines) +
          t("machinesView.machines");
      }
    }

    all_distributor_machines = all_distributor_machines.filter((machine) =>
      dict_contains_search_input(machine, props.searchInput)
    );

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={9}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item xs={3} className={classes.alignRight}>
            <h3 style={{ marginTop: "7px" }}>{machinesCount}</h3>
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={all_distributor_machines}
              loading={props.loadingAllDistributorMachines}
              columns={[
                {
                  Header: null,
                  width: 55,
                  Cell: ({ row }) => (
                    <Checkbox
                      checked={selectedMachines[row._original.machine_id]}
                      onChange={(value) => {
                        if (
                          value.target.checked &&
                          props.distributor_contract_plan.contract_plan !== null
                        ) {
                          if (
                            countSelectedMachines + 1 >
                              props.distributor_contract_plan.contract_plan
                                .max_machines +
                                props.distributor_contract_plan
                                  .extra_machines &&
                            props.distributor_contract_plan.contract_plan
                              .max_machines !== -1
                          ) {
                            setShowExcededMachineLimitModal(true);
                          } else {
                            changeMachineState(
                              row._original.machine_id,
                              value.target.checked
                            );
                          }
                        } else {
                          if (row._original.last_activation_date !== null) {
                            let lastActivationDatePlusWeek = new Date(
                              row._original.last_activation_date
                            );
                            lastActivationDatePlusWeek.setDate(
                              lastActivationDatePlusWeek.getDate() + 7
                            );
                            if (lastActivationDatePlusWeek < new Date()) {
                              changeMachineState(
                                row._original.machine_id,
                                value.target.checked
                              );
                            } else {
                              setShowNoWeekPassedModal(true);
                            }
                          } else {
                            changeMachineState(
                              row._original.machine_id,
                              value.target.checked
                            );
                          }
                        }
                      }}
                    />
                  ),
                },
                {
                  Header: t("machinesView.ckserial"),
                  accessor: "ckserial",
                },
                {
                  Header: t("machinesView.model"),
                  accessor: "model",
                },
                {
                  Header: t("machinesView.alias"),
                  accessor: "alias",
                },
                {
                  Header: t("machinesView.installationDate"),
                  accessor: "installation_date",
                },
                {
                  Header: t("machinesView.address"),
                  accessor: "address",
                },
              ]}
              defaultPageSize={10}
              className="linkTable"
              showPageSizeOptions={false}
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
          <Grid item xs={12}>
            <Container style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleAccept}
              >
                {t("machinesView.accept")}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleCancel}
              >
                {t("machinesView.cancel")}
              </Button>
            </Container>
          </Grid>
        </Grid>
        <InformationModal
          open={showExcededMachineLimitModal}
          handleClose={() => setShowExcededMachineLimitModal(false)}
          title={t("excededMachineLimitModal.title")}
          description={t("excededMachineLimitModal.description")}
          buttonDescription={t("excededMachineLimitModal.accept")}
        />
        <InformationModal
          open={showNoWeekPassedModal}
          handleClose={() => setShowNoWeekPassedModal(false)}
          title={t("noWeekPassedModal.title")}
          description={t("noWeekPassedModal.description")}
          buttonDescription={t("noWeekPassedModal.accept")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default MachinesView;
