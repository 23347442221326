import {
  update_personal_user_profile,
  update_alert,
  get_alerts,
  get_nonactive_alerts,
} from "../../api_calls/general_api_calls";
import { ATTEMPT_TO_LOGIN } from "../actions/login_action_types";
import {
  ATTEMPT_TO_GET_ALERTS,
  ATTEMPT_TO_GET_NONACTIVE_ALERTS,
  UPDATE_ALERTS_PAGE,
} from "../actions/general_action_types";
import { login, logout } from "../../api_calls/login_api_calls";

export const mapStateToPropsApp = (state, ownProps) => {
  return {
    auth_token: state.login.auth_token,
    is_data_loading: JSON.parse(
      JSON.stringify(
        state.general.loading_alerts && state.machines.loading_machines
      )
    ),
    view_path: state.router.location.pathname,
    is_logging_in: state.login.is_logging_in,
  };
};

export const mapDispatchToPropsApp = (dispatch) => {
  return {
    onAuthInLocalStorage: (user, password, t) => {
      dispatch({ type: ATTEMPT_TO_LOGIN });
      login(user, password, false, dispatch, t);
    },
    logout: () => logout(dispatch),
  };
};

export const mapStateToPropsContentWindow = (state, ownProps) => {
  return {
    machines_set: state.machines.machines,
    alerts_set: state.general.alerts,
    selected_distributor: state.general.selected_distributor,
    selected_group: state.general.selected_group,
    selected_machine: state.general.selected_machine,
    search_input: state.general.search_input,
    distributors_with_groups: state.general.distributors_with_groups,
    distributor_contract_plan: state.general.distributor_contract_plan,
  };
};

export const mapStateToPropsAlertsModal = (state, ownProps) => {
  return {
    alerts: state.general.alerts,
    nonactive_alerts: state.general.nonactive_alerts,
    alert_types: state.general.alert_types,
    active_alert_change_types: state.general.active_alert_change_types,
    auth_token: state.login.auth_token,
    are_alerts_loading: state.general.loading_alerts,
    are_nonactive_alerts_loading: state.general.loading_nonactive_alerts,
    alerts_page: state.general.alerts_page,
  };
};

export const mapDispatchToPropsAlertsModal = (dispatch) => {
  return {
    update_alert: (
      auth_token,
      alert_state,
      alerts_hash,
      non_active_alerts_count
    ) => {
      update_alert(
        auth_token,
        alert_state,
        alerts_hash,
        non_active_alerts_count,
        dispatch
      );
    },
    get_alerts: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERTS });
      get_alerts(auth_token, dispatch);
    },
    get_nonactive_alerts: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_NONACTIVE_ALERTS });
      get_nonactive_alerts(auth_token, dispatch);
    },
    update_alerts_page: (alerts_page) => {
      dispatch({ type: UPDATE_ALERTS_PAGE, alerts_page: alerts_page });
    },
  };
};

export const mapStateToPropsPersonalUserProfile = (state, ownProps) => {
  return {
    user_profile: state.general.user_profile,
    auth_token: state.login.auth_token,
    distributors_with_groups: state.general.distributors_with_groups,
  };
};

export const mapDispatchToPropsPersonalUserProfile = (dispatch) => {
  return {
    update_user_profile: (user_profile, token) => {
      update_personal_user_profile(token, user_profile, dispatch);
    },
  };
};
