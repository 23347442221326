import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Restore from "@material-ui/icons/Restore";

import SearchField from "../../utils/components/SearchField";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";
import UsersModal from "./UsersModal";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
}));

const assign_name_to_dist_and_group = (dict, distributors_with_groups) => {
  let group_name;
  if (
    distributors_with_groups[dict.distributor].groups[dict.group].is_default
  ) {
    group_name = "-";
  } else {
    group_name =
      distributors_with_groups[dict.distributor].groups[dict.group].name;
  }
  return Object.assign({}, dict, {
    distributor_name: distributors_with_groups[dict.distributor].name,
    group_name: group_name,
  });
};

const UsersView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [showNonActiveUsers, showNonActiveUsersSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [editUserModalOpen, editUserModalSetState] = React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);
  const [showExcededUserLimitModal, setShowExcededUserLimitModal] =
    React.useState(false);

  const get_users = props.getUsers;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_users(auth_token);
  }, [get_users, auth_token]);

  let users = props.users;
  if (!showNonActiveUsers) {
    users = users.filter((user) => user.is_active);
  }
  users = users.filter((dict) =>
    dict_contains_search_input(dict, props.searchInput)
  );
  users = users.map((dict) =>
    assign_name_to_dist_and_group(dict, props.distributorsWithGroups)
  );
  users = users.map((user) => {
    if (user.is_admin) {
      user.role = t("usersView.administrator");
    } else {
      user.role = t("usersView.user");
    }
    return user;
  });

  let distributorsWithGroups_filtered = Object.keys(
    props.distributorsWithGroups
  )
    .filter((key) => props.distributorsWithGroups[key].is_active)
    .reduce((obj, key) => {
      let groups = props.distributorsWithGroups[key].groups;
      groups = Object.keys(groups)
        .filter((group_key) => groups[group_key].is_active)
        .reduce((group_obj, group_key) => {
          return {
            ...group_obj,
            [group_key]: groups[group_key],
          };
        }, {});

      let distributor = props.distributorsWithGroups[key];
      distributor.groups = groups;

      return {
        ...obj,
        [key]: distributor,
      };
    }, {});

  let usersCount = "";
  let activeUsers = props.users.filter((user) => user.is_active);
  if (props.distributor_contract_plan.contract_plan !== null) {
    if (props.distributor_contract_plan.contract_plan.max_users === -1) {
      usersCount = t("usersView.unlimitedUsers");
    } else {
      usersCount =
        activeUsers.length +
        t("usersView.of") +
        (props.distributor_contract_plan.contract_plan.max_users +
          props.distributor_contract_plan.extra_users) +
        t("usersView.users");
    }
  }

  return (
    <div
      style={{ width: "80%", height: "625px", float: "right", padding: "20px" }}
    >
      <Grid container spacing={1} alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <SearchField
            value={props.searchInput}
            onChange={props.searchInputSetState}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={5}>
          <Button
            variant="contained"
            className={classes.newUserButton}
            onClick={() => {
              if (props.distributor_contract_plan.contract_plan !== null) {
                if (
                  activeUsers.length + 1 >
                    props.distributor_contract_plan.contract_plan.max_users +
                      props.distributor_contract_plan.extra_users &&
                  props.distributor_contract_plan.contract_plan.max_users !== -1
                ) {
                  setShowExcededUserLimitModal(true);
                } else {
                  editUserModalSetState(true);
                }
              } else {
                editUserModalSetState(true);
              }
            }}
          >
            <div style={{ textAlign: "right" }}>
              {t("usersView.newUserButton")}
            </div>
          </Button>
        </Grid>
        <Grid item lg={6} md={4} xs={2}>
          <h3 style={{ marginTop: "7px" }}>{usersCount}</h3>
        </Grid>
        <Grid item lg={3} md={4} xs={5}>
          {t("usersView.showSetDownUsers")}
          <Switch
            className={classes.showSetDownUsers}
            checked={showNonActiveUsers}
            onChange={(event) =>
              showNonActiveUsersSetState(event.target.checked)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ReactTable
            data={users}
            columns={[
              {
                Header: null,
                width: 50,
                Cell: ({ row }) => {
                  if (row._original.username === props.loggedUser) return null;
                  if (row._original.is_active) {
                    return (
                      <Delete
                        className={classes.icon}
                        onClick={() => {
                          selectedElementSetState(row._original);
                          deleteModalSetState(true);
                        }}
                      />
                    );
                  } else {
                    return (
                      <Restore
                        className={classes.icon}
                        onClick={() => {
                          if (
                            props.distributor_contract_plan.contract_plan !==
                            null
                          ) {
                            if (
                              activeUsers.length + 1 >
                                props.distributor_contract_plan.contract_plan
                                  .max_users +
                                  props.distributor_contract_plan.extra_users &&
                              props.distributor_contract_plan.contract_plan !==
                                -1
                            ) {
                              setShowExcededUserLimitModal(true);
                            } else {
                              props.updateUserState(
                                row._original.username,
                                true
                              );
                            }
                          } else {
                            props.updateUserState(row._original.username, true);
                          }
                        }}
                      />
                    );
                  }
                },
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      alignItems: "center",
                    },
                  };
                },
              },
              {
                Header: null,
                width: 50,
                Cell: ({ row }) => (
                  <Edit
                    className={classes.icon}
                    onClick={() => {
                      selectedElementSetState(row._original);
                      editUserModalSetState(true);
                    }}
                  />
                ),
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      alignItems: "center",
                    },
                  };
                },
              },
              {
                Header: t("usersView.isBlocked"),
                width: 50,
                Cell: ({ row }) => (
                  <Checkbox
                    disabled
                    checked={row._original.is_blocked}
                    className={classes.icon}
                  />
                ),
              },
              {
                Header: t("usersView.username"),
                accessor: "username",
              },
              {
                Header: t("usersView.profile"),
                accessor: "role",
              },
              {
                Header: t("usersView.distributor"),
                accessor: "distributor_name",
              },
              {
                Header: t("usersView.group"),
                accessor: "group_name",
              },
              {
                Header: t("usersView.email"),
                accessor: "email",
              },
              {
                Header: t("usersView.lastLogin"),
                accessor: "last_login",
              },
            ]}
            defaultSorted={[
              {
                id: "username",
                desc: false,
              },
            ]}
            loading={props.loadingUsers}
            defaultPageSize={10}
            showPageSizeOptions={false}
            className="viewsTable"
            previousText={t("tableTags.previous")}
            nextText={t("tableTags.next")}
            loadingText={t("tableTags.loading")}
            noDataText={t("tableTags.noData")}
            pageText={t("tableTags.page")}
            ofText={t("tableTags.of")}
            rowsText={t("tableTags.rows")}
          />
        </Grid>
      </Grid>
      <AcceptActionModal
        open={deleteModalOpen}
        handleClose={() => {
          selectedElementSetState(null);
          deleteModalSetState(false);
        }}
        handleAcceptButton={() => {
          props.updateUserState(selectedElement.username, false);
          selectedElementSetState(null);
          deleteModalSetState(false);
        }}
        title={t("sureToDeleteModal.title")}
        description={t("sureToDeleteModal.description")}
        acceptButtonDescription={t("sureToDeleteModal.delete")}
        cancelButtonDescription={t("sureToDeleteModal.cancel")}
      />
      <UsersModal
        open={editUserModalOpen}
        handleClose={() => {
          selectedElementSetState(null);
          editUserModalSetState(false);
        }}
        userData={selectedElement}
        distributorsWithGroups={distributorsWithGroups_filtered}
        createOrUpdateUserProfile={props.createOrUpdateUserProfile}
        resetPassword={props.resetPassword}
        users={props.users}
        successfulReturnUserCreateOrUpdate={
          props.successfulReturnUserCreateOrUpdate
        }
        loadingUserCreateOrUpdate={props.loadingUserCreateOrUpdate}
        loadingPasswordChangeRequest={props.loadingPasswordChangeRequest}
        successfulReturnPasswordChangeRequest={
          props.successfulReturnPasswordChangeRequest
        }
        loggedUser={props.loggedUser}
        clear_error_msg={props.clear_error_msg}
        distributor_contract_plan={props.distributor_contract_plan}
        errorMsg={props.errorMsg}
      />
      <InformationModal
        open={showExcededUserLimitModal}
        handleClose={() => setShowExcededUserLimitModal(false)}
        title={t("excededUserLimitModal.title")}
        description={t("excededUserLimitModal.description")}
        buttonDescription={t("excededUserLimitModal.accept")}
      />
    </div>
  );
};

export default UsersView;
