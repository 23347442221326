import {
  Bar,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "rgb(255, 163, 15)",
  },
  machineChartContainer: {
    border: "6px solid rgb(255, 163, 15)",
    borderRadius: "40px",
  },
}));

const colors = ["green", "orange", "red"];

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={"translate(" + x + "," + y + ")"}>
      <text dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const MachineForRiskLevelChart = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.machineChartContainer}>
      <div className={classes.title}>{props.title}</div>
      <ResponsiveContainer width="95%" height={300}>
        <BarChart data={props.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={<CustomizedAxisTick />} dataKey="name" />
          <YAxis />
          <Bar dataKey="riskLevel">
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MachineForRiskLevelChart;
