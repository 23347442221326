import {
  login,
  reset_password,
  reset_password_confirm,
} from "../../api_calls/login_api_calls";
import {
  UPDATING_USER,
  UPDATING_PASSWORD,
  UPDATING_REMEMBER_ME,
  ATTEMPT_TO_LOGIN,
  ATTEMPT_TO_RESET_PASSWORD,
  ATTEMPT_TO_RESET_PASSWORD_CONFIRM,
  CLEAR_LOGIN_ERROR_MSG,
} from "../actions/login_action_types";
import { push } from "connected-react-router";

export const mapStateToPropsLogin = (state, ownProps) => {
  return {
    state: state.login,
  };
};
export const mapDispatchToPropsLogin = (dispatch) => {
  return {
    onUserInputChange: (event) =>
      dispatch({
        type: UPDATING_USER,
        value: event.target.value,
      }),
    onPasswordInputChange: (event) =>
      dispatch({
        type: UPDATING_PASSWORD,
        value: event.target.value,
      }),
    onRememberMeCheckBoxChange: (event) =>
      dispatch({
        type: UPDATING_REMEMBER_ME,
        value: event.target.checked,
      }),
    onLoginClick: (user, password, remember_me, t) => {
      dispatch({ type: ATTEMPT_TO_LOGIN });
      login(user, password, remember_me, dispatch, t);
    },
    clear_error_msg: () => dispatch({ type: CLEAR_LOGIN_ERROR_MSG }),
  };
};

export const mapStateToPropsResetPasswordForm = (state, ownProps) => {
  return {
    loading_password_change_request:
      state.login.loading_password_change_request,
    successful_return_password_change_request:
      state.login.successful_return_password_change_request,
    error_msg: state.login.error_msg,
  };
};
export const mapDispatchToPropsResetPasswordForm = (dispatch) => {
  return {
    reset_password: (email) => {
      dispatch({ type: ATTEMPT_TO_RESET_PASSWORD });
      reset_password(email, dispatch);
    },
    clear_error_msg: () => dispatch({ type: CLEAR_LOGIN_ERROR_MSG }),
    push: (value) => dispatch(push(value)),
  };
};

export const mapStateToPropsResetPasswordConfirm = (state, ownProps) => {
  return {
    loading_password_change_confirm_request:
      state.login.loading_password_change_confirm_request,
    successful_return_password_change_confirm_request:
      state.login.successful_return_password_change_confirm_request,
    error_msg: state.login.error_msg,
  };
};
export const mapDispatchToPropsResetPasswordConfirm = (dispatch) => {
  return {
    reset_password_confirm: (uid, token, password1, password2) => {
      dispatch({ type: ATTEMPT_TO_RESET_PASSWORD_CONFIRM });
      reset_password_confirm(uid, token, password1, password2, dispatch);
    },
    clear_error_msg: () => dispatch({ type: CLEAR_LOGIN_ERROR_MSG }),
    push: (value) => dispatch(push(value)),
  };
};
