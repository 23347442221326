const SERVER_URL_DEV = "http://datisiontest.datision.com:8000";
const SERVER_URL_PROD =
  window.location.href.split("/")[0] +
  "/" +
  window.location.href.split("/")[1] +
  "/" +
  window.location.href.split("/")[2] +
  "/";

export const SERVER_URL =
  process.env.NODE_ENV === "development" ? SERVER_URL_DEV : SERVER_URL_PROD;

const USERS_API = SERVER_URL.concat("/users/v1/");
export const LOGIN_URL = USERS_API.concat("login/");
export const LOGOUT_URL = USERS_API.concat("logout/");
export const RESET_PASSWORD_URL = USERS_API.concat("password/reset/");
export const RESET_PASSWORD_CONFIRM_URL = USERS_API.concat(
  "password/reset/confirm/"
);
export const USER_PROFILE_URL = USERS_API.concat("user_profile");
export const UPDATE_PERSONAL_USER_PROFILE_URL = USERS_API.concat(
  "update_personal_user_profile"
);
export const USERS_URL = USERS_API.concat("users");
export const UPDATE_USER_STATE_URL = USERS_API.concat("update_user_state");
export const CREATE_OR_UPDATE_USER_PROFILE_URL = USERS_API.concat(
  "create_or_update_user"
);

const ALERTS_API = SERVER_URL.concat("/alerts/v1/");
export const ALERTS_URL = ALERTS_API.concat("alerts");
export const NONACTIVE_ALERTS_URL = ALERTS_API.concat("nonactive_alerts");
export const ALERT_TYPES_URL = ALERTS_API.concat("alert_types");
export const ACTIVE_ALERT_CHANGE_TYPES_URL = ALERTS_API.concat(
  "active_alert_change_types"
);
export const ALERT_CHANGE_TYPES_URL = ALERTS_API.concat("alert_change_types");
export const UPDATE_ALERT_CHANGE_STATE_URL = ALERTS_API.concat(
  "update_alert_change_state"
);
export const CREATE_OR_UPDATE_ALERT_CHANGE_URL = ALERTS_API.concat(
  "create_or_update_alert_change"
);
export const UPDATE_ALERT_URL = ALERTS_API.concat("update_alert");
export const ALERTS_HASH_URL = ALERTS_API.concat("alerts_hash");

const DISTRIBUTORS_API = SERVER_URL.concat("/distributors/v1/");
export const DISTRIBUTORS_URL = DISTRIBUTORS_API.concat("distributors");
export const DISTRIBUTOR_WITH_GROUPS_URL = DISTRIBUTORS_API.concat(
  "distributors_with_groups"
);
export const DISTRIBUTOR_CONTRACT_PLAN_URL = DISTRIBUTORS_API.concat(
  "distributor_contract_plan"
);
export const UPDATE_DISTRIBUTOR_STATE_URL = DISTRIBUTORS_API.concat(
  "update_distributor_state"
);
export const CREATE_OR_UPDATE_DISTRIBUTOR_URL = DISTRIBUTORS_API.concat(
  "create_or_update_distributor"
);
export const NON_ACTIVE_DISTRIBUTORS_URL = DISTRIBUTORS_API.concat(
  "non_active_distributors"
);
export const NON_ACTIVE_DISTRIBUTOR_MACHINES_URL = DISTRIBUTORS_API.concat(
  "distributor_machines"
);

const CONTRACT_PLANS_API = SERVER_URL.concat("/contract_plans/v1/");
export const CONTRACT_PLANS_URL = CONTRACT_PLANS_API.concat("contract_plans");
export const UPDATE_CONTRACT_PLAN_STATE_URL = CONTRACT_PLANS_API.concat(
  "update_contract_plan_state"
);
export const CREATE_OR_UPDATE_CONTRACT_PLAN_URL = CONTRACT_PLANS_API.concat(
  "create_or_update_contract_plan"
);

const MANUAL_HEURISTIC_API = SERVER_URL.concat("/manual_heuristic/v1/");
export const DEVICES_EXTRA_INFO_URL =
  MANUAL_HEURISTIC_API.concat("devices_extra_info");
export const HEURISTIC_TYPES_URL =
  MANUAL_HEURISTIC_API.concat("heuristic_types");
export const ALERT_LEVELS_URL = MANUAL_HEURISTIC_API.concat("alert_levels");
export const TIME_UNITS_URL = MANUAL_HEURISTIC_API.concat("time_units");
export const STATE_DESCRIPTIONS_URL =
  MANUAL_HEURISTIC_API.concat("state_descriptions");
export const RELATIONAL_OPERATORLS_URL = MANUAL_HEURISTIC_API.concat(
  "relational_operators"
);
export const HEURISTIC_RULES_URL =
  MANUAL_HEURISTIC_API.concat("heuristic_rules");
export const CREATE_OR_UPDATE_HEURISTIC_RULE_URL = MANUAL_HEURISTIC_API.concat(
  "create_or_update_heuristic_rule"
);
export const UPDATE_HEURISTIC_RULE_STATE_URL = MANUAL_HEURISTIC_API.concat(
  "update_heuristic_rule_state"
);

const GROUPS_API = SERVER_URL.concat("/groups/v1/");
export const GROUPS_URL = GROUPS_API.concat("groups");
export const UPDATE_GROUP_STATE_URL = GROUPS_API.concat("update_group_state");
export const CREATE_OR_UPDATE_GROUP_URL = GROUPS_API.concat(
  "create_or_update_group"
);

const MACHINES_API = SERVER_URL.concat("/machines/v1/");
export const MACHINES_URL = MACHINES_API.concat("machines");
export const ALL_DISTRIBUTOR_MACHINES_URL = MACHINES_API.concat(
  "all_distributor_machines"
);
export const UPDATE_MACHINES_STATUS_URL = MACHINES_API.concat(
  "update_machines_state"
);
export const UPDATE_MACHINE_URL = MACHINES_API.concat("update_machine");
export const ACTIVE_MACHINE_SECTORS_URL = MACHINES_API.concat(
  "active_machine_sectors"
);
export const UPDATE_MACHINE_SECTORS_STATE_URL = MACHINES_API.concat(
  "update_machine_sectors_state"
);
export const CREATE_OR_UPDATE_MACHINE_SECTORS_URL = MACHINES_API.concat(
  "create_or_update_machine_sectors"
);
export const MACHINE_SECTORS_TYPES_URL = MACHINES_API.concat(
  "machine_sectors_types"
);

const PREDICTIVE_API = SERVER_URL.concat("/predictive/v1/");
export const MODEL_HISTORY_URL = PREDICTIVE_API.concat("model_history");
