import {
  ATTEMPT_TO_GET_PERSONAL_USER_PROFILE,
  UPDATE_PERSONAL_USER_PROFILE,
  ERROR_GET_PERSONAL_USER_PROFILE,
  ERROR_UPDATE_PERSONAL_USER_PROFILE,
  ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS,
  UPDATE_DISTRIBUTORS_WITH_GROUPS,
  ERROR_GET_DISTRIBUTORS_WITH_GROUPS,
  ATTEMPT_TO_GET_DISTRIBUTOR_CONTRACT_PLAN,
  UPDATE_DISTRIBUTOR_CONTRACT_PLAN,
  ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN,
  UPDATE_SELECTED_DISTRIBUTOR,
  UPDATE_SELECTED_GROUP,
  ATTEMPT_TO_GET_ALERTS,
  UPDATE_ALERTS,
  ERROR_GET_ALERTS,
  ERROR_UPDATE_ALERT,
  ATTEMPT_TO_GET_NONACTIVE_ALERTS,
  UPDATE_NONACTIVE_ALERTS,
  ERROR_GET_NONACTIVE_ALERTS,
  ERROR_UPDATE_NONACTIVE_ALERT,
  ATTEMPT_TO_GET_ALERT_TYPES,
  UPDATE_ALERT_TYPES,
  ERROR_GET_ALERT_TYPES,
  ATTEMPT_TO_GET_ALERTS_HASH,
  UPDATE_ALERTS_HASH,
  ERROR_GET_ALERTS_HASH,
  ERROR_UPDATE_ALERT_HASH,
  ATTEMPT_TO_GET_FIRST_ALERTS_HASH,
  UPDATE_FIRST_ALERTS_HASH,
  ERROR_GET_FIRST_ALERTS_HASH,
  ERROR_UPDATE_FIRST_ALERT_HASH,
  UPDATE_SINGLE_ALERT_DATA,
  UPDATE_ALERTS_PAGE,
  ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES,
  UPDATE_ACTIVE_ALERT_CHANGE_TYPES,
  ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES,
  UPDATE_SELECTED_MACHINE,
  UPDATE_SEARCH_INPUT,
  INITIAL_STATE,
} from "../actions/general_action_types";
import AlertsSet from "../../js/AlertsSet";

export const general_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ATTEMPT_TO_GET_PERSONAL_USER_PROFILE:
      return Object.assign({}, state, {
        loading_user_profile: true,
      });
    case UPDATE_PERSONAL_USER_PROFILE:
      return Object.assign({}, state, {
        user_profile: action.user_profile,
        valid_user_profile: true,
        loading_user_profile: false,
      });
    case ERROR_GET_PERSONAL_USER_PROFILE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_user_profile: false,
        loading_user_profile: false,
      });
    case ERROR_UPDATE_PERSONAL_USER_PROFILE:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS:
      return Object.assign({}, state, {
        selected_distributor: "-1",
        selected_group: "-1",
        distributors_with_groups: {},
        loading_distributors_with_groups: true,
      });
    case UPDATE_DISTRIBUTORS_WITH_GROUPS:
      let distributors_with_groups = action.distributors_with_groups;
      if (Object.keys(distributors_with_groups).length > 1) {
        distributors_with_groups["0"] = {
          name: "Show all",
          is_admin: true,
          is_active: true,
          groups: {
            0: {
              name: "Default",
              is_default: true,
              is_active: true,
            },
          },
        };
      }
      let selected_distributor_key = Object.keys(distributors_with_groups)[0];
      let selected_group_key = Object.keys(
        distributors_with_groups[selected_distributor_key].groups
      )[0];

      return Object.assign({}, state, {
        distributors_with_groups: distributors_with_groups,
        selected_distributor: selected_distributor_key,
        selected_group: selected_group_key,
        valid_distributors_with_groups: true,
        loading_distributors_with_groups: false,
      });
    case ERROR_GET_DISTRIBUTORS_WITH_GROUPS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_distributors_with_groups: false,
        loading_distributors_with_groups: false,
      });

    case UPDATE_SELECTED_DISTRIBUTOR:
      return Object.assign({}, state, {
        selected_distributor: action.selected_distributor,
        selected_group: Object.keys(
          state.distributors_with_groups[action.selected_distributor].groups
        )[0],
      });
    case UPDATE_SELECTED_GROUP:
      return Object.assign({}, state, {
        selected_group: action.selected_group,
      });

    case ATTEMPT_TO_GET_ALERTS:
      return Object.assign({}, state, {
        loading_alerts: true,
      });
    case UPDATE_ALERTS:
      return Object.assign({}, state, {
        alerts: new AlertsSet(action.alerts),
        valid_alerts: true,
        loading_alerts: false,
      });
    case ERROR_GET_ALERTS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_alerts: false,
        loading_alerts: false,
      });
    case ERROR_UPDATE_ALERT:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_NONACTIVE_ALERTS:
      return Object.assign({}, state, {
        loading_nonactive_alerts: true,
      });
    case UPDATE_NONACTIVE_ALERTS:
      return Object.assign({}, state, {
        nonactive_alerts: new AlertsSet(action.nonactive_alerts),
        valid_nonactive_alerts: true,
        loading_nonactive_alerts: false,
      });
    case ERROR_GET_NONACTIVE_ALERTS:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_nonactive_alerts: false,
        loading_nonactive_alerts: false,
      });
    case ERROR_UPDATE_NONACTIVE_ALERT:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_ALERT_TYPES:
      return Object.assign({}, state, {
        loading_alert_types: true,
      });
    case UPDATE_ALERT_TYPES:
      return Object.assign({}, state, {
        alert_types: action.alert_types,
        valid_alert_types: true,
        loading_alert_types: false,
      });
    case ERROR_GET_ALERT_TYPES:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_alert_types: false,
        loading_alert_types: false,
      });

    case ATTEMPT_TO_GET_ALERTS_HASH:
      return Object.assign({}, state, {
        loading_alerts_hash: true,
      });
    case UPDATE_ALERTS_HASH:
      return Object.assign({}, state, {
        alerts_hash: action.alerts_hash,
        valid_alerts_hash: true,
        loading_alerts_hash: false,
      });
    case ERROR_GET_ALERTS_HASH:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_alerts_hash: false,
        loading_alerts_hash: false,
      });
    case ERROR_UPDATE_ALERT_HASH:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case ATTEMPT_TO_GET_FIRST_ALERTS_HASH:
      return Object.assign({}, state, {
        loading_first_alerts_hash: true,
      });
    case UPDATE_FIRST_ALERTS_HASH:
      return Object.assign({}, state, {
        first_alerts_hash: action.first_alerts_hash,
        alerts_hash: action.alerts_hash,
        valid_alerts_hash: true,
        loading_alerts_hash: false,
      });
    case ERROR_GET_FIRST_ALERTS_HASH:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_first_alerts_hash: false,
        loading_first_alerts_hash: false,
      });

    case ERROR_UPDATE_FIRST_ALERT_HASH:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case UPDATE_SINGLE_ALERT_DATA:
      let alerts_set = state.alerts;
      alerts_set.update_alert(action.alert);
      return Object.assign({}, state, {
        alerts: new AlertsSet(alerts_set.get_alerts()),
      });

    case ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        active_alert_change_types: [],
        loading_active_alert_change_types: true,
      });
    case UPDATE_ACTIVE_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        active_alert_change_types: action.active_alert_change_types,
        valid_active_alert_change_types: true,
        loading_active_alert_change_types: false,
      });
    case ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_active_alert_change_types: false,
        loading_active_alert_change_types: false,
      });

    case ATTEMPT_TO_GET_DISTRIBUTOR_CONTRACT_PLAN:
      return Object.assign({}, state, {
        distributor_contract_plan: {},
        loading_distributor_contract_plan: true,
      });

    case UPDATE_DISTRIBUTOR_CONTRACT_PLAN:
      return Object.assign({}, state, {
        distributor_contract_plan: action.distributor_contract_plan,
        valid_distributor_contract_plan: false,
        loading_distributor_contract_plan: false,
      });

    case ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
        valid_distributor_contract_plan: false,
        loading_distributor_contract_plan: false,
      });

    case UPDATE_SELECTED_MACHINE:
      return Object.assign({}, state, {
        selected_machine: action.selected_machine,
      });

    case UPDATE_SEARCH_INPUT:
      return Object.assign({}, state, {
        search_input: action.search_input,
      });

    case UPDATE_ALERTS_PAGE:
      return Object.assign({}, state, {
        alerts_page: action.alerts_page,
      });

    default:
      return state;
  }
};
