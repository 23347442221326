import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";

import { useTranslation } from "react-i18next";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
        ),
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      key={JSON.stringify(suggestion)}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.alias +
        " - " +
        suggestion.ckserial +
        " - " +
        suggestion.address}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function getSuggestions(value, machines, { showEmpty = false } = {}) {
  let count = 0;

  return value.length === 0 && !showEmpty
    ? []
    : machines.filter((machine) => {
        var match_found = false;
        if (count < 5) {
          Object.keys(machine).forEach(function (key) {
            if (
              machine[key] != null &&
              machine[key]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            ) {
              match_found = true;
            }
          });
        }
        if (match_found) {
          count += 1;
        }
        return match_found;
      });
}

function renderEmptySuggestion(noItemsFoundLabel) {
  return (
    <MenuItem key={noItemsFoundLabel} component="div" disabled>
      {noItemsFoundLabel}
    </MenuItem>
  );
}

function generateSuggestions(
  value,
  machines,
  getItemProps,
  highlightedIndex,
  noItemsFoundLabel
) {
  var suggestions = getSuggestions(value, machines);
  if (value.length !== 0 && suggestions.length === 0) {
    return renderEmptySuggestion(noItemsFoundLabel);
  } else {
    return suggestions.map((suggestion, index) =>
      renderSuggestion({
        suggestion,
        index,
        itemProps: getItemProps({ item: suggestion }),
        highlightedIndex,
      })
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flexGrow: 1,
    position: "relative",
    border: "1px solid rgb(158, 158, 158)",
    borderRadius: "4px",
    backgroundColor: "white",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
    padding: "8px 8px 8px 56px",
  },
}));

const PredictiveSearchField = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  return (
    <div className={classes.root}>
      <Downshift
        onChange={(selected_item) =>
          props.selectedMachineSetState(selected_item)
        }
        itemToString={(item) =>
          item ? item.alias + " - " + item.ckserial + " - " + item.address : ""
        }
      >
        {({
          getInputProps,
          getItemProps,
          inputValue,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: t("scoringSearchField.search"),
          });

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps,
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {generateSuggestions(
                      inputValue,
                      props.machines,
                      getItemProps,
                      highlightedIndex,
                      t("scoringSearchField.noItemsFound")
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default PredictiveSearchField;
