import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
      height: "255px",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
      fontSize: "16px",
    },
    itemTitle: {
      fontWeight: "bold",
      textAlign: "right",
      fontSize: 16,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    itemContent: {
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: 16,
    },
  })
);

export default function MachineBasicData(props) {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  return (
    <Paper className={classes.root}>
      <Box className={classes.componentTitle}>
        {t("machineBasicData.title")}
      </Box>
      {props.selectedMachine != null && (
        <Box className={classes.boxContainer}>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.ckserial") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.ckserial}
              <br />
            </Grid>
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.alias") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.alias}
            </Grid>
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.address") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.address}
            </Grid>
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.installationDate") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.installation_date}
            </Grid>
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.distributor") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.distributor_name}
            </Grid>
            <Grid className={classes.itemTitle} item xs={4}>
              {t("machineBasicData.group") + ":"}
            </Grid>
            <Grid className={classes.itemContent} item xs={8}>
              {props.selectedMachine.group_name}
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
}
