// Function that returns true if an string is inside any of the values of a dictionary. Otherwise returns false.
import { RISK_RANGES } from "../../utils/constants/common_constants";

export const dict_contains_search_input = (dictionary, search_input) => {
  if (search_input.length === 0) {
    return true;
  }

  let match_found = false;
  Object.keys(dictionary).forEach(function (key) {
    if (
      dictionary[key] !== null &&
      dictionary[key]
        .toString()
        .toLowerCase()
        .includes(search_input.toLowerCase())
    ) {
      match_found = true;
    }
  });

  return match_found;
};

export const is_machine_from_distributor_and_group = (
  machine,
  distributor,
  group
) => {
  if (distributor.id !== -1 && group.id !== -1) {
    if (parseInt(distributor.id) === machine.distributor) {
      if (group.name === "Default") {
        return true;
      } else if (machine.group === parseInt(group.id)) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

export const get_risk_level = (risk) => {
  for (let i = 0; i < RISK_RANGES.length - 1; ++i) {
    if (risk >= RISK_RANGES[i][0] && risk < RISK_RANGES[i + 1][0]) {
      return RISK_RANGES[i][1];
    }
  }
  return RISK_RANGES[RISK_RANGES.length - 1][1];
};

// Function that gets a machine dict as parameter and return the same dict with the additional parameter risk_level
// (string that indicates the risk level of a certain machine)
export const add_translated_risk_to_machine = (machine, t) => {
  return Object.assign({}, machine, {
    risk_level: t("riskLevels." + get_risk_level(machine.risk)),
  });
};

export const sort_importance_features = (a, b) => {
  if (Math.abs(a.Importance) > Math.abs(b.Importance)) {
    return -1;
  } else if (Math.abs(a.Importance) < Math.abs(b.Importance)) {
    return 1;
  }
  return 0;
};

export const is_predictive_module = (contract_plan, is_distributor_admin) => {
  if (contract_plan != null) {
    return contract_plan.module_predictive || is_distributor_admin;
  } else {
    return false || is_distributor_admin;
  }
};
