import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Restore from "@material-ui/icons/Restore";

import SearchField from "../../utils/components/SearchField";
import DistributorsModal from "./DistributorsModal";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import LinkMachinesModal from "./LinkMachinesModal";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  newDistributorButton: {
    float: "left",
    marginTop: "4px",
    marginBottom: "18px",
    backgroundColor: "white",
  },
  showSetDownDistributors: {
    float: "right",
  },
  icon: {
    cursor: "pointer",
  },
}));

const DistributorsView = (props) => {
  const { t } = useTranslation("more_options_modals");

  const [selectedElement, selectedElementSetState] = React.useState(null);
  const [showNonActiveDistributors, showNonActiveDistributorsSetState] =
    React.useState(false);
  const [editDistributorModalOpen, editDistributorModalSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [linkMachinesModalOpen, linkMachinesModalSetState] =
    React.useState(false);
  const [abortController, abortControllerSetState] = React.useState(false);

  const get_distributors = props.getDistributors;
  const get_non_active_distributors = props.getNonActiveDistributors;
  const get_contract_plans = props.getContractPlans;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_distributors(auth_token);
  }, [get_distributors, auth_token]);
  React.useEffect(() => {
    get_non_active_distributors(auth_token);
  }, [get_non_active_distributors, auth_token]);
  React.useEffect(() => {
    get_contract_plans(auth_token);
  }, [get_contract_plans, auth_token]);

  try {
    const classes = useStyles();

    let distributors = props.distributors;
    if (!showNonActiveDistributors) {
      distributors = distributors.filter((dist) => dist.is_active);
    }
    distributors = distributors.filter((dist) =>
      dict_contains_search_input(dist, props.searchInput)
    );
    distributors.forEach((item, i) => {
      if (item.contract_plan !== null) {
        item.contract_plan_name = item.contract_plan.name;
      }
    });

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={4} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newDistributorButton}
              onClick={() => editDistributorModalSetState(true)}
            >
              {t("distributorsView.newDistributorButton")}
            </Button>
          </Grid>
          <Grid item lg={4} md={2} xs={false} />
          <Grid item lg={4} md={5} xs={6}>
            <div style={{ textAlign: "right" }}>
              {t("distributorsView.showSetDownDistributors")}
            </div>
            <Switch
              className={classes.showSetDownDistributors}
              checked={showNonActiveDistributors}
              onChange={(event) =>
                showNonActiveDistributorsSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={distributors}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_admin) return null;
                    if (row._original.id === props.loggedDistributor)
                      return null;
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            deleteModalSetState(true);
                            selectedElementSetState(row._original);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            props.updateDistributorState(
                              row._original.id,
                              true
                            );
                            selectedElementSetState(row._original);
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.id === props.loggedDistributor)
                      return null;
                    else {
                      return (
                        <Edit
                          className={classes.icon}
                          onClick={() => {
                            selectedElementSetState(row._original);

                            const controller = new AbortController();
                            const signal = controller.signal;

                            abortControllerSetState(controller);
                            props.getDistributorMachines(
                              row._original.id,
                              signal
                            );

                            linkMachinesModalSetState(true);
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("distributorsView.id"),
                  accessor: "id",
                },
                {
                  Header: t("distributorsView.name"),
                  accessor: "name",
                },
                {
                  Header: t("distributorsView.cif"),
                  accessor: "cif",
                },
                {
                  Header: t("distributorsView.address"),
                  accessor: "address",
                },
                {
                  Header: t("distributorsView.contract_plan"),
                  accessor: "contract_plan_name",
                },
                {
                  Header: t("distributorsView.extra_users"),
                  accessor: "extra_users",
                },
              ]}
              defaultSorted={[
                {
                  id: "name",
                  desc: false,
                },
              ]}
              loading={props.loadingDistributors}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="viewsTable"
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateDistributorState(selectedElement.id, false);
            deleteModalSetState(false);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
        <DistributorsModal
          open={editDistributorModalOpen}
          handleClose={() => editDistributorModalSetState(false)}
          nonActiveDistributors={props.nonActiveDistributors}
          getDistributorMachines={props.getDistributorMachines}
          distributorMachines={props.distributorMachines}
          contractPlans={props.contractPlans}
          createOrUpdateDistributor={(distributor_data) => {
            editDistributorModalSetState(false);
            props.createOrUpdateDistributor(distributor_data);
          }}
          removeNonActiveDistributorMachines={
            props.removeNonActiveDistributorMachines
          }
          loadingNonActiveDistributorMachines={
            props.loadingNonActiveDistributorMachines
          }
        />
        <LinkMachinesModal
          open={linkMachinesModalOpen}
          handleClose={() => {
            abortController.abort();
            props.removeNonActiveDistributorMachines();
            abortControllerSetState(null);
            linkMachinesModalSetState(false);
          }}
          contractPlans={props.contractPlans}
          distributorMachines={props.distributorMachines}
          createOrUpdateDistributor={props.createOrUpdateDistributor}
          loadingNonActiveDistributorMachines={
            props.loadingNonActiveDistributorMachines
          }
          distributor={selectedElement}
          editDistributor
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default DistributorsView;
