import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Delete from "@material-ui/icons/Delete";
import Restore from "@material-ui/icons/Restore";
import Edit from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { dict_contains_search_input } from "../../utils/js/common_functions";

import ReactTable from "react-table-6";
import SearchField from "../../utils/components/SearchField";
import ManualHeuristicModal from "./ManualHeuristicModal";
import AcceptActionModal from "../../utils/components/AcceptActionModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    height: "75%",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  showSetDownUsers: {
    float: "right",
  },
  newUserButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  blockedUserCheckbox: {
    textAlign: "right",
    paddingRight: "0px",
    float: "left",
    paddingLeft: "8px",
  },
  textInput: {
    width: "100%",
  },
  resetPasswordContainer: {
    textAlign: "right",
    paddingRight: "0px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  errorMsg: {
    color: "red",
  },
  sentMsg: {
    color: "green",
  },
  icon: {
    cursor: "pointer",
  },
}));

const ManualHeuristicView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [editManualHeuristicModal, setEditManualHeuristicModal] =
    React.useState(false);
  const [showNonActiveHeuristicRules, showNonActiveHeuristicRulesSetState] =
    React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);

  const get_heuristic_rules = props.getHeuristicRules;
  const get_devices_extra_info = props.getDevicesExtraInfo;
  const get_heuristic_types = props.getHeuristicTypes;
  const get_time_units = props.getTimeUnits;
  const get_state_descriptions = props.getStateDescriptions;
  const get_relational_operators = props.getRelationalOperators;
  const auth_token = props.authToken;

  const are_dropdowns_loading = () => {
    return (
      props.loadingDevicesExtraInfo ||
      props.loadingHeuristicTypes ||
      props.loadingTimeUnits ||
      props.loadingStateDescriptions ||
      props.loadingRelationalOperators
    );
  };

  React.useEffect(() => {
    get_heuristic_rules(auth_token);
  }, [get_heuristic_rules, auth_token]);

  React.useEffect(() => {
    get_devices_extra_info(auth_token);
  }, [get_devices_extra_info, auth_token]);

  React.useEffect(() => {
    get_heuristic_types(auth_token);
  }, [get_heuristic_types, auth_token]);

  React.useEffect(() => {
    get_time_units(auth_token);
  }, [get_time_units, auth_token]);

  React.useEffect(() => {
    get_state_descriptions(auth_token);
  }, [get_state_descriptions, auth_token]);

  React.useEffect(() => {
    get_relational_operators(auth_token);
  }, [get_relational_operators, auth_token]);

  try {
    let heuristic_rules = props.heuristicRules;
    if (!showNonActiveHeuristicRules) {
      heuristic_rules = heuristic_rules.filter(
        (heuristic_rule) => heuristic_rule.is_active
      );
    }

    heuristic_rules = heuristic_rules.filter((heuristic_rules) =>
      dict_contains_search_input(heuristic_rules, props.searchInput)
    );

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={5} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newUserButton}
              disabled={are_dropdowns_loading()}
              onClick={() => setEditManualHeuristicModal(true)}
            >
              <div style={{ textAlign: "right" }}>
                {t("manualHeuristic.newManualHeuristicButton")}
              </div>
            </Button>
          </Grid>
          <Grid item lg={2} md={2} xs={false} />
          <Grid item lg={5} md={5} xs={6}>
            <div style={{ textAlign: "right" }}>
              {t("manualHeuristic.showSetDownManualHeuristic")}
            </div>
            <Switch
              className={classes.showSetDownUsers}
              checked={showNonActiveHeuristicRules}
              onChange={(event) =>
                showNonActiveHeuristicRulesSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={heuristic_rules}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            selectedElementSetState(row._original);
                            deleteModalSetState(true);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            props.updateHeuristicRuleState(
                              row._original.id,
                              true
                            );
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => (
                    <Edit
                      className={classes.icon}
                      onClick={() => {
                        selectedElementSetState(row._original);
                        setEditManualHeuristicModal(true);
                      }}
                    />
                  ),
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("manualHeuristic.id"),
                  accessor: "id",
                },
                {
                  Header: t("manualHeuristic.name"),
                  accessor: "name",
                },
                {
                  Header: t("manualHeuristic.type"),
                  accessor: "type_description",
                },
                {
                  Header: t("manualHeuristic.device"),
                  accessor: "device_description",
                },
                {
                  Header: t("manualHeuristic.event"),
                  accessor: "event_description",
                },
                {
                  Header: t("manualHeuristic.level"),
                  accessor: "level_description",
                },
              ]}
              loading={props.loadingHeuristicRules}
              defaultPageSize={10}
              className="linkTable"
              showPageSizeOptions={false}
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <ManualHeuristicModal
          open={editManualHeuristicModal}
          manualHeuristicData={selectedElement}
          devicesExtraInfo={props.devicesExtraInfo}
          heuristicTypes={props.heuristicTypes}
          alertLevels={props.alertLevels}
          timeUnits={props.timeUnits}
          stateDescriptions={props.stateDescriptions}
          relationalOperators={props.relationalOperators}
          createOrUpdateHeuristicRule={props.createOrUpdateHeuristicRule}
          handleClose={() => {
            selectedElementSetState(null);
            setEditManualHeuristicModal(false);
          }}
        />
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateHeuristicRuleState(selectedElement.id, false);
            deleteModalSetState(false);
            selectedElementSetState(null);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default ManualHeuristicView;
