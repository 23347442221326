import React from "react";

import clsx from "clsx";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
    },
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    icon: {
      height: 150,
      width: 150,
      [theme.breakpoints.down("lg")]: {
        height: 115,
        width: 115,
      },
      [theme.breakpoints.down("md")]: {
        height: 150,
        width: 150,
      },
      [theme.breakpoints.down("sm")]: {
        height: 135,
        width: 135,
      },
      [theme.breakpoints.down("xs")]: {
        height: 135,
        width: 135,
      },
    },
    iconLow: {
      color: "green",
    },
    iconMedium: {
      color: "#ffcc80",
    },
    iconHigh: {
      color: "red",
    },
    header: {
      textAlign: "right",
      paddingRight: "6px",
      fontWeight: "bold",
    },
    content: {
      textAlign: "left",
      paddingLeft: "6px",
    },
    headerContentTextSize: {
      fontSize: 16,
    },
  })
);

const ComponentStateDisplayer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  const generate_state_displayer = () => {
    if (props.component != null) {
      let state = props.component.state;
      let state_icon;
      switch (state) {
        case 1:
          state_icon = (
            <CheckCircleOutline
              className={clsx(classes.icon, classes.iconLow)}
            />
          );
          break;

        case 2:
          state_icon = (
            <ErrorOutline className={clsx(classes.icon, classes.iconMedium)} />
          );
          break;

        case 3:
          state_icon = (
            <ErrorOutline className={clsx(classes.icon, classes.iconHigh)} />
          );
          break;

        default:
          state_icon = (
            <CheckCircleOutline
              className={clsx(classes.icon, classes.iconLow)}
            />
          );
      }
      return (
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={3} md={4}>
            {state_icon}
          </Grid>
          <Grid item xs={9} md={8}>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.header)}
                xs={6}
              >
                {t("componentStateDisplayer.state")}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.content)}
                xs={6}
              >
                {props.component.state_level}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.header)}
                xs={6}
              >
                {t("componentStateDisplayer.description")}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.content)}
                xs={6}
              >
                {props.component.state_description.length > 0 &&
                  props.component.state_description}
                {props.component.state_description.length === 0 && "-"}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else return null;
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.componentTitle}>
        {t("componentStateDisplayer.title")}
      </Box>
      <CardContent className={classes.cardContent}>
        {generate_state_displayer()}
      </CardContent>
    </Paper>
  );
};

export default ComponentStateDisplayer;
