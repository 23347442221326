import React from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Lock from "@material-ui/icons/Lock";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "300px",
  },
}));

/**
 *  Base _TextField_ component for all the textfields of **Predik** application.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function TextInput(props) {
  const classes = useStyles();

  let inputProps = {};
  if (props.disabled) {
    inputProps = {
      endAdornment: (
        <InputAdornment>
          <Lock />
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      disabled={props.disabled}
      label={props.label}
      className={clsx(classes.textField, props.className)}
      value={props.value}
      margin="normal"
      type={props.type}
      multiline={props.multiline}
      rowsMax={props.rowsMax}
      variant="outlined"
      InputProps={inputProps}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}

TextInput.propTypes = {
  /** If true, textfield is disabled (is not possible to change the value of the dropdown).*/
  disabled: PropTypes.bool,
  /** Tag that it is going to be shown over the textfield.*/
  label: PropTypes.string,
  /** Current value to show in the textfield.*/
  value: PropTypes.string,
  /** Name of the component that is going to be used in order to change its style in Material-UI/.css.*/
  className: PropTypes.string,
  /** Function that is going to be called on textfield change. It must be a monad, with the new value as a parameter.
   * This function should change _value_ content.
   * */
  onChange: PropTypes.func,
  /** String representing the style of _TextField_ that is going to be shown. A list of available options can be found on
   * Material-UI -> TextField webpage.
   * */
  type: PropTypes.string,
};
