import React from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

import TextInput from "../../utils/components/TextInput";
import DropdownInput from "../../utils/components/DropdownInput";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";

import { useTranslation } from "react-i18next";

import { languages } from "../../utils/js/languages";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "1000px",
    height: "75%",
    maxHeight: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  blockedUserCheckbox: {
    textAlign: "right",
    paddingRight: "0px",
    float: "left",
    paddingLeft: "8px",
  },
  textInput: {
    width: "100%",
  },
  resetPasswordContainer: {
    textAlign: "right",
    paddingRight: "0px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  errorMsg: {
    color: "red",
  },
  sentMsg: {
    color: "green",
  },
}));

const UsersModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [username, setUsername] = React.useState("");
  const [distributor, setDistributor] = React.useState(
    Object.keys(props.distributorsWithGroups)[0]
  );
  const [group, setGroup] = React.useState(
    Object.keys(props.distributorsWithGroups[distributor].groups)[0]
  );
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [language, setLanguage] = React.useState(Object.keys(languages)[0]);
  const [role, setRole] = React.useState(t("usersView.usersModal.roles.user"));
  const [blockedUser, setBlockedUser] = React.useState(false);
  const [showNotAllFieldsFilledModal, setShowNotAllFieldsFilledModal] =
    React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);
  const [acceptButtonPressed, setAcceptButtonPressed] = React.useState(false);
  const [resetPasswordButtonPressed, setResetPasswordButtonPressed] =
    React.useState(false);
  const [showAdminGroupModal, setShowAdminGroupModal] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  const setNewState = React.useCallback(
    (user_data) => {
      setUsername(user_data.username);
      setDistributor(user_data.distributor);
      setGroup(user_data.group);
      setFirstName(user_data.first_name);
      setLastName(user_data.last_name);
      setEmail(user_data.email);
      setLanguage(user_data.language);
      if (user_data.is_admin) {
        setRole(t("usersView.usersModal.roles.administrator"));
      } else {
        setRole(t("usersView.usersModal.roles.user"));
      }
      setBlockedUser(user_data.is_blocked);
    },
    [t]
  );

  const user_data = props.userData;
  const distributors_with_groups = props.distributorsWithGroups;

  const resetStateToDefault = React.useCallback(() => {
    setUsername("");
    setDistributor(Object.keys(props.distributorsWithGroups)[0]);
    setGroup(Object.keys(props.distributorsWithGroups[distributor].groups)[0]);
    setFirstName("");
    setLastName("");
    setEmail("");
    setLanguage(Object.keys(languages)[0]);
    setRole(t("usersView.usersModal.roles.user"));
    setBlockedUser(false);
    setAcceptButtonPressed(false);
    setShowAdminGroupModal(false);
    setHasChanges(false);
  }, [t, props.distributorsWithGroups, distributor]);

  let roles = {
    [t("usersView.usersModal.roles.user")]: t(
      "usersView.usersModal.roles.user"
    ),
    [t("usersView.usersModal.roles.administrator")]: t(
      "usersView.usersModal.roles.administrator"
    ),
  };
  const are_all_fields_filled = () => {
    return (
      username.length !== 0 &&
      first_name.length !== 0 &&
      email.length !== 0      
      //last_name.length !== 0 &&
    );
  };

  const handleAcceptButton = () => {
    let is_admin =
      roles[role] === t("usersView.usersModal.roles.administrator");
    if (
      is_admin &&
      !props.distributorsWithGroups[distributor].groups[group].is_default &&
      !props.distributorsWithGroups[distributor].is_admin
    ) {
      setShowAdminGroupModal(true);
    } else if (!are_all_fields_filled()) {
      setShowNotAllFieldsFilledModal(true);
    } else {
      let user_profile = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        language: language,
        distributor: distributor,
        group: group,
        is_admin: is_admin,
        is_blocked: blockedUser,
      };
      if (user_data !== null) {
        user_profile["id"] = user_data.id;
      }
      props.createOrUpdateUserProfile(user_profile);
      setAcceptButtonPressed(true);
    }
  };

  const handleCancelButton = () => {
    if (!hasChanges) {
      setResetPasswordButtonPressed(false);
      props.clear_error_msg();
      resetStateToDefault();
      props.handleClose();
    } else {
      setShowSureToCancelModal(true);
    }
  };

  const handlePreviousModalClose = () => {
    setShowSureToCancelModal(false);
    resetStateToDefault();
    props.clear_error_msg();
    props.handleClose();
  };

  const doAdditionalOnChangeActions = () => {
    setHasChanges(true);
    setAcceptButtonPressed(false);
    setResetPasswordButtonPressed(false);
  };

  React.useEffect(() => {
    if (user_data !== null && hasChanges === false) {
      setNewState(user_data);
    }
  }, [user_data, distributors_with_groups, setNewState, hasChanges]);

  let distributors = {};
  Object.keys(props.distributorsWithGroups).forEach(
    (key) => (distributors[key] = props.distributorsWithGroups[key].name)
  );

  let groups = {};
  if (distributor != null) {
    Object.keys(props.distributorsWithGroups[distributor].groups).forEach(
      (key) => {
        if (props.distributorsWithGroups[distributor].groups[key].is_default) {
          groups[key] = "-";
        } else {
          groups[key] =
            props.distributorsWithGroups[distributor].groups[key].name;
        }
      }
    );
  }

  let error_msg = "";
  let sent_msg = "";
  if (acceptButtonPressed && !props.loadingUserCreateOrUpdate) {
    if (props.successfulReturnUserCreateOrUpdate) {
      resetStateToDefault();
      props.clear_error_msg();
      props.handleClose();
    } else {
      error_msg = t("usersView.usersModal.errorMsg." + props.errorMsg);
    }
  } else if (resetPasswordButtonPressed && props.userData === null) {
    error_msg = t("usersView.usersModal.errorMsg.emptyEmail");
  } else if (
    resetPasswordButtonPressed &&
    !props.loadingPasswordChangeRequest
  ) {
    if (props.successfulReturnPasswordChangeRequest) {
      sent_msg = t("usersView.usersModal.errorMsg.messageSentSuccessfully");
    } else {
      error_msg = t("usersView.usersModal.errorMsg." + props.error_msg);
    }
  }

  let users = props.users;
  let usersCount;
  let activeUsers = users.filter((user) => user.is_active);
  if (props.distributor_contract_plan.contract_plan !== null) {
    if (props.distributor_contract_plan.contract_plan.max_users === -1) {
      usersCount = t("usersView.unlimitedUsers");
    } else {
      usersCount =
        activeUsers.length +
        t("usersView.of") +
        (props.distributor_contract_plan.contract_plan.max_users +
          props.distributor_contract_plan.extra_users) +
        t("usersView.users");
    }
  }

  let disabledBlocked = false;
  if (props.userData !== undefined) {
    if (props.userData !== null) {
      if (props.userData.username === props.loggedUser) {
        disabledBlocked = true;
      }
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick={true}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("usersView.usersModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                className={classes.textInput}
                label={t("usersView.usersModal.username")}
                value={username}
                onChange={(value) => {
                  setUsername(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DropdownInput
                className={classes.textInput}
                label={t("usersView.usersModal.distributor")}
                options={distributors}
                value={distributor}
                onChange={(value) => {
                  setDistributor(value);
                  setGroup(
                    Object.keys(distributors_with_groups[value].groups)[0]
                  );
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DropdownInput
                className={classes.textInput}
                label={t("usersView.usersModal.group")}
                options={groups}
                value={group}
                onChange={(value) => {
                  setGroup(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                className={classes.textInput}
                label={t("usersView.usersModal.firstName")}
                value={first_name}
                onChange={(value) => {
                  setFirstName(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                className={classes.textInput}
                label={t("usersView.usersModal.lastName")}
                value={last_name}
                onChange={(value) => {
                  setLastName(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                className={classes.textInput}
                label={t("usersView.usersModal.email")}
                type="email"
                value={email}
                onChange={(value) => {
                  setEmail(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DropdownInput
                className={classes.textInput}
                label={t("usersView.usersModal.language")}
                value={language}
                options={languages}
                onChange={(value) => {
                  setLanguage(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DropdownInput
                className={classes.textInput}
                label={t("usersView.usersModal.role")}
                options={roles}
                value={role}
                onChange={(value) => {
                  setRole(value);
                  doAdditionalOnChangeActions();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <h3 style={{ marginTop: "30px" }}>{usersCount}</h3>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <FormControlLabel
                  className={classes.blockedUserCheckbox}
                  control={
                    <Checkbox
                      checked={blockedUser}
                      disabled={disabledBlocked}
                      onChange={(value) => {
                        setBlockedUser(value.target.checked);
                        doAdditionalOnChangeActions();
                      }}
                    />
                  }
                  label={t("usersView.usersModal.blockedUser")}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container className={classes.resetPasswordContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    if (props.userData !== null) {
                      props.resetPassword(props.userData.email);
                    }
                    setResetPasswordButtonPressed(true);
                  }}
                >
                  {t("usersView.usersModal.resetPassword")}
                </Button>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText
                className={clsx(
                  error_msg.length > 0 && classes.errorMsg,
                  sent_msg.length > 0 && classes.sentMsg
                )}
              >
                {error_msg + sent_msg}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("usersView.usersModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("usersView.usersModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
      <InformationModal
        open={showNotAllFieldsFilledModal}
        handleClose={() => setShowNotAllFieldsFilledModal(false)}
        title={t("notAllFieldsFilledModal.title")}
        description={t("notAllFieldsFilledModal.description")}
        buttonDescription={t("notAllFieldsFilledModal.keepEditing")}
      />
      <InformationModal
        open={showAdminGroupModal}
        handleClose={() => setShowAdminGroupModal(false)}
        title={t("adminGroupModal.title")}
        description={t("adminGroupModal.description")}
        buttonDescription={t("adminGroupModal.keepEditing")}
      />
    </div>
  );
};

export default UsersModal;
