export const ATTEMPT_TO_GET_USERS = "ATTEMPT_TO_GET_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const ERROR_GET_USERS = "ERROR_GET_USERS";
export const ERROR_UPDATE_USER_PROFILE = "ERROR_UPDATE_USER_PROFILE";
export const ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE =
  "ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE";
export const CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL =
  "CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL";
export const ERROR_CREATE_OR_UPDATE_USER_PROFILE =
  "ERROR_CREATE_OR_UPDATE_USER_PROFILE";

export const ATTEMPT_TO_GET_DISTRIBUTORS = "ATTEMPT_TO_GET_DISTRIBUTORS";
export const UPDATE_DISTRIBUTORS = "UPDATE_DISTRIBUTORS";
export const ERROR_GET_DISTRIBUTORS = "ERROR_GET_DISTRIBUTORS";

export const ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS =
  "ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS";
export const UPDATE_NON_ACTIVE_DISTRIBUTORS = "UPDATE_NON_ACTIVE_DISTRIBUTORS";
export const ERROR_GET_NON_ACTIVE_DISTRIBUTORS =
  "ERROR_GET_NON_ACTIVE_DISTRIBUTORS";
export const ERROR_UPDATE_DISTRIBUTOR_STATE = "ERROR_UPDATE_DISTRIBUTOR_STATE";

export const ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES =
  "ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES";
export const UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES =
  "UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES";
export const ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES =
  "ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES";
export const REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES =
  "REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES";
export const ERROR_CREATE_OR_UPDATE_DISTRIBUTOR =
  "ERROR_CREATE_OR_UPDATE_DISTRIBUTOR";

export const ATTEMPT_TO_GET_CONTRACT_PLANS = "ATTEMPT_TO_GET_CONTRACT_PLANS";
export const UPDATE_CONTRACT_PLANS = "UPDATE_CONTRACT_PLANS";
export const ERROR_GET_CONTRACT_PLANS = "ERROR_GET_CONTRACT_PLANS";
export const ATTEMPT_TO_CREATE_OR_UPDATE_CONTRACT_PLAN =
  "ATTEMPT_TO_CREATE_OR_UPDATE_CONTRACT_PLAN";
export const CREATE_OR_UPDATE_CONTRACT_PLAN_SUCCESSFUL =
  "CREATE_OR_UPDATE_CONTRACT_PLAN_SUCCESSFUL";
export const ERROR_CREATE_OR_UPDATE_CONTRACT_PLAN =
  "ERROR_CREATE_OR_UPDATE_CONTRAT_PLAN";
export const ERROR_UPDATE_CONTRACT_PLAN_STATE =
  "ERROR_UPDATE_CONTRACT_PLAN_STATE";

export const ATTEMPT_TO_GET_NON_ACTIVE_CONTRACT_PLANS =
  "ATTEMPT_TO_GET_NON_ACTIVE_CONTRACT_PLANS";
export const UPDATE_NON_ACTIVE_CONTRACT_PLANS =
  "UPDATE_NON_ACTIVE_CONTRACT_PLANS";
export const ERROR_GET_NON_ACTIVE_CONTRACT_PLANS =
  "ERROR_GET_NON_ACTIVE_CONTRACT_PLANS";

export const ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES =
  "ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES";
export const UPDATE_ALL_DISTRIBUTOR_MACHINES =
  "UPDATE_ALL_DISTRIBUTOR_MACHINES";
export const UPDATE_MACHINE_STATUS = "UPDATE_MACHINE_STATUS";
export const ERROR_GET_ALL_DISTRIBUTOR_MACHINES =
  "ERROR_GET_ALL_DISTRIBUTOR_MACHINES";
export const ERROR_UPDATE_MACHINES_STATE = "ERROR_UPDATE_MACHINES_STATE";

export const ATTEMPT_TO_GET_GROUPS = "ATTEMPT_TO_GET_GROUPS";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const ERROR_GET_GROUPS = "ERROR_GET_GROUPS";
export const ERROR_UPDATE_GROUP_STATE = "ERROR_UPDATE_GROUP_STATE";
export const ERROR_CREATE_OR_UPDATE_GROUP = "ERROR_CREATE_OR_UPDATE_GROUP";

export const ATTEMPT_TO_GET_ALERT_CHANGE_TYPES =
  "ATTEMPT_TO_GET_ALERT_CHANGE_TYPES";
export const UPDATE_ALERT_CHANGE_TYPES = "UPDATE_ALERT_CHANGE_TYPES";
export const ERROR_GET_ALERT_CHANGE_TYPES = "ERROR_GET_ALERT_CHANGE_TYPES";
export const ERROR_UPDATE_ALERT_CHANGE_STATE =
  "ERROR_UPDATE_ALERT_CHANGE_STATE";
export const ERROR_CREATE_OR_UPDATE_ALERT_CHANGE =
  "ERROR_CREATE_OR_UPDATE_ALERT_CHANGE";

export const ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES =
  "ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES";
export const UPDATE_MACHINE_SECTORS_TYPES = "UPDATE_MACHINE_SECTORS_TYPES";
export const ERROR_GET_MACHINE_SECTORS_TYPES =
  "ERROR_GET_MACHINE_SECTORS_TYPES";
export const ERROR_UPDATE_MACHINE_SECTORS_STATE =
  "ERROR_UPDATE_MACHINE_SECTORS_STATE";
export const ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS =
  "ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS";

export const CLEAR_ADMINISTRATION_MODALS_ERROR_MSG =
  "CLEAR_ADMINISTRATION_MODALS_ERROR_MSG";

export const ATTEMPT_TO_GET_DEVICES_EXTRA_INFO =
  "ATTEMPT_TO_GET_DEVICES_EXTRA_INFO";
export const UPDATE_DEVICES_EXTRA_INFO = "UPDATE_DEVICES_EXTRA_INFO";
export const ERROR_GET_DEVICES_EXTRA_INFO = "ERROR_GET_DEVICES_EXTRA_INFO";
export const ATTEMPT_TO_GET_HEURISTIC_TYPES = "ATTEMPT_TO_GET_HEURISTIC_TYPES";
export const UPDATE_HEURISTIC_TYPES = "UPDATE_HEURISTIC_TYPES";
export const ERROR_GET_HEURISTIC_TYPES = "ERROR_GET_HEURISTIC_TYPES";
export const ATTEMPT_TO_GET_ALERT_LEVELS = "ATTEMPT_TO_GET_ALERT_LEVELS";
export const UPDATE_ALERT_LEVELS = "UPDATE_ALERT_LEVELS";
export const ERROR_GET_ALERT_LEVELS = "ERROR_GET_ALERT_LEVELS";
export const ATTEMPT_TO_GET_TIME_UNITS = "ATTEMPT_TO_GET_TIME_UNITS";
export const UPDATE_TIME_UNITS = "UPDATE_TIME_UNITS";
export const ERROR_GET_TIME_UNITS = "ERROR_GET_TIME_UNITS";
export const ATTEMPT_TO_GET_STATE_DESCRIPTIONS =
  "ATTEMPT_TO_GET_STATE_DESCRIPTIONS";
export const UPDATE_STATE_DESCRIPTIONS = "UPDATE_STATE_DESCRIPTIONS";
export const ERROR_GET_STATE_DESCRIPTIONS = "ERROR_GET_STATE_DESCRIPTIONS";
export const ATTEMPT_TO_GET_RELATIONAL_OPERATORS =
  "ATTEMPT_TO_GET_RELATIONAL_OPERATORS";
export const UPDATE_RELATIONAL_OPERATORS = "UPDATE_RELATIONAL_OPERATORS";
export const ERROR_GET_RELATIONAL_OPERATORS = "ERROR_GET_RELATIONAL_OPERATORS";
export const ERROR_CREATE_OR_UPDATE_HEURISTIC_RULE =
  "ERROR_CREATE_OR_UPDATE_HEURISTIC_RULE";
export const ATTEMPT_TO_GET_HEURISTIC_RULES = "ATTEMPT_TO_GET_HEURISTIC_RULES";
export const ERROR_GET_HEURISTIC_RULES = "ERROR_GET_HEURISTIC_RULES";
export const UPDATE_HEURISTIC_RULES = "UPDATE_HEURISTIC_RULES";
export const ERROR_UPDATE_HEURISTIC_RULE_STATE =
  "ERROR_UPDATE_HEURISTIC_RULE_STATE";

export const INITIAL_STATE = {
  valid_distributors: false,
  loading_distributors: false,
  valid_users: false,
  loading_users: false,
  valid_groups: false,
  loading_groups: false,
  valid_contract_plans: false,
  loading_contract_plans: false,
  valid_alert_change_types: false,
  loading_alert_change_types: false,
  successful_return_user_create_or_update: false,
  loading_user_create_or_update: false,
  valid_non_active_distributors: false,
  loading_non_active_distributors: false,
  valid_non_active_contract_plans: false,
  loading_non_active_contract_plans: false,
  valid_non_active_distributor_machines: false,
  loading_non_active_distributor_machines: false,
  valid_machine_sectors_types: false,
  loading_machine_sectors_types: false,
  valid_all_distributor_machines: false,
  loading_all_distributor_machines: false,
  valid_devices_extra_info: false,
  loading_devices_extra_info: false,
  valid_heuristic_types: false,
  loading_heuristic_types: false,
  valid_alert_levels: false,
  loading_alert_levels: false,
  valid_time_units: false,
  loading_time_units: false,
  valid_state_descriptions: false,
  loading_state_descriptions: false,
  valid_relational_operators: false,
  loading_relational_operators: false,
  valid_heuristic_rules: false,
  loading_heuristic_rules: false,
  distributors: [],
  groups: [],
  contract_plans: [],
  users: [],
  alert_change_types: [],
  machine_sectors_types: [],
  new_state_options: [],
  error_msg: "",
  non_active_distributors: {},
  all_distributor_machines: [],
  non_active_distributor_machines: [],
  devices_extra_info: {},
  heuristic_types: {},
  alert_levels: {},
  time_units: {},
  state_descriptions: {},
  relational_operators: {},
  heuristic_rules: [],
};
