import React from "react";

import { useTranslation } from "react-i18next";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import PermIdentity from "@material-ui/icons/PermIdentity";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Brightness7 from "@material-ui/icons/Brightness7";

import { withStyles } from "@material-ui/core/styles";
import UserProfileModal from "../more_options_modals/UserProfileModal";
import AlertsModal from "../more_options_modals/AlertsModal";
import AdministrationModal from "../more_options_modals/AdministrationModal";
import HelpPDF from "../media/help/help.pdf";

const StyledBadge = withStyles((_) => ({
  badge: {
    top: "50%",
    right: "-50%",
  },
}))(Badge);

const show_help_pdf = () => {
  window.open(HelpPDF, "_blank");
};

const MoreOptionsMenu = (props) => {
  const { t } = useTranslation("administration_bar");

  const [profileModalOpen, profileModalSetOpen] = React.useState(false);
  const handleProfileModalOpen = () => {
    profileModalSetOpen(true);
  };
  const handleProfileModalClose = () => {
    profileModalSetOpen(false);
  };

  const [alertsModalOpen, alertsModalSetOpen] = React.useState(false);
  const handleAlertsModalOpen = () => {
    alertsModalSetOpen(true);
  };
  const handleAlertsModalClose = () => {
    alertsModalSetOpen(false);
  };

  const [administrationModalOpen, administrationModalSetOpen] =
    React.useState(false);
  const handleAdministrationModalOpen = () => {
    administrationModalSetOpen(true);
  };
  const handleAdministrationModalClose = () => {
    administrationModalSetOpen(false);
  };

  const handleOnLogoutClick = () => {
    props.on_logout_click();
  };

  let is_user_admin = false;
  let is_distributor_admin = false;
  if (
    Object.keys(props.loggedUser).length > 0 &&
    Object.keys(props.distributorsWithGroups).length > 0
  ) {
    is_user_admin = props.loggedUser.is_admin;
    is_distributor_admin =
      props.distributorsWithGroups[props.loggedUser.distributor].is_admin;
  }

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleProfileModalOpen}>
          <ListItemIcon>
            <PermIdentity />
          </ListItemIcon>
          <ListItemText primary={t("more_options_menu.Profile")} />
        </MenuItem>
        <MenuItem onClick={handleAlertsModalOpen}>
          <ListItemIcon>
            <NotificationsNone />
          </ListItemIcon>
          <StyledBadge
            badgeContent={props.numberOfOpenAlerts}
            color="secondary"
          >
            <ListItemText primary={t("more_options_menu.Alerts")} />
          </StyledBadge>
        </MenuItem>
        {(is_user_admin || is_distributor_admin) && (
          <MenuItem onClick={handleAdministrationModalOpen}>
            <ListItemIcon>
              <Brightness7 />
            </ListItemIcon>
            <ListItemText primary={t("more_options_menu.Administration")} />
          </MenuItem>
        )}
        <MenuItem onClick={show_help_pdf}>
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText primary={t("more_options_menu.Help")} />
        </MenuItem>
        <MenuItem onClick={handleOnLogoutClick}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary={t("more_options_menu.Logout")} />
        </MenuItem>
      </Menu>
      <UserProfileModal
        open={profileModalOpen}
        handleClose={handleProfileModalClose}
      />
      <AlertsModal
        open={alertsModalOpen}
        alertsHash={props.alertsHash}
        handleClose={handleAlertsModalClose}
      />
      <AdministrationModal
        open={administrationModalOpen}
        handleClose={handleAdministrationModalClose}
      />
    </div>
  );
};

export default MoreOptionsMenu;
