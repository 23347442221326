import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Restore from "@material-ui/icons/Restore";

import SearchField from "../../utils/components/SearchField";
import GroupsModal from "./GroupsModal";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

import "./ViewsTable.css";

const useStyles = makeStyles((theme) => ({
  showSetDownGroups: {
    float: "right",
  },
  newGroupButton: {
    float: "left",
    marginTop: "4px",
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
}));

const GroupsView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [selectedElement, selectedElementSetState] = React.useState(null);
  const [showNonActiveGroups, showNonActiveGroupsSetState] =
    React.useState(false);
  const [editGroupModalOpen, editGroupModalSetState] = React.useState(false);
  const [deleteModalOpen, deleteModalSetState] = React.useState(false);
  const [showExcededGroupLimitModal, setShowExcededGroupLimitModal] =
    React.useState(false);

  const get_groups = props.getGroups;
  const get_distributors = props.getDistributors;
  const auth_token = props.authToken;

  React.useEffect(() => {
    get_groups(auth_token);
  }, [get_groups, auth_token]);

  React.useEffect(() => {
    if (props.distributor_contract_plan.is_admin) {
      get_distributors(auth_token);
    }
  }, [get_distributors, props.distributor_contract_plan, auth_token]);

  try {
    let groups = props.groups;
    if (!showNonActiveGroups) {
      groups = groups.filter((dist) => dist.is_active);
    }
    groups = groups.filter((group) =>
      dict_contains_search_input(group, props.searchInput)
    );

    let distributorsWithGroups_filtered = Object.keys(
      props.distributorsWithGroups
    )
      .filter((key) => props.distributorsWithGroups[key].is_active)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: props.distributorsWithGroups[key],
        };
      }, {});

    let distributors = {};
    Object.keys(distributorsWithGroups_filtered).forEach(
      (key) => (distributors[key] = distributorsWithGroups_filtered[key].name)
    );

    let groupsCount = "";
    let activeGroupsCount = props.groups.filter(
      (dist) => dist.is_active
    ).length;
    if (props.distributor_contract_plan.contract_plan !== null) {
      if (props.distributor_contract_plan.contract_plan.max_groups === -1) {
        groupsCount = t("groupsView.unlimitedGroups");
      } else {
        groupsCount =
          activeGroupsCount +
          t("groupsView.of") +
          (props.distributor_contract_plan.contract_plan.max_groups +
            props.distributor_contract_plan.extra_groups) +
          t("groupsView.groups");
      }
    }

    return (
      <div
        style={{
          width: "80%",
          height: "625px",
          float: "right",
          padding: "20px",
        }}
      >
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <SearchField
              value={props.searchInput}
              onChange={props.searchInputSetState}
            />
          </Grid>
          <Grid item lg={3} md={5} xs={6}>
            <Button
              variant="contained"
              className={classes.newGroupButton}
              onClick={() => {
                if (props.distributor_contract_plan.contract_plan !== null) {
                  if (
                    activeGroupsCount + 1 >
                      props.distributor_contract_plan.contract_plan.max_groups +
                        props.distributor_contract_plan.extra_groups &&
                    props.distributor_contract_plan.contract_plan.max_groups !==
                      -1
                  ) {
                    setShowExcededGroupLimitModal(true);
                  } else {
                    editGroupModalSetState(true);
                  }
                } else {
                  editGroupModalSetState(true);
                }
              }}
            >
              <div style={{ textAlign: "right" }}>
                {t("groupsView.newGroupButton")}
              </div>
            </Button>
          </Grid>
          <Grid item lg={6} md={2}>
            <h3>{groupsCount}</h3>
          </Grid>
          <Grid item lg={3} md={5} xs={6}>
            {t("groupsView.showSetDownGroups")}
            <Switch
              className={classes.showSetDownGroups}
              checked={showNonActiveGroups}
              onChange={(event) =>
                showNonActiveGroupsSetState(event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              data={groups}
              columns={[
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Delete
                          className={classes.icon}
                          onClick={() => {
                            deleteModalSetState(true);
                            selectedElementSetState(row._original);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Restore
                          className={classes.icon}
                          onClick={() => {
                            if (
                              props.distributor_contract_plan.contract_plan !==
                              null
                            ) {
                              if (
                                activeGroupsCount + 1 >
                                  props.distributor_contract_plan.contract_plan
                                    .max_groups +
                                    props.distributor_contract_plan
                                      .extra_groups &&
                                props.distributor_contract_plan.contract_plan
                                  .max_groups !== -1
                              ) {
                                setShowExcededGroupLimitModal(true);
                              } else {
                                props.updateGroupState(row._original.id, true);
                              }
                            } else {
                              props.updateGroupState(row._original.id, true);
                            }
                          }}
                        />
                      );
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: null,
                  width: 50,
                  Cell: ({ row }) => {
                    if (row._original.is_active) {
                      return (
                        <Edit
                          className={classes.icon}
                          onClick={() => {
                            editGroupModalSetState(true);
                            selectedElementSetState(row._original);
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  },
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        alignItems: "center",
                      },
                    };
                  },
                },
                {
                  Header: t("groupsView.id"),
                  accessor: "id",
                },
                {
                  Header: t("groupsView.name"),
                  accessor: "name",
                },
                {
                  Header: t("groupsView.distributor"),
                  accessor: "distributor_name",
                },
              ]}
              defaultSorted={[
                {
                  id: "name",
                  desc: false,
                },
              ]}
              loading={props.loadingGroups}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="viewsTable"
              previousText={t("tableTags.previous")}
              nextText={t("tableTags.next")}
              loadingText={t("tableTags.loading")}
              noDataText={t("tableTags.noData")}
              pageText={t("tableTags.page")}
              ofText={t("tableTags.of")}
              rowsText={t("tableTags.rows")}
            />
          </Grid>
        </Grid>
        <AcceptActionModal
          open={deleteModalOpen}
          handleClose={() => {
            selectedElementSetState(null);
            deleteModalSetState(false);
          }}
          handleAcceptButton={() => {
            props.updateGroupState(selectedElement.id, false);
            deleteModalSetState(false);
            selectedElementSetState(null);
          }}
          title={t("sureToDeleteModal.title")}
          description={t("sureToDeleteModal.description")}
          acceptButtonDescription={t("sureToDeleteModal.delete")}
          cancelButtonDescription={t("sureToDeleteModal.cancel")}
        />
        <GroupsModal
          open={editGroupModalOpen}
          group={selectedElement}
          groups={props.groups}
          machines={props.machines}
          distributors={props.distributors}
          distributor_contract_plan={props.distributor_contract_plan}
          handleClose={() => {
            editGroupModalSetState(false);
            selectedElementSetState(null);
          }}
          createOrUpdateGroup={props.createOrUpdateGroup}
        />
        <InformationModal
          open={showExcededGroupLimitModal}
          handleClose={() => setShowExcededGroupLimitModal(false)}
          title={t("excededGroupLimitModal.title")}
          description={t("excededGroupLimitModal.description")}
          buttonDescription={t("excededGroupLimitModal.accept")}
        />
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default GroupsView;
