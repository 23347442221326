import React from "react";

import { connect } from "react-redux";

import LoginScreen from "./login/LoginScreen";
import Predik from "./predik/Predik";
import {
  mapStateToPropsApp,
  mapDispatchToPropsApp,
} from "./utils/redux/mappers/general_mappers";
import { withTranslation } from "react-i18next";
import Loading from "./utils/components/Loading";

class App extends React.Component {
  componentDidMount() {
    let user = localStorage.getItem("user");
    let password = localStorage.getItem("password");
    if (user !== null && password !== null) {
      this.props.onAuthInLocalStorage(user, password);
    }
  }

  render() {
    if (
      this.props.view_path.includes("reset_confirm") &&
      this.props.auth_token.length > 0
    ) {
      this.props.logout();
    }

    if (this.props.tReady === true && this.props.is_logging_in === false) {
      if (this.props.auth_token === "") {
        return <LoginScreen />;
      } else {
        return <Predik />;
      }
    } else {
      return <Loading />;
    }
  }
}

export default withTranslation()(
  connect(mapStateToPropsApp, mapDispatchToPropsApp)(App)
);
