import {
  USER_PROFILE_URL,
  DISTRIBUTOR_WITH_GROUPS_URL,
  DISTRIBUTOR_CONTRACT_PLAN_URL,
  ALERTS_HASH_URL,
  ACTIVE_ALERT_CHANGE_TYPES_URL,
  ALERTS_URL,
  NONACTIVE_ALERTS_URL,
  ALERT_TYPES_URL,
  UPDATE_ALERT_URL,
  UPDATE_PERSONAL_USER_PROFILE_URL,
} from "./common/routes.js";
import { STATUS_OK, UNAUTHORIZED } from "./common/fetch_status_codes";
import {
  UPDATE_PERSONAL_USER_PROFILE,
  UPDATE_DISTRIBUTORS_WITH_GROUPS,
  UPDATE_DISTRIBUTOR_CONTRACT_PLAN,
  UPDATE_ALERTS,
  UPDATE_NONACTIVE_ALERTS,
  UPDATE_ALERT_TYPES,
  UPDATE_ALERTS_HASH,
  UPDATE_FIRST_ALERTS_HASH,
  UPDATE_SINGLE_ALERT_DATA,
  UPDATE_ACTIVE_ALERT_CHANGE_TYPES,
  ERROR_GET_PERSONAL_USER_PROFILE,
  ERROR_GET_DISTRIBUTORS_WITH_GROUPS,
  ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN,
  ERROR_GET_ALERTS,
  ERROR_GET_NONACTIVE_ALERTS,
  ERROR_GET_ALERT_TYPES,
  ERROR_GET_ALERTS_HASH,
  ERROR_GET_FIRST_ALERTS_HASH,
  ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES,
  ERROR_UPDATE_PERSONAL_USER_PROFILE,
  ERROR_UPDATE_ALERT,
  ATTEMPT_TO_GET_PERSONAL_USER_PROFILE,
  ATTEMPT_TO_GET_ALERTS,
  ATTEMPT_TO_GET_NONACTIVE_ALERTS,
  ATTEMPT_TO_GET_FIRST_ALERTS_HASH,
  ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES,
} from "../redux/actions/general_action_types";
import i18n from "../../i18n";
import { logout } from "./login_api_calls";
import {
  ATTEMPT_TO_GET_MACHINES,
  ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS,
} from "../redux/actions/machines_action_types";
import { get_machines, get_active_machine_sectors } from "./machines_api_calls";

// ---------------------------------------------- USER PROFILE ---------------------------------------------------------
export const get_user_profile = (token, dispatch) => {
  fetch(USER_PROFILE_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_PERSONAL_USER_PROFILE,
          user_profile: data.body,
        });
        i18n.changeLanguage(data.body.language.toLowerCase());
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS });
        get_active_machine_sectors(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES });
        get_active_alert_change_types(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_PERSONAL_USER_PROFILE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_PERSONAL_USER_PROFILE,
        error_msg: "",
      });
    });
};

export const update_personal_user_profile = (token, user_profile, dispatch) => {
  fetch(UPDATE_PERSONAL_USER_PROFILE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(user_profile),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_PERSONAL_USER_PROFILE });
        get_user_profile(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_PERSONAL_USER_PROFILE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_PERSONAL_USER_PROFILE,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------- ALERTS ---------------------------------------------------------------
export const get_alerts = (token, dispatch) => {
  fetch(ALERTS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_ALERTS, alerts: data.body });
        dispatch({ type: ATTEMPT_TO_GET_FIRST_ALERTS_HASH });
        get_first_alerts_hash(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALERTS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALERTS,
        error_msg: "",
      });
    });
};

export const get_nonactive_alerts = (token, dispatch) => {
  fetch(NONACTIVE_ALERTS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_NONACTIVE_ALERTS,
          nonactive_alerts: data.body,
        });
        dispatch({ type: ATTEMPT_TO_GET_FIRST_ALERTS_HASH });
        get_first_alerts_hash(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_NONACTIVE_ALERTS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_NONACTIVE_ALERTS,
        error_msg: "",
      });
    });
};

export const get_alert_types = (token, dispatch) => {
  fetch(ALERT_TYPES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_ALERT_TYPES, alert_types: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALERT_TYPES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALERT_TYPES,
        error_msg: "",
      });
    });
};

export const get_active_alert_change_types = (token, dispatch) => {
  fetch(ACTIVE_ALERT_CHANGE_TYPES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_ACTIVE_ALERT_CHANGE_TYPES,
          active_alert_change_types: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES,
        error_msg: "",
      });
    });
};

export const update_alert = (
  token,
  alert_change,
  alerts_hash,
  non_active_alerts_count,
  dispatch
) => {
  fetch(UPDATE_ALERT_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(alert_change),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        alert_change["user_dealing_with_alert"] =
          data.body.new_user_dealing_with_alert;
        let new_alerts_hash = data.body.alerts_hash;
        if (new_alerts_hash === alerts_hash) {
          dispatch({
            type: UPDATE_SINGLE_ALERT_DATA,
            alert: alert_change,
          });
        } else {
          if (non_active_alerts_count > 0) {
            dispatch({ type: ATTEMPT_TO_GET_NONACTIVE_ALERTS });
            get_nonactive_alerts(token, dispatch);
          }
          dispatch({ type: ATTEMPT_TO_GET_ALERTS });
          get_alerts(token, dispatch);
          dispatch({ type: ATTEMPT_TO_GET_MACHINES });
          get_machines(token, dispatch);
        }
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_ALERT,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_ALERT,
        error_msg: "",
      });
    });
};

export const get_first_alerts_hash = (token, dispatch) => {
  fetch(ALERTS_HASH_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ session_key: null }),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_FIRST_ALERTS_HASH,
          first_alerts_hash: data.body.alerts_hash,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_FIRST_ALERTS_HASH,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_FIRST_ALERTS_HASH,
        error_msg: "",
      });
    });
};

export const get_alerts_hash = (token, session_key, dispatch) => {
  fetch(ALERTS_HASH_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ session_key: session_key }),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_ALERTS_HASH,
          alerts_hash: data.body.alerts_hash,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALERTS_HASH,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALERTS_HASH,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------- GENERAL --------------------------------------------------------------
export const get_distributors_with_groups = (token, dispatch) => {
  fetch(DISTRIBUTOR_WITH_GROUPS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_DISTRIBUTORS_WITH_GROUPS,
          distributors_with_groups: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_DISTRIBUTORS_WITH_GROUPS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_DISTRIBUTORS_WITH_GROUPS,
        error_msg: "",
      });
    });
};

export const get_distributor_contract_plan = (token, dispatch) => {
  fetch(DISTRIBUTOR_CONTRACT_PLAN_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_DISTRIBUTOR_CONTRACT_PLAN,
          distributor_contract_plan: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------
