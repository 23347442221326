import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";

import { convert_to_dict } from "../js/heuristic_functions";

import PropTypes from "prop-types";
import DropdownInput from "../../utils/components/DropdownInput";
import TextInput from "../../utils/components/TextInput";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
}));

/**
 *  Base component for manual heuristic operations without error type.
 *
 * @version 1.0.0
 * @author Emilio Casas (e.casas@datision.com)
 *
 */
export default function HeuristicDirect(props) {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  if (props.isReset) {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <DropdownInput
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.type")}
            sorted={false}
            value={props.reset.type}
            options={convert_to_dict(props.heuristicTypes)}
            onChange={(value) => {
              props.handleUpdateReset(props.reset.id, "type", parseInt(value));
              props.setHasChanges(true);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DropdownInput
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.device")}
            sorted={false}
            value={props.reset.device}
            options={convert_to_dict(props.devicesExtraInfo)}
            onChange={(value) => {
              props.handleUpdateReset(
                props.reset.id,
                "device",
                parseInt(value)
              );
              props.handleUpdateReset(
                props.reset.id,
                "event",
                parseInt(
                  Object.keys(
                    props.devicesExtraInfo[parseInt(value)]["events"]
                  )[0]
                )
              );
              props.handleUpdateReset(
                props.reset.id,
                "counter",
                parseInt(
                  Object.keys(
                    props.devicesExtraInfo[parseInt(value)]["counters"]
                  )[0]
                )
              );
              props.setHasChanges(true);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            sorted
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.numberOperations")}
            value={props.reset.number_operations}
            onChange={(value) => {
              let lastChar;
              if (value.length === 0) {
                props.handleUpdateReset(
                  props.reset.id,
                  "number_operations",
                  value
                );
                props.setHasChanges(true);
              } else {
                lastChar = value.substr(value.length - 1);
                if (!isNaN(parseInt(lastChar)) && parseInt(value) > 0) {
                  props.handleUpdateReset(
                    props.reset.id,
                    "number_operations",
                    value
                  );
                  props.setHasChanges(true);
                }
              }
            }}
          />
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          <DropdownInput
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.device")}
            sorted={false}
            value={props.device}
            options={convert_to_dict(props.devicesExtraInfo)}
            onChange={(value) => {
              props.setDevice(parseInt(value));
              props.setRelatedEvent(
                parseInt(
                  Object.keys(
                    props.devicesExtraInfo[parseInt(value)]["events"]
                  )[0]
                )
              );
              props.setCounter(
                parseInt(
                  Object.keys(
                    props.devicesExtraInfo[parseInt(value)]["counters"]
                  )[0]
                )
              );
              props.setHasChanges(true);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            sorted
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.numberOperations")}
            value={props.numberOperations}
            onChange={(value) => {
              let lastChar;
              if (value.length === 0) {
                props.setNumberOperations(value);
                props.setHasChanges(true);
              } else {
                lastChar = value.substr(value.length - 1);
                if (!isNaN(parseInt(lastChar)) && parseInt(value) > 0) {
                  props.setNumberOperations(value);
                  props.setHasChanges(true);
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownInput
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.level")}
            sorted={false}
            value={props.level}
            options={convert_to_dict(props.alertLevels)}
            onChange={(value) => {
              props.setLevel(parseInt(value));
              props.setHasChanges(true);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownInput
            className={classes.textInput}
            label={t("manualHeuristic.manualHeuristicModal.stateDescription")}
            sorted={false}
            value={props.stateDescription}
            options={convert_to_dict(props.stateDescriptions)}
            onChange={(value) => {
              props.setStateDescription(parseInt(value));
              props.setHasChanges(true);
            }}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

HeuristicDirect.propTypes = {
  alertLevels: PropTypes.object,
  device: PropTypes.int,
  devicesExtraInfo: PropTypes.object,
  handleUpdateReset: PropTypes.func,
  heuristicTypes: PropTypes.object,
  isReset: PropTypes.bool,
  level: PropTypes.int,
  numberOperations: PropTypes.int,
  reset: PropTypes.object,
  stateDescription: PropTypes.int,
  stateDescriptions: PropTypes.object,
  setDevice: PropTypes.func,
  setRelatedEvent: PropTypes.func,
  setCounter: PropTypes.func,
  setLevel: PropTypes.func,
  setStateDescription: PropTypes.func,
  setNumberOperations: PropTypes.func,
  setHasChanges: PropTypes.func,
};
