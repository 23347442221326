import {
  ALERT_CHANGE_TYPES_URL,
  CREATE_OR_UPDATE_DISTRIBUTOR_URL,
  CREATE_OR_UPDATE_GROUP_URL,
  CREATE_OR_UPDATE_ALERT_CHANGE_URL,
  CREATE_OR_UPDATE_USER_PROFILE_URL,
  DISTRIBUTORS_URL,
  GROUPS_URL,
  CONTRACT_PLANS_URL,
  ALL_DISTRIBUTOR_MACHINES_URL,
  UPDATE_MACHINES_STATUS_URL,
  UPDATE_CONTRACT_PLAN_STATE_URL,
  CREATE_OR_UPDATE_CONTRACT_PLAN_URL,
  UPDATE_ALERT_CHANGE_STATE_URL,
  NON_ACTIVE_DISTRIBUTOR_MACHINES_URL,
  NON_ACTIVE_DISTRIBUTORS_URL,
  UPDATE_DISTRIBUTOR_STATE_URL,
  UPDATE_GROUP_STATE_URL,
  UPDATE_USER_STATE_URL,
  USERS_URL,
  MACHINE_SECTORS_TYPES_URL,
  CREATE_OR_UPDATE_MACHINE_SECTORS_URL,
  UPDATE_MACHINE_SECTORS_STATE_URL,
  DEVICES_EXTRA_INFO_URL,
  HEURISTIC_TYPES_URL,
  ALERT_LEVELS_URL,
  TIME_UNITS_URL,
  STATE_DESCRIPTIONS_URL,
  HEURISTIC_RULES_URL,
  RELATIONAL_OPERATORLS_URL,
  CREATE_OR_UPDATE_HEURISTIC_RULE_URL,
  UPDATE_HEURISTIC_RULE_STATE_URL,
} from "./common/routes";
import { STATUS_OK, UNAUTHORIZED } from "./common/fetch_status_codes";
import {
  ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES,
  ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS,
  ATTEMPT_TO_GET_PERSONAL_USER_PROFILE,
} from "../redux/actions/general_action_types";
import {
  ATTEMPT_TO_GET_DISTRIBUTORS,
  ATTEMPT_TO_GET_GROUPS,
  ATTEMPT_TO_GET_USERS,
  ATTEMPT_TO_GET_CONTRACT_PLANS,
  ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES,
  ATTEMPT_TO_GET_ALERT_CHANGE_TYPES,
  ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES,
  ATTEMPT_TO_GET_HEURISTIC_RULES,
  ERROR_CREATE_OR_UPDATE_DISTRIBUTOR,
  ERROR_CREATE_OR_UPDATE_GROUP,
  ERROR_CREATE_OR_UPDATE_USER_PROFILE,
  ERROR_CREATE_OR_UPDATE_CONTRACT_PLAN,
  ERROR_GET_DISTRIBUTORS,
  ERROR_GET_GROUPS,
  ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  ERROR_GET_NON_ACTIVE_DISTRIBUTORS,
  ERROR_GET_USERS,
  ERROR_UPDATE_DISTRIBUTOR_STATE,
  ERROR_CREATE_OR_UPDATE_ALERT_CHANGE,
  ERROR_UPDATE_GROUP_STATE,
  ERROR_UPDATE_USER_PROFILE,
  ERROR_GET_CONTRACT_PLANS,
  ERROR_GET_ALL_DISTRIBUTOR_MACHINES,
  ERROR_GET_DEVICES_EXTRA_INFO,
  ERROR_UPDATE_MACHINES_STATE,
  ERROR_GET_ALERT_CHANGE_TYPES,
  ERROR_UPDATE_ALERT_CHANGE_STATE,
  ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS,
  ERROR_GET_MACHINE_SECTORS_TYPES,
  ERROR_UPDATE_MACHINE_SECTORS_STATE,
  ERROR_UPDATE_CONTRACT_PLAN_STATE,
  ERROR_GET_HEURISTIC_TYPES,
  ERROR_GET_ALERT_LEVELS,
  ERROR_GET_TIME_UNITS,
  ERROR_CREATE_OR_UPDATE_HEURISTIC_RULE,
  ERROR_GET_HEURISTIC_RULES,
  ERROR_UPDATE_HEURISTIC_RULE_STATE,
  ERROR_GET_STATE_DESCRIPTIONS,
  ERROR_GET_RELATIONAL_OPERATORS,
  UPDATE_MACHINE_SECTORS_TYPES,
  UPDATE_ALERT_CHANGE_TYPES,
  UPDATE_DISTRIBUTORS,
  UPDATE_GROUPS,
  UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  UPDATE_NON_ACTIVE_DISTRIBUTORS,
  UPDATE_USERS,
  UPDATE_CONTRACT_PLANS,
  UPDATE_ALL_DISTRIBUTOR_MACHINES,
  UPDATE_DEVICES_EXTRA_INFO,
  UPDATE_HEURISTIC_TYPES,
  UPDATE_ALERT_LEVELS,
  UPDATE_TIME_UNITS,
  UPDATE_HEURISTIC_RULES,
  UPDATE_STATE_DESCRIPTIONS,
  UPDATE_RELATIONAL_OPERATORS,
  CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL,
} from "../redux/actions/administration_modals_action_types";
import {
  ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS,
  ATTEMPT_TO_GET_MACHINES,
} from "../redux/actions/machines_action_types";
import { get_machines, get_active_machine_sectors } from "./machines_api_calls";
import {
  get_active_alert_change_types,
  get_distributors_with_groups,
  get_user_profile,
} from "./general_api_calls";
import { logout } from "./login_api_calls";

// ------------------------------------------- USERS -------------------------------------------------------------------
export const get_users = (token, dispatch) => {
  fetch(USERS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_USERS, users: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_USERS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_USERS,
        error_msg: "",
      });
    });
};

export const update_user_state = (token, username, is_active, dispatch) => {
  fetch(UPDATE_USER_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      username: username,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_USER_PROFILE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_USER_PROFILE,
        error_msg: "",
      });
    });
};

export const create_or_update_user_profile = (
  token,
  user_profile,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_USER_PROFILE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(user_profile),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: CREATE_OR_UPDATE_USER_PROFILE_SUCCESSFUL });
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_PERSONAL_USER_PROFILE });
        get_user_profile(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        let error_code = Object.keys(data.body)[0];
        let error_msg = "";
        if (error_code === "does not exist") {
          error_msg = "notExist";
        } else if (error_code === "username already exists") {
          error_msg = "usernameAlreadyExist";
        } else if (error_code === "email already exists") {
          error_msg = "emailAlreadyExist";
        } else if (error_code === "request with wrong format") {
          error_msg = "wrongFormatRequest";
        }
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_USER_PROFILE,
          error_msg: error_msg,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_USER_PROFILE,
        error_msg: "serverError",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ------------------------------------------- DISTRIBUTORS ------------------------------------------------------------
export const get_distributors = (token, dispatch) => {
  fetch(DISTRIBUTORS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_DISTRIBUTORS, distributors: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_DISTRIBUTORS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_DISTRIBUTORS,
        error_msg: "",
      });
    });
};

export const get_non_active_distributors = (token, dispatch) => {
  fetch(NON_ACTIVE_DISTRIBUTORS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_NON_ACTIVE_DISTRIBUTORS,
          non_active_distributors: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_NON_ACTIVE_DISTRIBUTORS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_NON_ACTIVE_DISTRIBUTORS,
        error_msg: "",
      });
    });
};

export const get_non_active_distributor_machines = (
  token,
  distributor_id,
  signal,
  dispatch
) => {
  fetch(
    NON_ACTIVE_DISTRIBUTOR_MACHINES_URL + "?distributor_id=" + distributor_id,
    {
      method: "GET",
      signal: signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  )
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
          non_active_distributor_machines: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
          non_active_distributor_machines: data.body,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
        error_msg: "",
      });
    });
};

export const create_or_update_distributor = (
  token,
  distributor_data,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_DISTRIBUTOR_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(distributor_data),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS });
        get_distributors(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS });
        get_distributors_with_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_MACHINES });
        get_machines(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_GROUPS });
        get_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_DISTRIBUTOR,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_DISTRIBUTOR,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ------------------------------------------- GROUPS ------------------------------------------------------------------
export const get_groups = (token, dispatch) => {
  fetch(GROUPS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_GROUPS, groups: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_GROUPS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_GROUPS,
        error_msg: "",
      });
    });
};

export const update_group_state = (token, group_id, is_active, dispatch) => {
  fetch(UPDATE_GROUP_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      group_id: group_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS });
        get_distributors_with_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_MACHINES });
        get_machines(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_GROUPS });
        get_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_GROUP_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_GROUP_STATE,
        error_msg: "",
      });
    });
};

export const create_or_update_group = (token, group_data, dispatch) => {
  fetch(CREATE_OR_UPDATE_GROUP_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(group_data),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS });
        get_distributors_with_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_MACHINES });
        get_machines(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_GROUPS });
        get_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_GROUP,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_GROUP,
        error_msg: "",
      });
    });
};

export const update_distributor_state = (
  token,
  distributor_id,
  is_active,
  dispatch
) => {
  fetch(UPDATE_DISTRIBUTOR_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      distributor_id: distributor_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS });
        get_distributors_with_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_MACHINES });
        get_machines(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_GROUPS });
        get_groups(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_USERS });
        get_users(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS });
        get_distributors(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_DISTRIBUTOR_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_DISTRIBUTOR_STATE,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ------------------------------------------ ALERT CHANGES ------------------------------------------------------------
export const get_alert_change_types = (token, dispatch) => {
  fetch(ALERT_CHANGE_TYPES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_ALERT_CHANGE_TYPES,
          alert_change_types: data.body.alert_state_changes,
          new_state_options: data.body.new_state_options,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALERT_CHANGE_TYPES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALERT_CHANGE_TYPES,
        error_msg: "",
      });
    });
};

export const update_alert_change_state = (
  token,
  alert_change_id,
  is_active,
  dispatch
) => {
  fetch(UPDATE_ALERT_CHANGE_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      alert_change_id: alert_change_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_ALERT_CHANGE_TYPES });
        get_alert_change_types(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES });
        get_active_alert_change_types(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_ALERT_CHANGE_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_ALERT_CHANGE_STATE,
        error_msg: "",
      });
    });
};

export const create_or_update_alert_change = (
  token,
  alert_change_data,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_ALERT_CHANGE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(alert_change_data),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_ALERT_CHANGE_TYPES });
        get_alert_change_types(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES });
        get_active_alert_change_types(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_ALERT_CHANGE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_ALERT_CHANGE,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

//------------------------------------------- MACHINES -----------------------------------------------------------------
export const get_all_distributor_machines = (token, dispatch) => {
  fetch(ALL_DISTRIBUTOR_MACHINES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_ALL_DISTRIBUTOR_MACHINES,
          all_distributor_machines: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALL_DISTRIBUTOR_MACHINES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALL_DISTRIBUTOR_MACHINES,
        error_msg: "",
      });
    });
};

export const update_machines_state = (token, machines_dict, dispatch) => {
  fetch(UPDATE_MACHINES_STATUS_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(machines_dict),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES });
        get_all_distributor_machines(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_MACHINES });
        get_machines(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_MACHINES_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_MACHINES_STATE,
        error_msg: "",
      });
    });
};
//----------------------------------------------------------------------------------------------------------------------

// ------------------------------------------ MACHINE SECTORS ----------------------------------------------------------
export const get_machine_sectors_types = (token, dispatch) => {
  fetch(MACHINE_SECTORS_TYPES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_MACHINE_SECTORS_TYPES,
          machine_sectors_types: data.body.machine_sectors_types,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_MACHINE_SECTORS_TYPES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_MACHINE_SECTORS_TYPES,
        error_msg: "",
      });
    });
};

export const update_machine_sectors_state = (
  token,
  machine_sectors_id,
  is_active,
  dispatch
) => {
  fetch(UPDATE_MACHINE_SECTORS_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      machine_sectors_id: machine_sectors_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES });
        get_machine_sectors_types(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS });
        get_active_machine_sectors(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_MACHINE_SECTORS_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_MACHINE_SECTORS_STATE,
        error_msg: "",
      });
    });
};

export const create_or_update_machine_sectors = (
  token,
  machine_sectors_data,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_MACHINE_SECTORS_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(machine_sectors_data),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES });
        get_machine_sectors_types(token, dispatch);
        dispatch({ type: ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS });
        get_active_machine_sectors(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_MACHINE_SECTORS,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ------------------------------------------ CONTRACT PLANS -----------------------------------------------------------
export const get_contract_plans = (token, dispatch) => {
  fetch(CONTRACT_PLANS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_CONTRACT_PLANS, contract_plans: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_CONTRACT_PLANS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_CONTRACT_PLANS,
        error_msg: "",
      });
    });
};

export const update_contract_plan_state = (
  token,
  contract_plan_id,
  is_active,
  dispatch
) => {
  fetch(UPDATE_CONTRACT_PLAN_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      contract_plan_id: contract_plan_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_CONTRACT_PLANS });
        get_contract_plans(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_CONTRACT_PLAN_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_CONTRACT_PLAN_STATE,
        error_msg: "",
      });
    });
};

export const create_or_update_contract_plan = (
  token,
  contract_plan_data,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_CONTRACT_PLAN_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(contract_plan_data),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_CONTRACT_PLANS });
        get_contract_plans(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_CONTRACT_PLAN,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_CONTRACT_PLAN,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------

// ------------------------------------------ MANUAL HEURISTIC ---------------------------------------------------------
export const get_devices_extra_info = (token, dispatch) => {
  fetch(DEVICES_EXTRA_INFO_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_DEVICES_EXTRA_INFO,
          devices_extra_info: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_DEVICES_EXTRA_INFO,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_DEVICES_EXTRA_INFO,
        error_msg: "",
      });
    });
};

export const get_heuristic_types = (token, dispatch) => {
  fetch(HEURISTIC_TYPES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_HEURISTIC_TYPES, heuristic_types: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_HEURISTIC_TYPES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_HEURISTIC_TYPES,
        error_msg: "",
      });
    });
};

export const get_alert_levels = (token, dispatch) => {
  fetch(ALERT_LEVELS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_ALERT_LEVELS, alert_levels: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_ALERT_LEVELS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_ALERT_LEVELS,
        error_msg: "",
      });
    });
};

export const get_time_units = (token, dispatch) => {
  fetch(TIME_UNITS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_TIME_UNITS, time_units: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_TIME_UNITS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_TIME_UNITS,
        error_msg: "",
      });
    });
};

export const get_state_descriptions = (token, dispatch) => {
  fetch(STATE_DESCRIPTIONS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_STATE_DESCRIPTIONS,
          state_descriptions: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_STATE_DESCRIPTIONS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_STATE_DESCRIPTIONS,
        error_msg: "",
      });
    });
};

export const get_relational_operators = (token, dispatch) => {
  fetch(RELATIONAL_OPERATORLS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_RELATIONAL_OPERATORS,
          relational_operators: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_RELATIONAL_OPERATORS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_RELATIONAL_OPERATORS,
        error_msg: "",
      });
    });
};

export const get_heuristic_rules = (token, dispatch) => {
  fetch(HEURISTIC_RULES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: UPDATE_HEURISTIC_RULES, heuristic_rules: data.body });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_GET_HEURISTIC_RULES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_GET_HEURISTIC_RULES,
        error_msg: "",
      });
    });
};

export const create_or_update_heuristic_rule = (
  token,
  heuristic_rule_data,
  dispatch
) => {
  fetch(CREATE_OR_UPDATE_HEURISTIC_RULE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(heuristic_rule_data),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_HEURISTIC_RULES });
        get_heuristic_rules(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_CREATE_OR_UPDATE_HEURISTIC_RULE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OR_UPDATE_HEURISTIC_RULE,
        error_msg: "",
      });
    });
};

export const update_heuristic_rule_state = (
  token,
  heuristic_rule_id,
  is_active,
  dispatch
) => {
  fetch(UPDATE_HEURISTIC_RULE_STATE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      heuristic_rule_id: heuristic_rule_id,
      is_active: is_active,
    }),
  })
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: ATTEMPT_TO_GET_HEURISTIC_RULES });
        get_heuristic_rules(token, dispatch);
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_HEURISTIC_RULE_STATE,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_HEURISTIC_RULE_STATE,
        error_msg: "",
      });
    });
};
// ---------------------------------------------------------------------------------------------------------------------
