import React from "react";

import clsx from "clsx";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import { ReactComponent as BigRiskIconLow } from "../media/svg/big_riskicon_low.svg";
import { ReactComponent as BigRiskIconMedium } from "../media/svg/big_riskicon_medium.svg";
import { ReactComponent as BigRiskIconHigh } from "../media/svg/big_riskicon_high.svg";

import { useTranslation } from "react-i18next";
import { sort_importance_features } from "../utils/js/common_functions";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
    },
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    icon: {
      height: 125,
      width: 125,
      [theme.breakpoints.down("lg")]: {
        height: 110,
        width: 110,
      },
      [theme.breakpoints.down("md")]: {
        height: 125,
        width: 125,
      },
      [theme.breakpoints.down("sm")]: {
        height: 110,
        width: 110,
      },
      [theme.breakpoints.down("xs")]: {
        height: 110,
        width: 110,
      },
    },
    iconLow: {
      color: "green",
    },
    iconMedium: {
      color: "orange",
    },
    iconHigh: {
      color: "red",
    },
    header: {
      textAlign: "right",
      paddingRight: "6px",
      fontWeight: "bold",
    },
    content: {
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 16,
    },
    headerContentTextSize: {
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  })
);

const ComponentRiskDisplayer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("predictive");

  const get_top_three_features = () => {
    if (props.component.importance_features !== null) {
      let top_three = props.component.importance_features
        .sort(sort_importance_features)
        .slice(0, 3);
      const devices_extra_info = props.devicesExtraInfo;
      if (Object.keys(devices_extra_info).length > 0) {
        top_three.forEach((item, i) => {
          let number = null;
          let value = Math.abs(item.Importance.toFixed(2));
          try {
            if (item.Feature.includes("countEvent")) {
              number = parseInt(item.Feature.replace("countEvent", ""));
              top_three[i].name =
                devices_extra_info[props.component.tipodispositiu].events[
                  number
                ].description;
            } else if (item.Feature.includes("DiffCounter")) {
              number = parseInt(item.Feature.replace("DiffCounter", ""));
              top_three[i].name =
                devices_extra_info[props.component.tipodispositiu].counters[
                  number
                ].description;
            } else if (item.Feature.includes("counter")) {
              number = parseInt(item.Feature.replace("counter", ""));
              top_three[i].name =
                devices_extra_info[props.component.tipodispositiu].counters[
                  number
                ].description;
            }
            top_three[i].name =
              top_three[i].name + " - ID " + number + " (" + value + "%)";
          } catch (e) {
            top_three[i].name = "Unknown - ID " + number + " (" + value + "%)";
          }
        });
      }
      return (
        <div>
          {top_three[0].name}
          <br />
          {top_three[1].name}
          <br />
          {top_three[2].name}
        </div>
      );
    }
  };

  const generate_risk_displayer = () => {
    if (props.component != null) {
      let risk = props.component.risk;
      let risk_icon;
      switch (risk) {
        case 1:
          risk_icon = (
            <BigRiskIconLow className={classes.icon} viewBox="0 0 125 125" />
          );
          break;

        case 2:
          risk_icon = (
            <BigRiskIconMedium className={classes.icon} viewBox="0 0 125 125" />
          );
          break;

        case 3:
          risk_icon = (
            <BigRiskIconHigh className={classes.icon} viewBox="0 0 125 125" />
          );
          break;

        default:
          risk_icon = (
            <BigRiskIconLow className={classes.icon} viewBox="0 0 125 125" />
          );
      }
      return (
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={3} md={4}>
            {risk_icon}
          </Grid>
          <Grid item xs={9} md={8}>
            <Grid
              container
              spacing={1}
              alignItems="initial"
              justify="flex-start"
            >
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.header)}
                xs={3}
              >
                {t("machineRiskDisplayer.risk")}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.content)}
                xs={9}
              >
                {props.component.risk_description}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.header)}
                xs={3}
              >
                {t("machineRiskDisplayer.causes")}
              </Grid>
              <Grid
                item
                className={clsx(classes.headerContentTextSize, classes.content)}
                xs={9}
              >
                {get_top_three_features()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else return null;
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.componentTitle}>
        {t("machineRiskDisplayer.title")}
      </Box>
      <CardContent className={classes.cardContent}>
        {generate_risk_displayer()}
      </CardContent>
    </Paper>
  );
};

export default ComponentRiskDisplayer;
