import {
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_CONFIRM_URL,
  RESET_PASSWORD_URL,
} from "./common/routes.js";
import { STATUS_OK } from "./common/fetch_status_codes";
import {
  LOGIN_SUCCESSFUL,
  ERROR_LOGIN,
  RESET_PASSWORD_SUCCESSFUL,
  ERROR_RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM_SUCCESSFUL,
  ERROR_RESET_PASSWORD_CONFIRM,
} from "../redux/actions/login_action_types";
import { RESET_APP } from "../redux/actions/root_action_types";

export const login = (user, password, remember_me, dispatch, t) => {
  fetch(LOGIN_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: user,
      password: password,
    }),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: LOGIN_SUCCESSFUL, token: data.body.key });
        if (remember_me) {
          localStorage.setItem("user", user);
          localStorage.setItem("password", password);
        }
      } else {
        try {
          if (
            data.body[Object.keys(data.body)[0]][0] ===
            "User account is blocked"
          ) {
            dispatch({ type: ERROR_LOGIN, error_msg: t("blockedUser") });
          } else {
            dispatch({ type: ERROR_LOGIN, error_msg: t("Wrong credentials") });
          }
        } catch (e) {
          dispatch({ type: ERROR_LOGIN, error_msg: t("Wrong credentials") });
        }
      }
    })
    .catch(function () {
      dispatch({
        type: ERROR_LOGIN,
        error_msg: t("Connection problem server"),
      });
    });
};

export const logout = (dispatch) => {
  fetch(LOGOUT_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  }).then(() => {
    let user = localStorage.getItem("user");
    let password = localStorage.getItem("password");
    if (user !== null && password !== null) {
      localStorage.clear();
    }
    dispatch({ type: RESET_APP });
  });
};

export const reset_password = (email, dispatch) => {
  fetch(RESET_PASSWORD_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: RESET_PASSWORD_SUCCESSFUL });
      } else {
        dispatch({ type: ERROR_RESET_PASSWORD, error_msg: "wrongEmail" });
      }
    })
    .catch(function () {
      dispatch({ type: ERROR_RESET_PASSWORD, error_msg: "serverError" });
    });
};

export const reset_password_confirm = (
  uid,
  token,
  password1,
  password2,
  dispatch
) => {
  fetch(RESET_PASSWORD_CONFIRM_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      new_password1: password1,
      new_password2: password2,
    }),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({ type: RESET_PASSWORD_CONFIRM_SUCCESSFUL });
      } else {
        dispatch({
          type: ERROR_RESET_PASSWORD_CONFIRM,
          error_msg: "wrongPassword",
        });
      }
    })
    .catch(function () {
      dispatch({
        type: ERROR_RESET_PASSWORD_CONFIRM,
        error_msg: "serverError",
      });
    });
};
