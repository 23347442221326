import React from "react";

import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import DropdownInput from "../../utils/components/DropdownInput";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "90%",
    height: "90%",
    maxWidth: "450px",
    maxHeight: "380px",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  closeButton: {
    padding: "8px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  alerts: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
  modalContent: {
    padding: "0px 32px 20px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 50px)",
    overflowY: "auto",
  },
  buttonsContainer: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  textInput: {
    width: "90%",
  },
}));

const AlertStateChangeModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  if (
    props.dropdownValue === null &&
    Object.keys(props.alertChangeTypes).length !== 0
  ) {
    props.dropdownValueSetState(Object.keys(props.alertChangeTypes)[0]);
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.alerts}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="center"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("alertStateChangeModal.title")}
              </h2>
            </Grid>
            <Grid item xs={12}>
              {t("alertStateChangeModal.description")}
            </Grid>
            {
              <Grid item xs={12}>
                <DropdownInput
                  className={classes.textInput}
                  label={t("alertStateChangeModal.changeOptions")}
                  value={props.dropdownValue}
                  options={props.alertChangeTypes}
                  onChange={props.dropdownValueSetState}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <Container className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={props.handleAccept}
                >
                  {t("alertStateChangeModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    props.dropdownValueSetState(null);
                    props.handleClose();
                  }}
                >
                  {t("alertStateChangeModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default AlertStateChangeModal;
