import React from "react";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Email from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next";

import {
  mapStateToPropsResetPasswordForm,
  mapDispatchToPropsResetPasswordForm,
} from "../utils/redux/mappers/login_mappers";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  emailInput: {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
    marginLeft: "0.5em",
    color: "#000",
  },
  button: {
    margin: theme.spacing(1),
  },
  enterEmailText: {
    marginTop: "5em",
  },
  buttonsContainer: {
    marginTop: "3em",
    marginBottom: "2em",
  },
  loginErrorMsg: {
    margin: "0.5em",
    color: "red",
    textAlign: "center",
    fontSize: "1em",
  },
}));

const is_valid_email = (email) => {
  return RegExp(".+@.+\\..+").test(email) || email.length === 0;
};

const is_empty_email = (email) => {
  return email.length === 0;
};

const ResetPasswordForm = (props) => {
  const [email, setEmail] = React.useState("");
  const [acceptButtonPressed, setAcceptButtonPressed] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation("login");

  let error_msg = "";
  if (!is_valid_email(email)) {
    error_msg = t("resetPasswordForm.noValidEmail");
  }

  if (acceptButtonPressed && email.length === 0) {
    error_msg = t("resetPasswordForm.emptyEmail");
  } else if (acceptButtonPressed && !props.loading_password_change_request) {
    if (props.successful_return_password_change_request) {
      props.clear_error_msg();
      props.push("/reset_successful");
    } else {
      error_msg = t("resetPasswordForm." + props.error_msg);
    }
  }
  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item className={classes.enterEmailText}>
          {t("resetPasswordForm.enterEmailText")}
        </Grid>
        <Grid container spacing={1} alignItems="flex-end" justify="center">
          <Grid item>
            <Email />
          </Grid>
          <Grid item>
            <TextField
              error={error_msg.length !== 0}
              className={classes.emailInput}
              type="email"
              onChange={(value) => {
                setAcceptButtonPressed(false);
                setEmail(value.target.value);
              }}
              label={t("resetPasswordForm.enterEmailLabel")}
              value={email}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormHelperText className={classes.loginErrorMsg}>
            {error_msg}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} className={classes.buttonsContainer}>
          <Container style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setAcceptButtonPressed(true);
                if (!is_empty_email(email)) {
                  props.reset_password(email);
                }
              }}
            >
              {t("resetPasswordForm.accept")}
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                props.clear_error_msg();
                props.push("/");
              }}
            >
              {t("resetPasswordForm.cancel")}
            </Button>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(
  mapStateToPropsResetPasswordForm,
  mapDispatchToPropsResetPasswordForm
)(ResetPasswordForm);
