import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import MachinesTable from "./MachinesTable";
import SearchField from "../utils/components/SearchField";
import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  advancedSearchBox: {
    textAlign: "right",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  advancedSearch: {
    fontWeight: "bold",
    color: "blue",
  },
  searchInput: {
    paddingBottom: "16px",
  },
}));

/**
 *  Meta container based on Material-UI Grid that serve as structure of _SearchField_ and _MachinesTable_ in
 *  **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function MachinesSearcher(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-start" justify="center">
      <Grid item xs={12} className={classes.searchInput}>
        <SearchField
          value={props.searchInput}
          onChange={props.searchInputSetState}
        />
      </Grid>

      <Grid item xs={12}>
        <MachinesTable
          machines={props.machines}
          selected={props.selected}
          updateSelectedMachine={props.updateSelectedMachine}
          searchInput={props.searchInput}
          updateSearchInput={props.updateSearchInput}
          selectedSetState={props.selectedSetState}
          distributorsWithGroups={props.distributorsWithGroups}
          updateMachine={props.updateMachine}
          updateMachinesSet={props.updateMachinesSet}
          changeWindowOnCkserialClick={props.changeWindowOnCkserialClick}
          userHasDeselected={props.userHasDeselected}
          userHasDeselectedSetState={props.userHasDeselectedSetState}
          machineSectors={props.machineSectors}
          loadingMachines={props.loadingMachines}
          isDistributorAdmin={props.isDistributorAdmin}
          distributor_contract_plan={props.distributor_contract_plan}
        />
      </Grid>
    </Grid>
  );
}

MachinesSearcher.propTypes = {
  /** String that contains the search input introduced by the user.*/
  searchInput: PropTypes.string,
  /** Function used to change _searchInput_ value. */
  searchInputSetState: PropTypes.func,
  /** List of dicts containing all the relative information of each machine of the application. */
  machines: PropTypes.array,
  /** Index of the row selected in the _MachinesTable_. If no machine is selected, this index must be -1*/
  selected: PropTypes.number,
  /** Function used to change _selected_ value. */
  selectedSetState: PropTypes.func,
  /** Boolean that indicates if the distributor is admin or not. */
  isDistributorAdmin: PropTypes.bool,
  /** Array of dicts containing the different distributors and groups of the application. The structure must be
   * {"distid":{"name": "asd", "groups": {"groupid":{"name": "asd2", "is_default": False}, ...}, ...}*/
  distributorsWithGroups: PropTypes.object,
  /** Function that make an API call to the backend in order to update machine data*/
  updateMachine: PropTypes.func,
  /** Boolean that indicates if the user has deselected manually the selected machine on the table*/
  userHasDeselected: PropTypes.bool,
  /** Function that must change _userHasSelected_ value.*/
  userHasDeselectedSetState: PropTypes.func,
  /** Boolean that indicates if _machines_ are still loading.*/
  loadingMachines: PropTypes.bool,
};
