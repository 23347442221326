import {
  ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS,
  ATTEMPT_TO_GET_DISTRIBUTOR_CONTRACT_PLAN,
  ATTEMPT_TO_GET_PERSONAL_USER_PROFILE,
  ATTEMPT_TO_GET_ALERTS,
  ATTEMPT_TO_GET_ALERT_TYPES,
  ATTEMPT_TO_GET_ALERTS_HASH,
  ATTEMPT_TO_GET_FIRST_ALERTS_HASH,
  ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES,
  UPDATE_SELECTED_DISTRIBUTOR,
  UPDATE_SELECTED_GROUP,
} from "../actions/general_action_types";
import {
  ATTEMPT_TO_GET_MACHINES,
  ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS,
  ATTEMPT_TO_GET_MODEL_HISTORY,
} from "../actions/machines_action_types";
import {
  ATTEMPT_TO_GET_DEVICES_EXTRA_INFO,
  ATTEMPT_TO_GET_ALERT_LEVELS,
} from "../actions/administration_modals_action_types";
import {
  get_user_profile,
  get_distributors_with_groups,
  get_distributor_contract_plan,
  get_alerts,
  get_alert_types,
  get_alerts_hash,
  get_first_alerts_hash,
  get_active_alert_change_types,
} from "../../api_calls/general_api_calls";
import {
  get_machines,
  get_active_machine_sectors,
  get_model_history,
} from "../../api_calls/machines_api_calls";
import { logout } from "../../api_calls/login_api_calls";
import {
  get_devices_extra_info,
  get_alert_levels,
} from "../../api_calls/administration_modals_api_calls";

export const mapStateToPropsPredik = (state, ownProps) => {
  let user_distributor = null;
  if (Object.keys(state.general.user_profile).length > 0) {
    user_distributor = state.general.user_profile.distributor.toString();
  }
  return {
    view_path: state.router.location.pathname,
    auth_token: state.login.auth_token,
    distributors_with_groups: state.general.distributors_with_groups,
    distributor_contract_plan: state.general.distributor_contract_plan,
    user_profile: state.general.user_profile,
    alerts: state.general.alerts,
    alerts_page: state.general.alerts_page,
    alerts_hash: state.general.alerts_hash,
    first_alerts_hash: state.general.first_alerts_hash,
    selected_distributor: state.general.selected_distributor,
    selected_group: state.general.selected_group,
    alert_levels: state.administration_modals.alert_levels,
    logged_user_distributor: user_distributor,
    loading_fetch_call:
      state.machines.loading_machines ||
      state.general.loading_user_profile ||
      state.general.loading_distributors_with_groups ||
      state.general.loading_alerts,
  };
};

export const mapDispatchToPropsPredik = (dispatch) => {
  return {
    get_user_profile: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_PERSONAL_USER_PROFILE });
      get_user_profile(auth_token, dispatch);
    },
    get_distributors_with_groups: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS });
      get_distributors_with_groups(auth_token, dispatch);
    },
    get_distributor_contract_plan: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTOR_CONTRACT_PLAN });
      get_distributor_contract_plan(auth_token, dispatch);
    },
    get_alerts: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERTS });
      get_alerts(auth_token, dispatch);
      dispatch({ type: ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES });
      get_active_alert_change_types(auth_token, dispatch);
    },
    get_alert_types: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERT_TYPES });
      get_alert_types(auth_token, dispatch);
    },
    get_alerts_hash: (auth_token, session_key) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERTS_HASH });
      get_alerts_hash(auth_token, session_key, dispatch);
    },
    get_first_alerts_hash: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_FIRST_ALERTS_HASH });
      get_first_alerts_hash(auth_token, dispatch);
    },
    get_machines: (auth_token) => {
      dispatch({
        type: ATTEMPT_TO_GET_MACHINES,
        token: auth_token,
      });
      get_machines(auth_token, dispatch);
    },
    get_model_history: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_MODEL_HISTORY });
      get_model_history(auth_token, dispatch);
    },
    get_active_machine_sectors: (auth_token) => {
      dispatch({
        type: ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS,
        token: auth_token,
      });
      get_active_machine_sectors(auth_token, dispatch);
    },
    get_alert_levels: (auth_token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERT_LEVELS });
      get_alert_levels(auth_token, dispatch);
    },
    get_devices_extra_info: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_DEVICES_EXTRA_INFO });
      get_devices_extra_info(token, dispatch);
    },
    on_select_distributor_change: (selected_distributor) => {
      dispatch({
        type: UPDATE_SELECTED_DISTRIBUTOR,
        selected_distributor: selected_distributor,
      });
    },
    on_select_group_change: (selected_group) => {
      dispatch({
        type: UPDATE_SELECTED_GROUP,
        selected_group: selected_group,
      });
    },
    on_logout_click: () => {
      logout(dispatch);
    },
  };
};
