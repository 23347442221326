import React from "react";
import Grid from "@material-ui/core/Grid";
import Lock from "@material-ui/icons/Lock";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  mapDispatchToPropsResetPasswordConfirm,
  mapStateToPropsResetPasswordConfirm,
} from "../utils/redux/mappers/login_mappers";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  passwordInput: {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
    marginLeft: "0.5em",
    color: "#000",
  },
  containerButton: {
    margin: "2em 0",
  },
  passwordErrorMsg: {
    margin: "0.5em",
    color: "red",
    textAlign: "center",
    fontSize: "1em",
  },
  enterPasswordText: {
    marginTop: "3em",
  },
}));

const is_valid_password = (password) => {
  const passwordRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
  );
  return passwordRegex.test(password);
};

const are_passwords_equal = (password1, password2) => {
  return password1.valueOf() === password2.valueOf();
};

const ResetPasswordConfirm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("login");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [acceptButtonPressed, setAcceptButtonPressed] = React.useState(false);

  let error_msg = "";
  let error_1 = false;
  let error_2 = false;
  if (!is_valid_password(password1) && password1.length !== 0) {
    error_msg = t("resetPasswordConfirm.notValidPassword");
    error_1 = true;
  } else if (!are_passwords_equal(password1, password2)) {
    error_msg = t("resetPasswordConfirm.passwordsNotEqual");
    error_2 = true;
  }
  if (
    acceptButtonPressed &&
    (password1.length === 0 || password2.length === 0)
  ) {
    error_msg = t("resetPasswordConfirm.passwordsNotEmpty");
    error_1 = password1.length === 0;
    error_2 = password2.length === 0;
  } else if (
    acceptButtonPressed &&
    !props.loading_password_change_confirm_request
  ) {
    if (props.successful_return_password_change_confirm_request) {
      props.clear_error_msg();
      props.push("/reset_confirm_successful");
    } else {
      error_1 = error_2 = true;
      if (props.error_msg.length === 0) {
        error_msg = t("resetPasswordConfirm.serverError");
      } else {
        error_msg = t("resetPasswordConfirm." + props.error_msg);
      }
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item className={classes.enterPasswordText}>
          {t("resetPasswordConfirm.enterPasswordText")}
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          justify="center"
          className={classes.containerPassword1}
        >
          <Grid item>
            <Lock />
          </Grid>
          <Grid item>
            <TextField
              error={error_1}
              className={classes.passwordInput}
              type="password"
              onChange={(event) => {
                setAcceptButtonPressed(false);
                setPassword1(event.target.value);
              }}
              label={t("resetPasswordConfirm.password1")}
              value={password1}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end" justify="center">
          <Grid item>
            <Lock />
          </Grid>
          <Grid item>
            <TextField
              error={error_2}
              className={classes.passwordInput}
              type="password"
              onChange={(event) => {
                setAcceptButtonPressed(false);
                setPassword2(event.target.value);
              }}
              label={t("resetPasswordConfirm.password2")}
              value={password2}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormHelperText className={classes.passwordErrorMsg}>
            {error_msg}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} className={classes.containerButton}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              setAcceptButtonPressed(true);
              if (
                is_valid_password(password1) &&
                are_passwords_equal(password1, password2)
              ) {
                props.reset_password_confirm(
                  props.match.params.uidb64,
                  props.match.params.token,
                  password1,
                  password2
                );
              }
            }}
          >
            {t("resetPasswordConfirm.changePassword")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(
  mapStateToPropsResetPasswordConfirm,
  mapDispatchToPropsResetPasswordConfirm
)(ResetPasswordConfirm);
