export const mapStateToPropsPredictive = (state, ownProps) => {
  let user_distributor = null;
  if (Object.keys(state.general.user_profile).length > 0) {
    user_distributor = state.general.user_profile.distributor.toString();
  }
  return {
    selected_distributor: state.general.selected_distributor,
    selected_group: state.general.selected_group,
    distributors_with_groups: state.general.distributors_with_groups,
    distributor_contract_plan: state.general.distributor_contract_plan,
    model_history: state.machines.model_history,
    devices_extra_info: state.administration_modals.devices_extra_info,
    logged_user_distributor: user_distributor,
  };
};
