// React imports
import React from "react";

// Third-Party imports
import { makeStyles } from "@material-ui/core/styles";
import { Route } from "react-router";
import { Redirect } from "react-router";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// Local imports
import Dashboard from "../dashboard/Dashboard";
import BlankView from "./BlankView";
import MachinesList from "../machines_list/MachinesList";
import Scoring from "../scoring/Scoring";
import Predictive from "../predictive/Predictive";
import { mapStateToPropsContentWindow } from "../utils/redux/mappers/general_mappers";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

const generate_chart_data = (chart_data) => {
  let output = [];
  Object.keys(chart_data).forEach((key) =>
    output.push({
      name: key,
      riskLevel: chart_data[key],
    })
  );
  return output;
};

const ContentWindow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [selectedMachineIndex, selectedMachineIndexSetState] = React.useState(
    props.selected_machine
  );
  const [searchInput, searchInputSetState] = React.useState(props.search_input);

  React.useEffect(() => {
    selectedMachineIndexSetState(props.selected_machine);
  }, [props.selected_distributor, props.selected_machine]);

  React.useEffect(() => {
    searchInputSetState(props.search_input);
  }, [props.search_input]);

  //This code block acts as filter. The intention of the block is filter the machines that doesnt satisfy the user search
  //input, and in addition it add extra fields to machine dicts (just for make easy to users to visualize some of the fields)
  let machines = [];
  let chart_data = [];
  let number_of_open_alerts = 0;
  let medium_and_high_state_machines = [];
  let number_of_machines = 0;
  let top_5_alerts = [];
  let alert_levels = props.alertLevels;
  let contract_plan = props.distributor_contract_plan.contract_plan;
  if (props.selected_distributor !== "-1" && props.selected_group !== "-1") {
    let selected_distributor = Object.assign(
      {},
      props.distributors_with_groups[props.selected_distributor],
      { id: props.selected_distributor }
    );
    let selected_group = Object.assign(
      {},
      selected_distributor.groups[props.selected_group],
      { id: props.selected_group }
    );

    machines = props.machines_set.get_filtered_machines(
      selected_distributor,
      selected_group,
      t
    );
    if (props.viewPath === "Dashboard") {
      chart_data = generate_chart_data(
        props.machines_set.get_filtered_number_of_machines_for_each_state_level(
          selected_distributor,
          selected_group,
          alert_levels
        )
      );
      number_of_open_alerts =
        props.alerts_set.get_number_of_machines_with_alerts(
          selected_distributor,
          selected_group
        );
      medium_and_high_state_machines =
        props.machines_set.get_over_green_state_machines(
          selected_distributor,
          selected_group
        );
      number_of_machines = machines.length;
      top_5_alerts = props.alerts_set.get_top_5_alerts(
        selected_distributor,
        selected_group
      );
    }
  }

  let selected_machine = null;
  if (selectedMachineIndex !== -1) {
    selected_machine = machines[selectedMachineIndex];
  } else {
    selected_machine = null;
  }

  const change_window_on_ckserial_click = (new_ckserial, new_view) => {
    let i = 0;
    let found = false;
    while (i < machines.length && !found) {
      if (machines[i].ckserial === new_ckserial) {
        found = true;
      } else {
        ++i;
      }
    }
    if (!found) {
      selectedMachineIndexSetState(-1);
    } else {
      selectedMachineIndexSetState(i);
    }
    props.push(new_view);
  };

  let dashboard = (
    <Route
      exact
      path="/"
      component={() => (
        <Dashboard
          changeWindowOnCkserialClick={(new_ckserial) =>
            change_window_on_ckserial_click(new_ckserial, "/machine_list")
          }
          chartData={chart_data}
          numberOfOpenAlerts={number_of_open_alerts}
          mediumAndHighStateMachines={medium_and_high_state_machines}
          numberOfMachines={number_of_machines}
          top5Alerts={top_5_alerts}
        />
      )}
    />
  );
  let machine_list = (
    <Route
      path="/machine_list"
      component={() => (
        <MachinesList
          changeWindowOnCkserialClick={(new_ckserial) =>
            change_window_on_ckserial_click(new_ckserial, "/scoring")
          }
          machines={machines}
          defaultSelectedMachineIndex={selectedMachineIndex}
          searchInput={searchInput}
        />
      )}
    />
  );
  let scoring = (
    <Route
      path="/scoring"
      component={() => (
        <Scoring
          machines={machines}
          defaultSelectedMachine={selected_machine}
        />
      )}
    />
  );
  let predictive = (
    <Route
      path="/predictive"
      component={() => (
        <Predictive
          machines={machines}
          defaultSelectedMachine={selected_machine}
        />
      )}
    />
  );

  if (contract_plan != null) {
    if (!contract_plan.module_dashboard) {
      dashboard = <Route exact path="/" component={() => <BlankView />} />;
    }
    if (!contract_plan.module_machines_list) {
      machine_list = (
        <Route exact path="/machine_list" component={() => <BlankView />} />
      );
    }
    if (!contract_plan.module_scoring) {
      scoring = <Route exact path="/scoring" component={() => <BlankView />} />;
    }
    if (!contract_plan.module_predictive) {
      predictive = (
        <Route exact path="/predictive" component={() => <BlankView />} />
      );
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {dashboard}
      {machine_list}
      {scoring}
      {predictive}
      <Redirect from="/*" to="/" />
    </main>
  );
};

export default connect(mapStateToPropsContentWindow, { push })(ContentWindow);
