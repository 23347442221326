import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./ViewsTable.css";

import SearchField from "../../utils/components/SearchField";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import DropdownInput from "../../utils/components/DropdownInput";
import InformationModal from "../../utils/components/InformationModal";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "1040px",
    height: "90%",
    maxHeight: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  linkMachinesModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectAll: {
    float: "left",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  textInput: {
    width: "100%",
    marginLeft: 0,
  },
  alignRight: {
    textAlign: "right",
  },
}));

const generateNewDictOfSelectedMachines = (machines, state) => {
  let machines_dict = {};
  machines.forEach((item, i) => {
    machines_dict[item["id"]] = state;
  });
  return machines_dict;
};

const get_linked_machines_array = (machines) => {
  let output = [];
  Object.keys(machines).forEach((key) => {
    if (machines[key]) {
      output.push(key);
    }
  });
  return output;
};

const LinkMachinesModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  let contract_plans_dict = {};
  let contract_plans = props.contractPlans;
  let default_contract_plan;
  if (contract_plans !== null && contract_plans !== undefined) {
    contract_plans.forEach((item, i) => {
      if (item["is_active"]) {
        contract_plans_dict[item["id"]] = item["name"];
        if (item.is_default) {
          default_contract_plan = item.id;
        }
      }
    });
  }

  const [allMachinesSelected, setAllMachinesSelected] = React.useState(false);
  const [selectedMachines, setSelectedMachines] = React.useState({});
  const [searchInput, setSearchInput] = React.useState("");
  const [showChangePlanModal, setShowChangePlanModal] = React.useState(false);
  const [contractPlan, setContractPlan] = React.useState(default_contract_plan);
  const [showExcededMachineLimitModal, setShowExcededMachineLimitModal] =
    React.useState(false);

  React.useEffect(() => {
    if (props.activateAllMachines != null) {
      setAllMachinesSelected(props.activateAllMachines);
      setSelectedMachines(
        generateNewDictOfSelectedMachines(
          props.distributorMachines,
          props.activateAllMachines
        )
      );
    }
    if (props.editDistributor) {
      let machines_dict = {};
      for (let i = 0; i < props.distributorMachines.length; ++i) {
        machines_dict[props.distributorMachines[i].id] =
          props.distributorMachines[i].is_active;
      }
      setSelectedMachines(machines_dict);
    }
    if (props.distributor !== null && props.distributor !== undefined) {
      if (props.distributor.contract_plan !== null) {
        setContractPlan(props.distributor.contract_plan.id);
      } else {
        setContractPlan(default_contract_plan);
      }
    }
  }, [
    props.activateAllMachines,
    props.editDistributor,
    props.distributorMachines,
    props.distributor,
    default_contract_plan,
  ]);

  const changeMachineState = (machine_id, new_state) => {
    let new_selected_elements_dict = { ...selectedMachines };
    new_selected_elements_dict[machine_id] = new_state;
    setSelectedMachines(new_selected_elements_dict);
  };

  let machines = props.distributorMachines;
  machines = machines.filter((machine) =>
    dict_contains_search_input(machine, searchInput)
  );

  const handleAccept = () => {
    let distributor_data = {
      id: props.distributor.id,
      linked_machines: get_linked_machines_array(selectedMachines),
      contract_plan: contractPlan,
    };
    if (contractPlan === null) {
      distributor_data["contract_plan"] = Object.keys(contract_plans_dict)[0];
    }
    props.createOrUpdateDistributor(distributor_data);
    if (props.editDistributor) {
      props.handleClose();
    } else {
      props.handleAcceptButton();
    }
  };

  let machinesCount = "";
  let countSelectedMachines = 0;
  Object.keys(selectedMachines).forEach((item, i) => {
    if (selectedMachines[item]) {
      countSelectedMachines++;
    }
  });
  if (props.distributor !== null && props.distributor !== undefined) {
    if (props.distributor.contract_plan !== null) {
      if (props.distributor.contract_plan.max_machines === -1) {
        machinesCount = t("machinesView.unlimitedMachines");
      } else {
        machinesCount =
          countSelectedMachines +
          t("machinesView.of") +
          (props.distributor.contract_plan.max_machines +
            props.distributor.extra_machines) +
          t("machinesView.machines");
      }
    }
  }

  let dropdownContractPlans;
  if (contract_plans !== null && contract_plans !== undefined) {
    dropdownContractPlans = (
      <Grid item xs={12}>
        <DropdownInput
          sorted={false}
          className={classes.textInput}
          label={t("distributorsView.distributorsModal.contract_plan")}
          options={contract_plans_dict}
          value={contractPlan}
          onChange={(value) => {
            setContractPlan(value);
          }}
        />
      </Grid>
    );
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.linkMachinesModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="center"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t(
                  "distributorsView.distributorsModal.linkMachinesModal.title"
                )}
              </h2>
              <Divider />
            </Grid>
            {dropdownContractPlans}
            <Grid item xs={12}>
              <SearchField value={searchInput} onChange={setSearchInput} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                className={classes.selectAll}
                control={
                  <Checkbox
                    checked={allMachinesSelected}
                    onChange={(value) => {
                      if (
                        value.target.checked &&
                        props.distributor.contract_plan !== null
                      ) {
                        if (
                          props.distributorMachines.length >
                            props.distributor.contract_plan.max_machines +
                              props.distributor.extra_machines &&
                          props.distributor.contract_plan.max_machines !== -1
                        ) {
                          setShowExcededMachineLimitModal(true);
                        } else {
                          setSelectedMachines(
                            generateNewDictOfSelectedMachines(
                              props.distributorMachines,
                              value.target.checked
                            )
                          );
                          setAllMachinesSelected(value.target.checked);
                        }
                      } else {
                        setSelectedMachines(
                          generateNewDictOfSelectedMachines(
                            props.distributorMachines,
                            value.target.checked
                          )
                        );
                        setAllMachinesSelected(value.target.checked);
                      }
                    }}
                  />
                }
                label={t(
                  "distributorsView.distributorsModal.linkMachinesModal.selectAll"
                )}
              />
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <h3 style={{ marginTop: "7px" }}>{machinesCount}</h3>
            </Grid>
            <Grid item xs={12}>
              <ReactTable
                data={machines}
                loading={props.loadingNonActiveDistributorMachines}
                columns={[
                  {
                    Header: null,
                    width: 55,
                    Cell: ({ row }) => (
                      <Checkbox
                        checked={selectedMachines[row._original.id]}
                        onChange={(value) => {
                          if (
                            value.target.checked &&
                            props.distributor.contract_plan !== null
                          ) {
                            if (
                              countSelectedMachines + 1 >
                                props.distributor.contract_plan.max_machines +
                                  props.distributor.extra_machines &&
                              props.distributor.contract_plan.max_machines !==
                                -1
                            ) {
                              setShowExcededMachineLimitModal(true);
                            } else {
                              changeMachineState(
                                row._original.id,
                                value.target.checked
                              );
                            }
                          } else {
                            changeMachineState(
                              row._original.id,
                              value.target.checked
                            );
                          }
                        }}
                      />
                    ),
                  },
                  {
                    Header: t(
                      "distributorsView.distributorsModal.linkMachinesModal.ckserial"
                    ),
                    accessor: "ckserial",
                  },
                  {
                    Header: t(
                      "distributorsView.distributorsModal.linkMachinesModal.model"
                    ),
                    accessor: "model",
                  },
                  {
                    Header: t(
                      "distributorsView.distributorsModal.linkMachinesModal.alias"
                    ),
                    accessor: "alias",
                  },
                  {
                    Header: t(
                      "distributorsView.distributorsModal.linkMachinesModal.instalationDate"
                    ),
                    accessor: "installation_date",
                  },
                  {
                    Header: t(
                      "distributorsView.distributorsModal.linkMachinesModal.address"
                    ),
                    accessor: "address",
                  },
                ]}
                defaultPageSize={10}
                className="linkTable"
                showPageSizeOptions={false}
                previousText={t("tableTags.previous")}
                nextText={t("tableTags.next")}
                loadingText={t("tableTags.loading")}
                noDataText={t("tableTags.noData")}
                pageText={t("tableTags.page")}
                ofText={t("tableTags.of")}
                rowsText={t("tableTags.rows")}
              />
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  disabled={props.loadingNonActiveDistributorMachines}
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    if (props.distributor.contract_plan !== null) {
                      if (props.distributor.contract_plan.id !== contractPlan) {
                        setShowChangePlanModal(true);
                      } else {
                        handleAccept();
                      }
                    } else {
                      handleAccept();
                    }
                  }}
                >
                  {t(
                    "distributorsView.distributorsModal.linkMachinesModal.accept"
                  )}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={props.handleClose}
                >
                  {t(
                    "distributorsView.distributorsModal.linkMachinesModal.cancel"
                  )}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showChangePlanModal}
        handleClose={() => setShowChangePlanModal(false)}
        handleAcceptButton={() => {
          handleAccept();
          setShowChangePlanModal(false);
        }}
        title={t("sureToChangePlan.title")}
        description={t("sureToChangePlan.description")}
        acceptButtonDescription={t("sureToChangePlan.accept")}
        cancelButtonDescription={t("sureToChangePlan.cancel")}
      />
      <InformationModal
        open={showExcededMachineLimitModal}
        handleClose={() => setShowExcededMachineLimitModal(false)}
        title={t("excededMachineLimitModal.title")}
        description={t("excededMachineLimitModal.description")}
        buttonDescription={t("excededMachineLimitModal.accept")}
      />
    </div>
  );
};

export default LinkMachinesModal;
