import React from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";

import UserAvatar from "./UserAvatar";
import MoreOptionsButton from "./MoreOptionsButton";
import DistributorAndGroupSelector from "./DistributorAndGroupSelector";

import { DRAWER_WIDTH } from "../utils/constants/common_constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgb(237, 237, 237)",
    boxShadow: "none",
    borderBottom: "1px solid rgb(152, 152, 152)",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - 72px)`,
    },
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginRight: 36,
    color: "rgb(94, 94, 94)",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    color: "rgb(94, 94, 94)",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const AdministrationBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <Box display={{ xs: "block", lg: "none", md: "none" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              props.open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {t("navigation_bar:" + props.view_path)}
        </Typography>

        <DistributorAndGroupSelector
          distributors_with_groups={props.distributors_with_groups}
          selected_distributor={props.selected_distributor}
          on_select_distributor_change={props.on_select_distributor_change}
          selected_group={props.selected_group}
          on_select_group_change={props.on_select_group_change}
        />

        <UserAvatar
          firstName={props.user_profile.first_name}
          lastName={props.user_profile.last_name}
          isAdmin={props.user_profile.is_admin}
        />
        <MoreOptionsButton
          numberOfOpenAlerts={props.numberOfOpenAlerts}
          on_logout_click={props.on_logout_click}
          user_profile={props.user_profile}
          loggedUser={props.user_profile}
          alertsHash={props.alerts_hash}
          distributorsWithGroups={props.distributors_with_groups}
        />
      </Toolbar>
    </AppBar>
  );
};

export default AdministrationBar;
