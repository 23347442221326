import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { useTranslation } from "react-i18next";

import { SERVER_URL } from "../utils/api_calls/common/routes";
import { is_predictive_module } from "../utils/js/common_functions";

import { ReactComponent as SmallRiskIconLow } from "../media/svg/small_riskicon_low.svg";
import { ReactComponent as SmallRiskIconMedium } from "../media/svg/small_riskicon_medium.svg";
import { ReactComponent as SmallRiskIconHigh } from "../media/svg/small_riskicon_high.svg";

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles({
  card: {
    boxShadow: "none",
    border: "1px solid black",
    borderRadius: "0px",
    height: "211px",
  },
  icon: {
    height: 70,
    width: 70,
  },
  componentText: {
    fontSize: 14,
    margin: 0,
  },
  componentContainer: {
    width: "100%",
    height: "100%",
  },
  displayerGrid: {
    paddingTop: "0px !important",
  },
  displayerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-5px",
    marginBottom: "-5px",
  },
  riskMargin: {
    marginRight: 7,
  },
});

/**
 *  Custom card used to show the different components of a machine inside **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function ComponentCard(props) {
  const classes = useStyles();
  const { t } = useTranslation("machines_list");

  const stateStyle = {
    height: 30,
    width: 30,
    marginRight: 5,
    color: props.stateColor,
  };

  let stateDisplayer = (
    <React.Fragment>
      <Box className={classes.displayerContainer}>
        <CheckCircleOutline style={stateStyle} />
        <p>{t("componentCard.state") + props.stateLevel}</p>
      </Box>
    </React.Fragment>
  );
  if (props.state > 1) {
    stateDisplayer = (
      <React.Fragment>
        <Box className={classes.displayerContainer}>
          <ErrorOutline style={stateStyle} />
          <p>{props.stateDescription}</p>
        </Box>
      </React.Fragment>
    );
  }

  let riskDisplayer;
  switch (props.risk) {
    case 1:
      riskDisplayer = <SmallRiskIconLow className={classes.riskMargin} />;
      break;
    case 2:
      riskDisplayer = <SmallRiskIconMedium className={classes.riskMargin} />;
      break;
    case 3:
      riskDisplayer = <SmallRiskIconHigh className={classes.riskMargin} />;
      break;
    default:
      riskDisplayer = <SmallRiskIconLow className={classes.riskMargin} />;
  }

  return (
    <Card className={classes.card}>
      <Box className={classes.componentContainer}>
        <Grid
          container
          className={classes.componentContainer}
          alignItems="center"
          justify="center"
        >
          <Grid item className={classes.displayerGrid} xs={12}>
            {stateDisplayer}
          </Grid>
          {is_predictive_module(
            props.distributor_contract_plan.contract_plan,
            props.isDistributorAdmin
          ) && (
            <Grid item className={classes.displayerGrid} xs={12}>
              <Box className={classes.displayerContainer}>
                {riskDisplayer}
                <p>{t("componentCard.risk") + props.riskDescription}</p>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <img
              className={classes.icon}
              src={SERVER_URL + props.img_path}
              alt={"comp"}
            />
            <p className={classes.componentText}>
              <b>
                {props.nomDispositiu}
                <br />
              </b>
              {props.serial}
            </p>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

ComponentCard.propTypes = {
  /** Floating number in the range [0.0 1.0] which express the probability of machine component failure.*/
  risk: PropTypes.number,
  /** Component name to display in the card*/
  nomDispositiu: PropTypes.string,
  /** Serial of the component*/
  serial: PropTypes.string,
  /** Image path where the image is stored. This path is supposed to reside in the backend of the application, in the
   * folder of the static files.*/
  img_path: PropTypes.string,
};
