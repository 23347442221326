import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import { SERVER_URL } from "../utils/api_calls/common/routes";

import { useTranslation } from "react-i18next";

import WorkingRangeOkImage from "../media/images/working_range_ok.png";
import WorkingRangeExceededImage from "../media/images/working_range_exceeded.png";

import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    border: "1px solid black",
    borderRadius: "0px",
    height: "211px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    width: "100%",
  },
  icon: {
    maxWidth: 150,
    maxHeight: 150,
    [theme.breakpoints.down("md")]: {
      maxWidth: 100,
      maxHeight: 120,
    },
  },
  machineTitle: {
    fontWeight: "bold",
    color: "grey",
    fontSize: "26px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
  },
  machineCKSerial: {
    fontWeight: "bold",
    color: "grey",
    fontSize: "14px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "10px",
    },
  },
  header: {
    fontWeight: "bold",
    fontSize: 12,
    marginTop: "6px",
  },
  workingRangeImg: {
    height: 20,
  },
  textContainer: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  content: {
    fontSize: "0.7rem",
  },
  timestampContainer: {
    margin: 0,
    fontSize: "10px",
    borderBottom: "none !important",
    textDecoration: "none !important",
  },
}));

/**
 *  Custom card used to show the machine information inside **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function MachineCard(props) {
  const classes = useStyles();
  const { t } = useTranslation("machines_list");

  let working_range = (
    <img
      className={classes.workingRangeImg}
      src={WorkingRangeOkImage}
      alt="Working Range Ok"
    />
  );
  if (props.working_range) {
    working_range = (
      <img
        className={classes.workingRangeImg}
        src={WorkingRangeExceededImage}
        alt="Working Range Exceeded"
      />
    );
  }

  let warranty_date = "-";
  if (props.warranty_date != null) {
    warranty_date = props.warranty_date;
  }

  let installation_date = "-";
  if (props.installation_date != null) {
    installation_date = props.installation_date;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Box className={classes.machineTitle}>{props.model}</Box>
            <Box className={classes.machineCKSerial}>{props.ckserial}</Box>
          </Grid>
          <Grid item xs={6}>
            <img
              className={classes.icon}
              src={SERVER_URL + props.img_path}
              alt={"comp"}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              className={classes.textContainer}
              container
              spacing={0}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Box className={classes.header}>
                  {t("machineCard.installation")}
                </Box>
                <Box className={classes.content}>{installation_date}</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.header}>
                  {t("machineCard.warrantyEnd")}
                </Box>
                <Box className={classes.content}>{warranty_date}</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.header}>
                  {t("machineCard.workingRange")}
                </Box>
                <Box className={classes.content}>{working_range}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

MachineCard.propTypes = {
  /** Name of the machine model that its going to be displayed*/
  model: PropTypes.string,
  /** Image path where the image is stored. This path is supposed to reside in the backend of the application, in the
   * folder of the static files.*/
  img_path: PropTypes.string,
  /** Placeholder (this prop is not going to exist on final version).*/
  avgBill: PropTypes.string,
  /** Placeholder (this prop is not going to exist on final version).*/
  avgCoins: PropTypes.string,
  /** Placeholder (this prop is not going to exist on final version).*/
  installation_date: PropTypes.string,
};
