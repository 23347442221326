import React from "react";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./MachinesTable.css";

import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import MachinesModal from "./MachinesModal";
import Box from "@material-ui/core/Box";

import { is_predictive_module } from "../utils/js/common_functions";

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: "right",
    paddingRight: "8px",
    whiteSpace: "initial",
  },
  content: {
    textAlign: "left",
    paddingLeft: "8px",
    whiteSpace: "initial",
  },
  editIcon: {
    cursor: "pointer",
    width: "100%",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const MachinesTable = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("machines_list");

  const [editMachineModalOpen, editMachineModalSetState] =
    React.useState(false);
  const [selectedElement, selectedElementSetState] = React.useState(null);
  const [selectedRow, selectedRowSetState] = React.useState(null);
  const [currentPage, currentPageSetState] = React.useState(0);

  let reactTable = React.useRef(null);

  React.useEffect(() => {
    if (props.selected === -1) {
      currentPageSetState(0);
    } else {
      currentPageSetState(Math.floor(props.selected / 15));
    }
  }, [props.selected]);

  let columns;
  let minRows;
  if (isWidthUp("md", props.width)) {
    minRows = 15;
    columns = [
      {
        Header: null,
        width: 50,
        Cell: ({ row }) => (
          <Edit
            className={classes.editIcon}
            onClick={() => {
              selectedRowSetState(row._index);
              selectedElementSetState(row._original);
              editMachineModalSetState(true);
            }}
          />
        ),
      },
      {
        Header: t("machineTable.state"),
        accessor: "state",
        width: 50,
        Cell: ({ row }) => {
          return (
            <Box
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                border: "4px solid " + row._original.state_color,
                margin: "0 auto",
              }}
            />
          );
        },
        sortMethod: (a, b, desc) => {
          // force null and undefined to the bottom
          if (desc) {
            if (a === b) return 0;
            else if (a > b) return 1;
            else if (b > a) return -1;
            else return -1;
          } else {
            if (a === b) return 0;
            else if (a > b) return -1;
            else if (b > a) return 1;
            else return 1;
          }
        },
      },
      {
        Header: t("machineTable.ckserial"),
        accessor: "ckserial",
        Cell: (e) => (
          <p
            className={classes.link}
            onClick={() => props.changeWindowOnCkserialClick(e.value)}
          >
            {" "}
            {e.value}{" "}
          </p>
        ),
      },
      {
        Header: t("machineTable.model"),
        accessor: "model",
      },
      {
        Header: t("machineTable.alias"),
        accessor: "alias",
      },
      {
        Header: t("machineTable.address"),
        accessor: "address",
      },
      {
        Header: t("machineTable.last_timestamp"),
        accessor: "last_timestamp",
      },
    ];
    if (
      is_predictive_module(
        props.distributor_contract_plan.contract_plan,
        props.isDistributorAdmin
      )
    ) {
      columns.splice(2, 0, {
        Header: t("machineTable.risk"),
        width: 50,
        accessor: "risk",
        sortMethod: (a, b, desc) => {
          // force null and undefined to the bottom
          if (desc) {
            if (a === b) return 0;
            else if (a > b) return 1;
            else if (b > a) return -1;
            else return -1;
          } else {
            if (a === b) return 0;
            else if (a > b) return -1;
            else if (b > a) return 1;
            else return 1;
          }
        },
        Cell: ({ row }) => {
          return <div>{row._original.risk_description}</div>;
        },
      });
    }
    if (props.isDistributorAdmin) {
      columns.splice(4, 0, {
        Header: t("machineTable.distributor"),
        accessor: "distributor_name",
      });
    } else {
      columns.splice(4, 0, {
        Header: t("machineTable.group"),
        accessor: "group_name",
      });
    }
  } else {
    minRows = 1;
    columns = [
      {
        Header: null,
        Cell: ({ row }) => {
          let distributor_or_group_row;
          if (props.isDistributorAdmin) {
            distributor_or_group_row = (
              <React.Fragment>
                <Grid item xs={4} className={classes.header}>
                  <b>{t("machineTable.distributor") + ":"}</b>
                </Grid>
                <Grid item xs={8} className={classes.content}>
                  {row._original.distributor_name}
                </Grid>
              </React.Fragment>
            );
          } else {
            distributor_or_group_row = (
              <React.Fragment>
                <Grid item xs={4} className={classes.header}>
                  <b>{t("machineTable.group") + ":"}</b>
                </Grid>
                <Grid item xs={8} className={classes.content}>
                  {row._original.group_name}
                </Grid>
              </React.Fragment>
            );
          }
          return (
            <div>
              <Grid
                container
                spacing={0}
                alignItems="flex-start"
                justify="flex-start"
              >
                <Grid item xs={11}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.ckserial") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      <span
                        className={classes.link}
                        onClick={() =>
                          props.changeWindowOnCkserialClick(
                            row._original.ckserial
                          )
                        }
                      >
                        {row._original.ckserial}
                      </span>
                    </Grid>
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.model") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      {row._original.model}
                    </Grid>
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.alias") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      {row._original.alias}
                    </Grid>
                    {distributor_or_group_row}
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.address") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      {row._original.address}
                    </Grid>
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.last_timestamp") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      {row._original.last_timestamp}
                    </Grid>
                    <Grid item xs={4} className={classes.header}>
                      <b>{t("machineTable.state") + ":"}</b>
                    </Grid>
                    <Grid item xs={8} className={classes.content}>
                      {row._original.state_level}
                    </Grid>
                    {is_predictive_module(
                      props.distributor_contract_plan.contract_plan,
                      props.isDistributorAdmin
                    ) && (
                      <React.Fragment>
                        <Grid item xs={4} className={classes.header}>
                          <b>{t("machineTable.risk") + ":"}</b>
                        </Grid>
                        <Grid item xs={8} className={classes.content}>
                          {row._original.risk_description}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Edit
                    className={classes.editIcon}
                    onClick={() => {
                      selectedElementSetState(row._original);
                      editMachineModalSetState(true);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          );
        },
      },
    ];
  }
  return (
    <div>
      <ReactTable
        ref={(r) => (reactTable = r)}
        data={props.machines}
        page={currentPage}
        onPageChange={(pageIndex) => {
          currentPageSetState(pageIndex);
        }}
        onSortedChange={(newSorted, column, shiftKey) => {
          let sorted_machines = reactTable
            .getResolvedState()
            .sortedData.map((dataRow) => dataRow._original);
          let i = 0;
          let found = false;
          if (props.selected !== -1) {
            while (i < sorted_machines.length && !found) {
              if (
                sorted_machines[i].ckserial ===
                props.machines[props.selected].ckserial
              ) {
                found = true;
              } else {
                ++i;
              }
            }
          }
          if (found) {
            props.selectedSetState(i);
            props.updateSelectedMachine(i);
          } else {
            props.selectedSetState(-1);
            props.updateSelectedMachine(-1);
          }
          props.updateMachinesSet(sorted_machines);
        }}
        columns={columns}
        minRows={minRows}
        loading={props.loadingMachines}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="highlight machinesListTable"
        previousText={t("machineTable.previous")}
        nextText={t("machineTable.next")}
        loadingText={t("machineTable.loading")}
        noDataText={t("machineTable.noData")}
        pageText={t("machineTable.page")}
        ofText={t("machineTable.of")}
        rowsText={t("machineTable.rows")}
        getTdProps={(state, rowInfo, column, instance) => {
          if (typeof rowInfo !== "undefined") {
            return {
              onClick: (e, handleOriginal) => {
                if (
                  (isWidthUp("md", props.width) && column.Header !== null) ||
                  !isWidthUp("md", props.width)
                ) {
                  if (rowInfo.index === props.selected) {
                    props.userHasDeselectedSetState(true);
                    props.selectedSetState(-1);
                  } else {
                    props.userHasDeselectedSetState(false);
                    props.selectedSetState(rowInfo.index);
                  }
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              },
              style: {
                background:
                  rowInfo.index === props.selected
                    ? "rgb(255, 163, 15)"
                    : "none",
                color: rowInfo.index === props.selected ? "white" : "black",
              },
            };
          } else {
            return {
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };
          }
        }}
      />
      <MachinesModal
        open={editMachineModalOpen}
        handleClose={() => editMachineModalSetState(false)}
        machine={selectedElement}
        selectedRow={selectedRow}
        updateSelectedMachine={props.updateSelectedMachine}
        updateSearchInput={props.updateSearchInput}
        searchInput={props.searchInput}
        distributorsWithGroups={props.distributorsWithGroups}
        updateMachine={(machine) => props.updateMachine(machine)}
        isDistributorAdmin={props.isDistributorAdmin}
        machineSectors={props.machineSectors}
      />
    </div>
  );
};

export default withWidth()(MachinesTable);
