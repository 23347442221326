import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
  containerDescription: {
    marginTop: "4em",
  },
  containerButton: {
    marginTop: "2em",
    marginBottom: "3em",
  },
}));

const ResetPasswordFormSuccessful = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("login");

  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item xs={12} className={classes.containerDescription}>
          {t("resetPasswordFormSuccessful.description")}
        </Grid>
        <Grid item xs={12} className={classes.containerButton}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => props.push("/")}
          >
            {t("resetPasswordFormSuccessful.goBack")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(null, { push })(ResetPasswordFormSuccessful);
