import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  numberOfAlertsDiv: {
    border: "6px solid rgb(255, 163, 15)",
    borderRadius: "40px",
    color: "rgb(255, 102, 102)",
    height: "335px",
  },
  numberOfAlertsNumber: {
    fontSize: "192px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "160px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "112px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "192px",
    },
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70%",
  },
  numberOfAlertsText: {
    fontSize: "36px",
    overflow: "hidden",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30%",
  },
}));

const NumberOfAlerts = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.numberOfAlertsDiv}>
      <div className={classes.numberOfAlertsNumber}>
        {props.numberOfOpenAlerts}
      </div>
      <div className={classes.numberOfAlertsText}>
        {props.criticalAlertsText +
          " " +
          props.in +
          " " +
          props.numberOfMachines.toString()}
      </div>
    </div>
  );
};

export default NumberOfAlerts;
