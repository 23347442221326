import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";

import TextInput from "../../utils/components/TextInput";

import { useTranslation } from "react-i18next";

import Close from "@material-ui/icons/Close";
import AcceptActionModal from "../../utils/components/AcceptActionModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    height: "75%",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  blockedUserCheckbox: {
    textAlign: "right",
    paddingRight: "0px",
    float: "left",
    paddingLeft: "8px",
  },
  textInput: {
    width: "100%",
  },
  resetPasswordContainer: {
    textAlign: "right",
    paddingRight: "0px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  errorMsg: {
    color: "red",
  },
  sentMsg: {
    color: "green",
  },
  infiniteCheckbox: {
    paddingTop: "10px",
  },
  moduleCheckbox: {
    width: "40%",
  },
}));

const ContractPlansModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [name, setName] = React.useState("");
  const [max_users, setMaxUsers] = React.useState("");
  const [max_groups, setMaxGroups] = React.useState("");
  const [max_machines, setMaxMachines] = React.useState("");
  const [is_default, setIsDefault] = React.useState(false);
  const [infinite_users, setInfiniteUsers] = React.useState(false);
  const [infinite_groups, setInfiniteGroups] = React.useState(false);
  const [infinite_machines, setInfiniteMachines] = React.useState(false);
  const [module_dashboard, setModuleDashboard] = React.useState(false);
  const [module_machines_list, setModuleMachinesList] = React.useState(false);
  const [module_scoring, setModuleScoring] = React.useState(false);
  const [module_predictive, setModulePredictive] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);

  const setNewState = React.useCallback((contract_plan_data) => {
    setName(contract_plan_data.name);
    if (contract_plan_data.max_users === -1) {
      setMaxUsers("");
      setInfiniteUsers(true);
    } else {
      setMaxUsers(contract_plan_data.max_users.toString());
      setInfiniteUsers(false);
    }
    if (contract_plan_data.max_groups === -1) {
      setMaxGroups("");
      setInfiniteGroups(true);
    } else {
      setMaxGroups(contract_plan_data.max_groups.toString());
      setInfiniteGroups(false);
    }
    if (contract_plan_data.max_machines === -1) {
      setMaxMachines("");
      setInfiniteMachines(true);
    } else {
      setMaxMachines(contract_plan_data.max_machines.toString());
      setInfiniteMachines(false);
    }
    setIsDefault(contract_plan_data.is_default);
    setModuleDashboard(contract_plan_data.module_dashboard);
    setModuleMachinesList(contract_plan_data.module_machines_list);
    setModuleScoring(contract_plan_data.module_scoring);
    setModulePredictive(contract_plan_data.module_predictive);
  }, []);

  const contract_plan_data = props.contractPlanData;

  const resetStateToDefault = React.useCallback(() => {
    setName("");
    setMaxUsers("");
    setMaxGroups("");
    setMaxMachines("");
    setIsDefault(false);
    setInfiniteUsers(false);
    setInfiniteGroups(false);
    setInfiniteMachines(false);
    setModuleDashboard(false);
    setModuleMachinesList(false);
    setModuleScoring(false);
    setModulePredictive(false);
  }, []);

  const handleAcceptButton = () => {
    if (is_default) {
      props.contractPlans.map((item) => (item.is_default = false));
    }
    if (max_users === "") {
      setMaxUsers("0");
    }
    if (max_groups === "") {
      setMaxGroups("0");
    }
    if (max_machines === "") {
      setMaxMachines("0");
    }
    let contract_plan = {
      name: name,
      max_users: parseInt(max_users),
      max_groups: parseInt(max_groups),
      max_machines: parseInt(max_machines),
      is_default: is_default,
      module_dashboard: module_dashboard,
      module_machines_list: module_machines_list,
      module_scoring: module_scoring,
      module_predictive: module_predictive,
    };
    if (contract_plan_data !== null) {
      contract_plan["id"] = contract_plan_data.id;
    }
    if (infinite_users) {
      contract_plan["max_users"] = -1;
    }
    if (infinite_groups) {
      contract_plan["max_groups"] = -1;
    }
    if (infinite_machines) {
      contract_plan["max_machines"] = -1;
    }
    props.createOrUpdateContractPlan(contract_plan);
    props.handleClose();
  };

  const handleCancelButton = () => {
    if (!hasChanges) {
      resetStateToDefault();
      props.handleClose();
    } else {
      setShowSureToCancelModal(true);
    }
  };

  const handlePreviousModalClose = () => {
    setShowSureToCancelModal(false);
    resetStateToDefault();
    props.handleClose();
  };

  React.useEffect(() => {
    if (contract_plan_data !== null) {
      setNewState(contract_plan_data);
    }
  }, [contract_plan_data, setNewState]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick={true}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("contractPlansView.contractPlansModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                className={classes.textInput}
                label={t("contractPlansView.contractPlansModal.name")}
                value={name}
                onChange={(value) => {
                  setName(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                className={classes.infiniteCheckbox}
                control={
                  <Checkbox
                    checked={is_default}
                    color="secondary"
                    onChange={(value) => {
                      if (!is_default) {
                        setIsDefault(value.target.checked);
                        setHasChanges(true);
                      }
                    }}
                  />
                }
                label={t("contractPlansView.contractPlansModal.is_default")}
                labelPlacement="bottom"
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                disabled={infinite_users}
                className={classes.textInput}
                label={t("contractPlansView.contractPlansModal.max_users")}
                value={max_users}
                onChange={(value) => {
                  let lastChar;
                  if (value.length === 0) {
                    setMaxUsers(value);
                    setHasChanges(true);
                  } else {
                    lastChar = value.substr(value.length - 1);
                    if (!isNaN(parseInt(lastChar)) && parseInt(value) > 0) {
                      setMaxUsers(value);
                      setHasChanges(true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                className={classes.infiniteCheckbox}
                control={
                  <Checkbox
                    checked={infinite_users}
                    color="secondary"
                    onChange={(value) => {
                      setInfiniteUsers(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t("contractPlansView.contractPlansModal.infinite_users")}
                labelPlacement="bottom"
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                disabled={infinite_groups}
                className={classes.textInput}
                label={t("contractPlansView.contractPlansModal.max_groups")}
                value={max_groups}
                onChange={(value) => {
                  let lastChar;
                  if (value.length === 0) {
                    setMaxGroups(value);
                    setHasChanges(true);
                  } else {
                    lastChar = value.substr(value.length - 1);
                    if (!isNaN(parseInt(lastChar)) && parseInt(value) >= 0) {
                      setMaxGroups(value);
                      setHasChanges(true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                className={classes.infiniteCheckbox}
                control={
                  <Checkbox
                    checked={infinite_groups}
                    color="secondary"
                    onChange={(value) => {
                      setInfiniteGroups(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t(
                  "contractPlansView.contractPlansModal.infinite_groups"
                )}
                labelPlacement="bottom"
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                disabled={infinite_machines}
                className={classes.textInput}
                label={t("contractPlansView.contractPlansModal.max_machines")}
                value={max_machines}
                onChange={(value) => {
                  let lastChar;
                  if (value.length === 0) {
                    setMaxMachines(value);
                    setHasChanges(true);
                  } else {
                    lastChar = value.substr(value.length - 1);
                    if (!isNaN(parseInt(lastChar)) && parseInt(value) >= 0) {
                      setMaxMachines(value);
                      setHasChanges(true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                className={classes.infiniteCheckbox}
                control={
                  <Checkbox
                    checked={infinite_machines}
                    color="secondary"
                    onChange={(value) => {
                      setInfiniteMachines(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t(
                  "contractPlansView.contractPlansModal.infinite_machines"
                )}
                labelPlacement="bottom"
              />
            </Grid>
            <Box
              alignItems="flex-start"
              justify="flex-start"
              border={1}
              borderColor="grey.400"
              width="100%"
              borderRadius="borderRadius"
            >
              <FormControlLabel
                className={classes.moduleCheckbox}
                control={
                  <Checkbox
                    checked={module_dashboard}
                    color="secondary"
                    onChange={(value) => {
                      setModuleDashboard(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t(
                  "contractPlansView.contractPlansModal.module_dashboard"
                )}
                labelPlacement="bottom"
              />
              <FormControlLabel
                className={classes.moduleCheckbox}
                control={
                  <Checkbox
                    checked={module_machines_list}
                    color="secondary"
                    onChange={(value) => {
                      setModuleMachinesList(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t(
                  "contractPlansView.contractPlansModal.module_machines_list"
                )}
                labelPlacement="bottom"
              />
              <FormControlLabel
                className={classes.moduleCheckbox}
                control={
                  <Checkbox
                    checked={module_scoring}
                    color="secondary"
                    onChange={(value) => {
                      setModuleScoring(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t("contractPlansView.contractPlansModal.module_scoring")}
                labelPlacement="bottom"
              />
              <FormControlLabel
                className={classes.moduleCheckbox}
                control={
                  <Checkbox
                    checked={module_predictive}
                    color="secondary"
                    onChange={(value) => {
                      setModulePredictive(value.target.checked);
                      setHasChanges(true);
                    }}
                  />
                }
                label={t(
                  "contractPlansView.contractPlansModal.module_predictive"
                )}
                labelPlacement="bottom"
              />
            </Box>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("usersView.usersModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("usersView.usersModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
    </div>
  );
};

export default ContractPlansModal;
