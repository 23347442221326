// React imports
import React from "react";
import PropTypes from "prop-types";

// Third-Party imports
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Local imports
import MachinesSearcher from "./MachinesSearcher";
import MachinesInfo from "./MachinesInfo";
import { mapStateToPropsMachinesList } from "../utils/redux/mappers/machines_list_mappers";
import { mapDispatchToPropsMachinesList } from "../utils/redux/mappers/machines_list_mappers";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  machinesList: {
    backgroundColor: "rgb(237, 237, 237)",
    height: "calc(100% - 64px)",
    minHeight: "calc(100% - 64px)",
    padding: "20px",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
}));

const machine_contains_search_input = (machine, search_input = "") => {
  if (search_input.length === 0) {
    return true;
  }

  let match_found = true;
  let search_input_array = search_input.toLowerCase().split(" ");
  search_input_array.forEach((item, i) => {
    let word_found = false;
    Object.keys(machine).forEach(function (key) {
      if (
        machine[key] !== null &&
        machine[key].toString().toLowerCase().includes(item)
      ) {
        word_found = true;
      }
    });
    if (word_found === false) {
      match_found = false;
    }
  });

  return match_found;
};

/**
 *  Meta container based on Material-UI Grid that serve as the base container of **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
function MachinesList(props) {
  const classes = useStyles();

  // Variable that stores the search input introduced by the user
  const [searchInput, searchInputRawSetState] = React.useState(
    props.searchInput
  );
  // Variable that stores the index of the selected machine on the table
  const [selected, selectedSetState] = React.useState(
    props.defaultSelectedMachineIndex
  );
  // Variable that stores if the user has manually deselected the selected row in the table
  const [userHasDeselected, userHasDeselectedSetState] = React.useState(false);

  let machines = [];
  machines = props.machines.filter((machine) =>
    machine_contains_search_input(machine, searchInput)
  );

  const searchInputSetState = (input) => {
    searchInputRawSetState(input);
    selectedSetState(-1);
  };

  let selected_machine;
  if (selected === -1) {
    selected_machine = null;
  } else {
    selected_machine = machines[selected];
  }

  if (selected !== -1 && machines.length === 0) {
    selectedSetState(-1);
  } else if (selected === -1 && machines.length > 0 && !userHasDeselected) {
    selectedSetState(0);
  }

  return (
    <div className={classes.machinesList}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item xs={12} md={6}>
          <MachinesSearcher
            searchInput={searchInput}
            searchInputSetState={(value) => {
              searchInputSetState(value);
              userHasDeselectedSetState(false);
            }}
            userHasDeselected={userHasDeselected}
            userHasDeselectedSetState={userHasDeselectedSetState}
            machines={machines}
            selected={selected}
            updateSelectedMachine={props.update_selected_machine}
            changeWindowOnCkserialClick={props.changeWindowOnCkserialClick}
            selectedSetState={selectedSetState}
            updateMachinesSet={props.update_machines_set}
            updateSearchInput={props.update_search_input}
            distributorsWithGroups={props.distributors_with_groups}
            updateMachine={(machine) =>
              props.update_machine(props.auth_token, machine)
            }
            loadingMachines={props.loading_machines}
            machineSectors={props.active_machine_sectors}
            isDistributorAdmin={props.distributor_contract_plan.is_admin}
            distributor_contract_plan={props.distributor_contract_plan}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MachinesInfo
            machines={machines}
            selectedMachine={selected_machine}
            isDistributorAdmin={props.distributor_contract_plan.is_admin}
            distributor_contract_plan={props.distributor_contract_plan}
          />
        </Grid>
      </Grid>
    </div>
  );
}

MachinesList.propTypes = {
  /** Id of the user selected distributor in Predik -> Administration Bar*/
  selected_distributor: PropTypes.string,
  /** Id of the user selected group in Predik -> Administration Bar*/
  selected_group: PropTypes.string,
  /** List of machines that contains all the relevant information of the machines of **Predik** application.*/
  machines: PropTypes.array,
  /** Array of dicts containing the different distributors and groups of the application. The structure must be
   * {"distid":{"name": "asd", "groups": {"groupid":{"name": "asd2", "is_default": False}, ...}, ...}*/
  distributors_with_groups: PropTypes.object,
  /** Token used in the transactions between frontend and backend*/
  auth_token: PropTypes.string,
  /** Id of the distributor assigned to the logged user*/
  logged_user_distributor: PropTypes.string,
  /** Function that makes an API call in order to update machine data in backend*/
  update_machine: PropTypes.func,
};

export default connect(
  mapStateToPropsMachinesList,
  mapDispatchToPropsMachinesList
)(MachinesList);
