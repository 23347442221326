import React from "react";
import Spinner from "../../media/images/spinner.gif";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingScreen: {
    height: "100vh",
    backgroundColor: "rgb(237, 237, 237)",
    position: "relative",
  },
  loadingImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "200px",
    height: "200px",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingScreen}>
      <img className={classes.loadingImage} src={Spinner} alt="loading..." />
    </div>
  );
};

export default Loading;
