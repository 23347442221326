import React from "react";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  informationModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
}));

export default function InformationModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.informationModal}
      >
        <div className={classes.paper}>
          <h2 className={classes.alignCenter}>{props.title}</h2>
          <p className={classes.alignCenter}>{props.description}</p>
          <Container className={classes.alignCenter}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={props.handleClose}
            >
              {props.buttonDescription}
            </Button>
          </Container>
        </div>
      </Modal>
    </div>
  );
}

InformationModal.defaultProps = {
  title: "",
  description: "",
  buttonDescription: "",
};
