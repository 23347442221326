import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";

import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AlertStateChangeModal from "./AlertStateChangeModal";

const useStyles = makeStyles({
  card: {
    minWidth: 500,
    border: "1px solid rgb(158, 158, 158)",
    boxShadow: "none",
  },
  alertID: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    alignSelf: "center",
  },
  dateAndAssignedPerson: {
    alignSelf: "center",
    textAlign: "right",
    textSize: "14px",
  },
  dateStyle: {
    fontWeight: "bold",
  },
  icon: {
    padding: "0px",
    minWidth: "32px",
  },
  circleAlertState: {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    display: "inline-block",
  },
  cardContent: {},
  icons: {
    justifyContent: "flex-end",
  },
  machineComponentTag: {
    textAlign: "right",
    fontWeight: "bold",
  },
  selectorOpen: {
    backgroundColor: "orange",
  },
  selectorInProgress: {
    backgroundColor: "cyan",
  },
  selectorClose: {
    backgroundColor: "grey",
  },
  errorDesc: {
    fontSize: "16px",
    textAlign: "left",
  },
  alertAddress: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const AlertItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [alertStateChangeState, alertStateChangeSetState] =
    React.useState(false);
  const [userDealingWithAlert, setUserDealingWithAlert] = React.useState();
  const [destinationState, setDestinationState] = React.useState({});
  const [destinationOptions, setDestinationOptions] = React.useState([]);
  const [selectorStyle, setSelectorStyle] = React.useState();
  const [dropdownAlertStateChangeValue, dropdownAlertStateChangeSetState] =
    React.useState(null);
  const [newAlertState, newAlertStateSetState] = React.useState("");

  const handleAccept = () => {
    let alert_change = {
      id: props.alert.alert_id,
      new_state: newAlertState,
      description: parseInt(dropdownAlertStateChangeValue),
    };
    props.updateAlert(alert_change);
    alertStateChangeSetState(false);
    dropdownAlertStateChangeSetState(null);
  };

  let alert_types = props.alertTypes;

  React.useEffect(() => {
    switch (props.alert.alert_state) {
      case "Open":
        setDestinationState({
          label: alert_types["OP"].description,
          value: alert_types["OP"].id,
        });
        setDestinationOptions([
          {
            label: alert_types["IP"].description,
            value: alert_types["IP"].id,
            state: "In Progress",
          },
        ]);
        setSelectorStyle({
          control: (styles) => ({ ...styles, backgroundColor: "#ffe0b3" }),
        });
        break;

      case "In Progress":
        setDestinationState({
          label: alert_types["IP"].description,
          value: alert_types["IP"].id,
        });
        setDestinationOptions([
          {
            label: alert_types["OP"].description,
            value: alert_types["OP"].id,
            state: "Open",
          },
          {
            label: alert_types["CL"].description,
            value: alert_types["CL"].id,
            state: "Closed",
          },
        ]);
        setSelectorStyle({
          control: (styles) => ({ ...styles, backgroundColor: "#b3ffff" }),
        });
        setUserDealingWithAlert(props.alert.user_dealing_with_alert);
        break;

      case "Closed":
        setDestinationState({
          label: alert_types["CL"].description,
          value: alert_types["CL"].id,
        });
        setDestinationOptions([
          {
            label: alert_types["IP"].description,
            value: alert_types["IP"].id,
            state: "In Progress",
          },
        ]);
        setSelectorStyle({
          control: (styles) => ({ ...styles, backgroundColor: "#e6e6e6" }),
        });
        break;

      default:
        setUserDealingWithAlert("");
    }
  }, [props.alert, alert_types]);

  let rma_data;
  if (
    props.alert.last_repair_date.length === 0 &&
    props.alert.last_action.length === 0
  ) {
    rma_data = "No RMA";
  } else {
    rma_data = props.alert.last_action + " - " + props.alert.last_repair_date;
  }

  let alert_change_types = {};
  if (newAlertState in props.alertChangeTypes) {
    alert_change_types = props.alertChangeTypes[newAlertState];
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1} alignItems="flex-start" justify="center">
          <Grid item xs={2} className={classes.alertID}>
            <span style={{ paddingRight: "16px" }}>
              {"ID: " + props.alert.alert_id}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Select
              isClearable={false}
              isSearchable={false}
              value={destinationState}
              options={destinationOptions}
              styles={selectorStyle}
              onChange={(option) => {
                newAlertStateSetState(option.state);
                alertStateChangeSetState(true);
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.dateAndAssignedPerson}>
            <div className={classes.dateStyle}>{props.alert.timestamp}</div>
            <div>{userDealingWithAlert}</div>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-start"
                  justify="center"
                >
                  <Grid item xs={2} className={classes.machineComponentTag}>
                    {t("alertsModal.machine") + ":"}
                  </Grid>
                  <Grid item xs={2}>
                    {props.alert.machine_ckserial}
                  </Grid>
                  <Grid item xs={1}>
                    -
                  </Grid>
                  <Grid item xs={1}>
                    {props.alert.machine_alias}
                  </Grid>
                  <Grid item xs={1}>
                    -
                  </Grid>
                  <Grid item xs={5} className={classes.alertAddress}>
                    {props.alert.machine_address}
                  </Grid>
                  <Grid item xs={2} className={classes.machineComponentTag}>
                    {t("alertsModal.component") + ":"}
                  </Grid>
                  <Grid item xs={2}>
                    {props.alert.component_type}
                  </Grid>
                  <Grid item xs={1}>
                    -
                  </Grid>
                  <Grid item xs={2}>
                    {props.alert.component_serial}
                  </Grid>
                  <Grid item xs={1}>
                    -
                  </Grid>
                  <Grid item xs={4}>
                    {rma_data}
                  </Grid>
                  <Grid item xs={2} className={classes.machineComponentTag}>
                    {t("alertsModal.state") + ":"}
                  </Grid>
                  <Grid item xs={10} className={classes.errorDesc}>
                    {props.alert.error_desc}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <AlertStateChangeModal
        open={alertStateChangeState}
        handleClose={() => alertStateChangeSetState(false)}
        handleAccept={() => handleAccept()}
        dropdownValue={dropdownAlertStateChangeValue}
        dropdownValueSetState={dropdownAlertStateChangeSetState}
        alertChangeTypes={alert_change_types}
      />
    </Card>
  );
};

export default AlertItem;
