import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TextInput from "../utils/components/TextInput";
import AcceptActionModal from "../utils/components/AcceptActionModal";

import { useTranslation } from "react-i18next";

import Close from "@material-ui/icons/Close";
import DropdownInput from "../utils/components/DropdownInput";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "720px",
    height: "90%",
    maxHeight: "850px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 20px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 50px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  addressField: {
    width: "630px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  buttonsContainer: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  textInput: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  testMachineCheckbox: {
    float: "right",
    paddingRight: "16px",
  },
}));

/**
 *  Modal that allows machines fields edition on **Machines List** view.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
const MachinesModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [ckserial, setCkserial] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [installationDate, setInstallationDate] = React.useState("");
  const [model, setModel] = React.useState("");
  const [tlf, setTlf] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [distributor, setDistributor] = React.useState(-1);
  const [group, setGroup] = React.useState(-1);
  const [sector, setSector] = React.useState(-1);
  const [observations, setObservations] = React.useState("");
  const [isTestMachine, setIsTestMachine] = React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [lastCleaningDate, setLastCleaningDate] = React.useState("");

  const resetStateToDefault = React.useCallback(() => {
    let current_date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    setCkserial("");
    setAlias("");
    setAddress("");
    setInstallationDate(current_date);
    setModel("");
    setTlf("");
    setContactPerson("");
    setDistributor(-1);
    setGroup(-1);
    setSector(-1);
    setObservations("");
    setIsTestMachine(false);
    setHasChanges(false);
    setLastCleaningDate(current_date);
  }, []);

  //Function that sets the state of all the state variables in the component to the values defined by machine _prop_.
  const setNewState = React.useCallback(() => {
    let machine_date_array;
    if (props.machine.installation_date === null) {
      machine_date_array = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/")
        .split("/")
        .reverse();
    } else {
      machine_date_array = props.machine.installation_date.split("/");
    }

    let day = machine_date_array[0];
    if (day.length === 1) day = "0" + day;
    let month = machine_date_array[1];
    if (month.length === 1) month = "0" + month;
    let year = machine_date_array[2];
    let parsed_date = year + "-" + month + "-" + day;

    let last_cleaning_date_array;
    if (props.machine.last_cleaning_date === null) {
      last_cleaning_date_array = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/")
        .split("/")
        .reverse();
    } else {
      last_cleaning_date_array = props.machine.last_cleaning_date.split("/");
    }

    let day_cleaning = last_cleaning_date_array[0];
    if (day_cleaning.length === 1) day = "0" + day;

    let month_cleaning = last_cleaning_date_array[1];
    if (month_cleaning.length === 1) month_cleaning = "0" + month_cleaning;
    let year_cleaning = last_cleaning_date_array[2];
    let cleaning_parsed_date =
      year_cleaning + "-" + month_cleaning + "-" + day_cleaning;

    setCkserial(props.machine.ckserial);
    setAlias(props.machine.alias);
    setAddress(props.machine.address);
    setInstallationDate(parsed_date);
    setModel(props.machine.model);
    setGroup(props.machine.group);
    setDistributor(props.machine.distributor);
    setTlf(props.machine.tlf);
    setContactPerson(props.machine.contact_person);
    setObservations(props.machine.observations);
    setIsTestMachine(props.machine.is_test_machine);
    setSector(props.machine.sector);
    setLastCleaningDate(cleaning_parsed_date);
  }, [props.machine]);

  const handleCancelButton = () => {
    if (hasChanges) {
      setShowSureToCancelModal(true);
    } else {
      props.handleClose();
    }
  };

  const handleAcceptButton = () => {
    let installation_date_array = installationDate.split("-");
    let installation_date =
      installation_date_array[2] +
      "/" +
      installation_date_array[1] +
      "/" +
      installation_date_array[0];
    let last_cleaning_date_array = lastCleaningDate.split("-");
    let last_cleaning_date =
      last_cleaning_date_array[2] +
      "/" +
      last_cleaning_date_array[1] +
      "/" +
      last_cleaning_date_array[0];
    let machine_data = {
      machine_id: props.machine.machine_id,
      address: address,
      installation_date: installation_date,
      tlf: tlf,
      contact_person: contactPerson,
      alias: alias,
      observations: observations,
      is_test_machine: isTestMachine,
      sector: sector,
      last_cleaning_date: last_cleaning_date,
    };
    if (props.isDistributorAdmin) {
      machine_data["group"] = group;
      machine_data["distributor"] = distributor;
    }
    props.updateSelectedMachine(props.selectedRow);
    props.updateSearchInput(props.searchInput);
    props.updateMachine(machine_data);
    props.handleClose();
  };

  const handlePreviousModalClose = () => {
    resetStateToDefault();
    setShowSureToCancelModal(false);
    props.handleClose();
  };

  React.useEffect(() => {
    if (props.machine === null) {
      resetStateToDefault();
    } else {
      setNewState();
    }
  }, [props.machine, props.open, resetStateToDefault, setNewState]);

  let distributors = {};
  let groups = {};
  let group_name = "";
  let distributor_name = "";
  if (props.isDistributorAdmin) {
    Object.keys(props.distributorsWithGroups).forEach(
      (key) => (distributors[key] = props.distributorsWithGroups[key].name)
    );

    if (
      distributor !== -1 &&
      Object.keys(props.distributorsWithGroups).length > 0
    ) {
      Object.keys(props.distributorsWithGroups[distributor].groups).forEach(
        (key) => {
          if (
            props.distributorsWithGroups[distributor].groups[key].is_default
          ) {
            groups[key] = "-";
          } else {
            groups[key] =
              props.distributorsWithGroups[distributor].groups[key].name;
          }
        }
      );
    }
  } else {
    if (
      props.machine !== null &&
      Object.keys(props.distributorsWithGroups).length > 0
    ) {
      distributor_name =
        props.distributorsWithGroups[props.machine.distributor].name;
      if (
        props.distributorsWithGroups[props.machine.distributor].groups[
          props.machine.group
        ].is_default
      ) {
        group_name = "-";
      } else {
        group_name =
          props.distributorsWithGroups[props.machine.distributor].groups[
            props.machine.group
          ].name;
      }
    }
  }

  let machineSectors = props.machineSectors;
  machineSectors["-1"] = "";

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick={true}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justify="flex-end"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("machinesView.machinesModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                disabled
                className={classes.textInput}
                label={t("machinesView.machinesModal.ckserial")}
                value={ckserial}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                disabled
                label={t("machinesView.machinesModal.model")}
                value={model}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {props.isDistributorAdmin && (
                <DropdownInput
                  className={classes.textInput}
                  label={t("machinesView.machinesModal.distributor")}
                  value={distributor.toString()}
                  options={distributors}
                  onChange={(value) => {
                    setDistributor(value);
                    setGroup(
                      Object.keys(props.distributorsWithGroups[value].groups)[0]
                    );
                    setHasChanges(true);
                  }}
                />
              )}
              {!props.isDistributorAdmin && (
                <TextInput
                  disabled
                  className={classes.textInput}
                  label={t("machinesView.machinesModal.distributor")}
                  value={distributor_name}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {props.isDistributorAdmin && (
                <DropdownInput
                  className={classes.textInput}
                  label={t("machinesView.machinesModal.group")}
                  value={group.toString()}
                  options={groups}
                  onChange={(value) => {
                    setGroup(value);
                    setHasChanges(true);
                  }}
                />
              )}
              {!props.isDistributorAdmin && (
                <TextInput
                  disabled
                  className={classes.textInput}
                  label={t("machinesView.machinesModal.group")}
                  value={group_name}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("machinesView.machinesModal.alias")}
                value={alias}
                onChange={(value) => {
                  setHasChanges(true);
                  setAlias(value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                disabled={
                  !props.isDistributorAdmin &&
                  props.machine !== null &&
                  props.machine.installation_date !== null
                }
                className={classes.textInput}
                type="date"
                label={t("machinesView.machinesModal.installationDate")}
                value={installationDate}
                onChange={(value) => {
                  if (
                    props.isDistributorAdmin ||
                    new Date() >= new Date(value)
                  ) {
                    setHasChanges(true);
                    setInstallationDate(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("machinesView.machinesModal.tlf")}
                value={contactPerson}
                onChange={(value) => {
                  setHasChanges(true);
                  setContactPerson(value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("machinesView.machinesModal.contactPerson")}
                value={tlf}
                onChange={(value) => {
                  setHasChanges(true);
                  setTlf(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.addressField}
                type="date"
                label={t("machinesView.machinesModal.lastCleaningDate")}
                value={lastCleaningDate}
                onChange={(value) => {
                  if (
                    props.isDistributorAdmin ||
                    new Date() <= new Date(value)
                  ) {
                    setHasChanges(true);
                    setLastCleaningDate(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.addressField}
                label={t("machinesView.machinesModal.address")}
                value={address}
                onChange={(value) => {
                  setHasChanges(true);
                  setAddress(value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownInput
                className={classes.textInput}
                label={t("machinesView.machinesModal.sector")}
                sorted
                value={sector.toString()}
                options={machineSectors}
                onChange={(value) => {
                  setSector(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                className={classes.testMachineCheckbox}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={isTestMachine}
                    onChange={(value) => {
                      setHasChanges(true);
                      setIsTestMachine(value.target.checked);
                    }}
                  />
                }
                label={t("machinesView.machinesModal.testMachine")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.addressField}
                label={t("machinesView.machinesModal.observations")}
                value={observations}
                multiline
                rowsMax={5}
                onChange={(value) => {
                  setHasChanges(true);
                  setObservations(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Container className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("machinesView.machinesModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("machinesView.machinesModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
    </div>
  );
};

export default MachinesModal;
