import React from "react";

import LoginHeader from "./LoginHeader";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordFormSuccessful from "./ResetPasswordFormSuccessful";
import ResetPasswordConfirm from "./ResetPasswordConfirm";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import {
  mapStateToPropsLogin,
  mapDispatchToPropsLogin,
} from "../utils/redux/mappers/login_mappers";

import { useTranslation } from "react-i18next";
import { Route, Redirect, Switch } from "react-router";
import ResetPasswordConfirmSuccessful from "./ResetPasswordConfirmSuccessful";

const useStyles = makeStyles((theme) => ({
  loginScreen: {
    backgroundColor: "rgb(220, 220, 220)",
    height: "100vh",
  },
  loginContainer: {
    width: "450px",
    backgroundColor: "#9D9D9D",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "600px",
    },
  },
}));

const LoginScreen = (props) => {
  const classes = useStyles();
  const {
    state,
    onUserInputChange,
    onPasswordInputChange,
    onRememberMeCheckBoxChange,
    onLoginClick,
  } = props;

  const { t } = useTranslation("login");
  return (
    <div className={classes.loginScreen}>
      <div className={classes.loginContainer}>
        <Grid container spacing={0} alignItems="flex-end" justify="center">
          <LoginHeader />
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <LoginForm
                  errorMsg={state.error_msg}
                  userValue={state.user}
                  passwdValue={state.passwd}
                  rememberMeValue={state.remember_me}
                  onUserInputChange={onUserInputChange}
                  onPasswordInputChange={onPasswordInputChange}
                  onRememberMeCheckBoxChange={onRememberMeCheckBoxChange}
                  onLoginClick={() => {
                    props.clear_error_msg();
                    onLoginClick(
                      state.user,
                      state.passwd,
                      state.remember_me,
                      (string) => t(string)
                    );
                  }}
                />
              )}
            />
            <Route path="/reset" component={ResetPasswordForm} />
            <Route
              path="/reset_successful"
              component={ResetPasswordFormSuccessful}
            />
            <Route
              path="/reset_confirm/:uidb64/:token"
              component={ResetPasswordConfirm}
            />
            <Route
              path="/reset_confirm_successful"
              component={ResetPasswordConfirmSuccessful}
            />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Grid>
      </div>
    </div>
  );
};

export default connect(
  mapStateToPropsLogin,
  mapDispatchToPropsLogin
)(LoginScreen);
