import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import NavigationBar from "./NavigationBar";
import ContentWindow from "./ContentWindow";
import AdministrationBar from "./AdministrationBar";
import Loading from "../utils/components/Loading";

import { connect } from "react-redux";

import {
  mapStateToPropsPredik,
  mapDispatchToPropsPredik,
} from "../utils/redux/mappers/predik_mappers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
  },
}));

const get_current_view = (path) => {
  if (path === "/") {
    return "Dashboard";
  } else if (path === "/machine_list") {
    return "Machines List";
  } else if (path === "/scoring") {
    return "Scoring";
  } else if (path === "/predictive") {
    return "Predictive";
  } else {
    return "No Title";
  }
};

const Predik = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hasFetchStarted, setHasFetchStarted] = React.useState(false);
  const [hasShownLoading, setHasShownLoading] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (isWidthUp("sm", props.width)) {
      handleDrawerOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth_token = props.auth_token;
  const get_user_profile = props.get_user_profile;
  const get_distributors_with_groups = props.get_distributors_with_groups;
  const get_distributor_contract_plan = props.get_distributor_contract_plan;
  const get_alerts = props.get_alerts;
  const get_alert_types = props.get_alert_types;
  const get_alerts_hash = props.get_alerts_hash;
  const get_alert_levels = props.get_alert_levels;
  const get_machines = props.get_machines;
  const get_model_history = props.get_model_history;
  const get_devices_extra_info = props.get_devices_extra_info;
  const get_active_machine_sectors = props.get_active_machine_sectors;

  React.useEffect(() => {
    get_user_profile(auth_token);
  }, [get_user_profile, auth_token]);

  React.useEffect(() => {
    get_distributors_with_groups(auth_token);
  }, [get_distributors_with_groups, auth_token]);

  React.useEffect(() => {
    get_alerts(auth_token);
  }, [get_alerts, auth_token]);

  React.useEffect(() => {
    get_alert_types(auth_token);
  }, [get_alert_types, auth_token]);

  React.useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      get_alerts_hash(auth_token, props.user_profile.session_key);
    }, 10000);
    return () => clearInterval(interval);
  }, [get_alerts_hash, props.user_profile, auth_token]);

  React.useEffect(() => {
    get_machines(auth_token);
  }, [get_machines, auth_token]);
  React.useEffect(() => {
    get_active_machine_sectors(auth_token);
  }, [get_active_machine_sectors, auth_token]);

  React.useEffect(() => {
    get_distributor_contract_plan(auth_token);
  }, [get_distributor_contract_plan, auth_token]);

  React.useEffect(() => {
    get_alert_levels(auth_token);
  }, [get_alert_levels, auth_token]);

  React.useEffect(() => {
    get_model_history(auth_token);
  }, [get_model_history, auth_token]);

  React.useEffect(() => {
    get_devices_extra_info(auth_token);
  }, [get_devices_extra_info, auth_token]);

  const view_path = get_current_view(props.view_path);

  if (!hasFetchStarted && props.loading_fetch_call) {
    setHasFetchStarted(true);
  } else if (props.loading_fetch_call && !hasShownLoading) {
    return <Loading />;
  } else if (!props.loading_fetch_call && !hasShownLoading && hasFetchStarted) {
    setHasShownLoading(true);
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AdministrationBar
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          view_path={view_path}
          numberOfOpenAlerts={props.alerts.alerts.length}
          distributors_with_groups={props.distributors_with_groups}
          selected_distributor={props.selected_distributor}
          selected_group={props.selected_group}
          alerts_hash={props.alerts_hash}
          on_select_distributor_change={props.on_select_distributor_change}
          on_select_group_change={props.on_select_group_change}
          user_profile={props.user_profile}
          on_logout_click={props.on_logout_click}
        />
        <NavigationBar
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          view_path={view_path}
          auth_token={props.auth_token}
          get_alerts={props.get_alerts}
          update_first_alerts_hash={props.update_first_alerts_hash}
          alerts_hash={props.alerts_hash}
          get_first_alerts_hash={props.get_first_alerts_hash}
          first_alerts_hash={props.first_alerts_hash}
          get_alerts_hash={props.get_alerts_hash}
          get_machines={props.get_machines}
          distributor_contract_plan={props.distributor_contract_plan}
          isDistributorAdmin={props.distributor_contract_plan.is_admin}
        />
        <ContentWindow viewPath={view_path} alertLevels={props.alert_levels} />
      </div>
    );
  }
};

export default withWidth()(
  connect(mapStateToPropsPredik, mapDispatchToPropsPredik)(Predik)
);
