import React from "react";

import CashKeeper from "../media/images/cashkeeper.png";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  cashKeeperLogo: {
    width: "80%",
    marginTop: "3em",
  },
  logoText: {
    fontFamily: "arial",
    color: "white",
    fontSize: "1.2em",
    fontWeight: "bold",
    fontStyle: "italic",
  },
}));

/**
 *  Base dropdown component for all the dropdowns of **Predik** application.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
const LoginHeader = () => {
  const { t } = useTranslation("login");
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item>
        <img
          className={classes.cashKeeperLogo}
          src={CashKeeper}
          alt="fireSpot"
        />
      </Grid>
      <Grid item>
        <p className={classes.logoText}>{t("Cashkeeper login header")}</p>
      </Grid>
    </React.Fragment>
  );
};

export default LoginHeader;
