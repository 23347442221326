import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

import { useTranslation } from "react-i18next";
import {
  convert_to_dict,
  is_type,
  add_reset,
  update_reset,
  remove_reset,
  are_reset_filled,
  process_resets,
} from "../../utils/js/heuristic_functions";

import Close from "@material-ui/icons/Close";
import TextInput from "../../utils/components/TextInput";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";
import DropdownInput from "../../utils/components/DropdownInput";
import HeuristicDirect from "../../utils/components/HeuristicDirect";
import HeuristicRepeated from "../../utils/components/HeuristicRepeated";
import HeuristicCounter from "../../utils/components/HeuristicCounter";
import HeuristicTemporary from "../../utils/components/HeuristicTemporary";
import HeuristicError from "../../utils/components/HeuristicError";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "1200px",
    height: "75%",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  textInput: {
    width: "100%",
  },
}));

const ManualHeuristicModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [name, setName] = React.useState("");
  const [type, setType] = React.useState(null);
  const [device, setDevice] = React.useState(null);
  const [relatedEvent, setRelatedEvent] = React.useState(null);
  const [counter, setCounter] = React.useState(null);
  const [level, setLevel] = React.useState(null);
  const [stateDescription, setStateDescription] = React.useState(null);
  const [relationalOperator, setRelationalOperator] = React.useState(null);
  const [times, setTimes] = React.useState("1");
  const [timeInterval, setTimeInterval] = React.useState("1");
  const [timeUnit, setTimeUnit] = React.useState(null);
  const [value, setValue] = React.useState("0");
  const [numberOperations, setNumberOperations] = React.useState("1");
  const [resetsToShow, setResetsToShow] = React.useState([]);
  const [resetsToDelete, setResetsToDelete] = React.useState([]);
  const [resetText, setResetText] = React.useState(
    t("manualHeuristic.manualHeuristicModal.addReset")
  );
  const [hasChanges, setHasChanges] = React.useState(false);
  const [showNotAllFieldsFilledModal, setShowNotAllFieldsFilledModal] =
    React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);

  const setNewState = React.useCallback(
    (heuristic_rule_data) => {
      setName(heuristic_rule_data.name);
      setType(heuristic_rule_data.type);
      setDevice(heuristic_rule_data.device);
      if (heuristic_rule_data.event !== null) {
        setRelatedEvent(heuristic_rule_data.event);
      }
      if (heuristic_rule_data.counter !== null) {
        setCounter(heuristic_rule_data.counter);
      }
      setLevel(heuristic_rule_data.level);
      setStateDescription(heuristic_rule_data.state_description);
      if (heuristic_rule_data.relational_operator !== null) {
        setRelationalOperator(heuristic_rule_data.relational_operator);
      }
      if (heuristic_rule_data.times !== null) {
        setTimes(heuristic_rule_data.times);
      }
      if (heuristic_rule_data.time_interval !== null) {
        setTimeInterval(heuristic_rule_data.time_interval);
      }
      if (heuristic_rule_data.time_unit !== null) {
        setTimeUnit(heuristic_rule_data.time_unit);
      }
      if (heuristic_rule_data.value !== null) {
        setValue(heuristic_rule_data.value);
      }
      if (heuristic_rule_data.number_operations !== null) {
        setNumberOperations(heuristic_rule_data.number_operations);
      }
      setResetsToShow(heuristic_rule_data.resets);
      if (heuristic_rule_data.resets.length > 0) {
        setResetText(t("manualHeuristic.manualHeuristicModal.or"));
      }
    },
    [t]
  );

  const resetStateToDefault = React.useCallback(() => {
    setName("");
    setType(parseInt(Object.keys(props.heuristicTypes)[0]));
    setDevice(parseInt(Object.keys(props.devicesExtraInfo)[0]));
    setRelatedEvent(
      parseInt(
        Object.keys(
          props.devicesExtraInfo[Object.keys(props.devicesExtraInfo)[0]][
            "events"
          ]
        )[0]
      )
    );
    setCounter(
      parseInt(
        Object.keys(
          props.devicesExtraInfo[Object.keys(props.devicesExtraInfo)[0]][
            "counters"
          ]
        )[0]
      )
    );
    setTimeUnit(parseInt(Object.keys(props.timeUnits)[0]));
    setLevel(parseInt(Object.keys(props.alertLevels)[0]));
    setStateDescription(parseInt(Object.keys(props.stateDescriptions)[0]));
    setRelationalOperator(parseInt(Object.keys(props.relationalOperators)[0]));
    setTimes("1");
    setTimeInterval("1");
    setResetsToShow([]);
    setResetsToDelete([]);
    setValue("0");
    setNumberOperations("1");
    setResetText(t("manualHeuristic.manualHeuristicModal.addReset"));
    setHasChanges(false);
  }, [t, props]);

  const are_all_fields_filled = () => {
    let filled =
      name.length !== 0 && are_reset_filled(resetsToShow, props.heuristicTypes);
    switch (heuristic_types[type].name) {
      case "Repeated":
        filled = filled && times.length !== 0 && timeInterval.length !== 0;
        break;
      case "Counter value":
        filled = filled && value.length !== 0;
        break;
      case "Temporary":
        filled = filled && timeInterval.length !== 0;
        break;
      case "Operations without error":
        filled = filled && numberOperations.length !== 0;
        break;
      default:
        break;
    }
    return filled;
  };

  const handleAcceptButton = () => {
    if (are_all_fields_filled()) {
      let heuristic_rule_data = {
        name: name,
        type: type,
        device: device,
        level: level,
        state_description: stateDescription,
        resets: process_resets(resetsToShow, heuristic_reset_types),
        resets_to_delete: resetsToDelete,
      };
      switch (heuristic_types[type].name) {
        case "Repeated":
          heuristic_rule_data["event"] = relatedEvent;
          heuristic_rule_data["times"] = parseInt(times);
          heuristic_rule_data["time_interval"] = parseInt(timeInterval);
          heuristic_rule_data["time_unit"] = timeUnit;
          break;
        case "Direct":
          heuristic_rule_data["event"] = relatedEvent;
          break;
        case "Counter value":
          heuristic_rule_data["counter"] = counter;
          heuristic_rule_data["relational_operator"] = relationalOperator;
          heuristic_rule_data["value"] = parseInt(value);
          break;
        case "Temporary":
          heuristic_rule_data["time_interval"] = parseInt(timeInterval);
          heuristic_rule_data["time_unit"] = timeUnit;
          break;
        case "Operations without error":
          heuristic_rule_data["number_operations"] = parseInt(numberOperations);
          break;
        default:
          break;
      }
      if (props.manualHeuristicData !== null) {
        heuristic_rule_data["id"] = props.manualHeuristicData.id;
      }
      props.createOrUpdateHeuristicRule(heuristic_rule_data);
      resetStateToDefault();
      props.handleClose();
    } else {
      setShowNotAllFieldsFilledModal(true);
    }
  };

  const handleCancelButton = () => {
    if (!hasChanges) {
      resetStateToDefault();
      props.handleClose();
    } else {
      setShowSureToCancelModal(true);
    }
  };

  const handlePreviousModalClose = () => {
    setShowSureToCancelModal(false);
    resetStateToDefault();
    props.handleClose();
  };

  let heuristic_rule_data = props.manualHeuristicData;
  let devices_extra_info = props.devicesExtraInfo;
  let heuristic_types = {};
  let heuristic_reset_types = {};
  let alert_levels = props.alertLevels;
  let time_units = props.timeUnits;
  let state_descriptions = props.stateDescriptions;
  let relational_operators = props.relationalOperators;

  React.useEffect(() => {
    if (
      Object.keys(devices_extra_info).length > 0 &&
      Object.keys(props.heuristicTypes).length > 0 &&
      Object.keys(alert_levels).length > 0 &&
      Object.keys(time_units).length > 0 &&
      Object.keys(state_descriptions).length > 0 &&
      Object.keys(relational_operators).length > 0 &&
      !hasChanges &&
      props.open &&
      heuristic_rule_data === null
    ) {
      resetStateToDefault();
    }
  }, [
    devices_extra_info,
    props.heuristicTypes,
    alert_levels,
    time_units,
    state_descriptions,
    relational_operators,
    hasChanges,
    resetStateToDefault,
    heuristic_rule_data,
    props.open,
  ]);

  React.useEffect(() => {
    if (heuristic_rule_data !== null) {
      setNewState(heuristic_rule_data);
    }
  }, [heuristic_rule_data, setNewState, props.open]);

  if (Object.keys(props.heuristicTypes).length > 0) {
    Object.keys(props.heuristicTypes).forEach((key) => {
      switch (props.heuristicTypes[key].show) {
        case "A":
          heuristic_types[key] = props.heuristicTypes[key];
          heuristic_reset_types[key] = props.heuristicTypes[key];
          break;
        case "M":
          heuristic_types[key] = props.heuristicTypes[key];
          break;
        case "R":
          heuristic_reset_types[key] = props.heuristicTypes[key];
          break;
        default:
          break;
      }
    });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick={true}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("manualHeuristic.manualHeuristicModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <DropdownInput
                className={classes.textInput}
                label={t("manualHeuristic.manualHeuristicModal.type")}
                sorted={false}
                value={type}
                options={convert_to_dict(heuristic_types)}
                onChange={(value) => {
                  setType(parseInt(value));
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                className={classes.textInput}
                label={t("manualHeuristic.manualHeuristicModal.name")}
                value={name}
                onChange={(value) => {
                  setName(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            {is_type(type, "Direct", heuristic_types) && (
              <HeuristicDirect
                alertLevels={alert_levels}
                device={device}
                devicesExtraInfo={devices_extra_info}
                isReset={false}
                level={level}
                relatedEvent={relatedEvent}
                stateDescription={stateDescription}
                stateDescriptions={state_descriptions}
                setDevice={setDevice}
                setRelatedEvent={setRelatedEvent}
                setCounter={setCounter}
                setLevel={setLevel}
                setStateDescription={setStateDescription}
                setHasChanges={setHasChanges}
              />
            )}
            {is_type(type, "Repeated", heuristic_types) && (
              <HeuristicRepeated
                alertLevels={alert_levels}
                device={device}
                devicesExtraInfo={devices_extra_info}
                isReset={false}
                level={level}
                relatedEvent={relatedEvent}
                stateDescription={stateDescription}
                stateDescriptions={state_descriptions}
                times={times}
                timeInterval={timeInterval}
                timeUnit={timeUnit}
                timeUnits={time_units}
                setDevice={setDevice}
                setRelatedEvent={setRelatedEvent}
                setCounter={setCounter}
                setLevel={setLevel}
                setStateDescription={setStateDescription}
                setTimes={setTimes}
                setTimeInterval={setTimeInterval}
                setTimeUnit={setTimeUnit}
                setHasChanges={setHasChanges}
              />
            )}
            {is_type(type, "Counter value", heuristic_types) && (
              <HeuristicCounter
                alertLevels={alert_levels}
                counter={counter}
                device={device}
                devicesExtraInfo={devices_extra_info}
                isReset={false}
                level={level}
                relationalOperator={relationalOperator}
                relationalOperators={relational_operators}
                stateDescription={stateDescription}
                stateDescriptions={state_descriptions}
                setDevice={setDevice}
                setRelatedEvent={setRelatedEvent}
                setCounter={setCounter}
                setLevel={setLevel}
                setStateDescription={setStateDescription}
                setRelationalOperator={setRelationalOperator}
                setValue={setValue}
                setHasChanges={setHasChanges}
                value={value}
              />
            )}
            {is_type(type, "Temporary", heuristic_types) && (
              <HeuristicTemporary
                alertLevels={alert_levels}
                device={device}
                devicesExtraInfo={devices_extra_info}
                isReset={false}
                level={level}
                stateDescription={stateDescription}
                stateDescriptions={state_descriptions}
                timeInterval={timeInterval}
                timeUnit={timeUnit}
                timeUnits={time_units}
                setTimeInterval={setTimeInterval}
                setTimeUnit={setTimeUnit}
                setLevel={setLevel}
                setDevice={setDevice}
                setRelatedEvent={setRelatedEvent}
                setCounter={setCounter}
                setStateDescription={setStateDescription}
                setValue={setValue}
                setHasChanges={setHasChanges}
                value={value}
              />
            )}
            {is_type(type, "Operations without error", heuristic_types) && (
              <HeuristicError
                alertLevels={alert_levels}
                device={device}
                devicesExtraInfo={devices_extra_info}
                isReset={false}
                level={level}
                numberOperations={numberOperations}
                stateDescription={stateDescription}
                stateDescriptions={state_descriptions}
                setDevice={setDevice}
                setRelatedEvent={setRelatedEvent}
                setCounter={setCounter}
                setLevel={setLevel}
                setStateDescription={setStateDescription}
                setNumberOperations={setNumberOperations}
                setHasChanges={setHasChanges}
              />
            )}
            {resetsToShow.map((reset) => (
              <React.Fragment key={reset.id}>
                <Grid item xs={11}>
                  <h2 className={classes.alignCenter}>
                    {t("manualHeuristic.manualHeuristicModal.resetTitle")}
                  </h2>
                  <Divider />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() =>
                      remove_reset(
                        reset.id,
                        setResetText,
                        setResetsToDelete,
                        setResetsToShow,
                        resetsToShow,
                        resetsToDelete,
                        t
                      )
                    }
                  >
                    <Close />
                  </Button>
                </Grid>
                {is_type(reset.type, "Direct", heuristic_reset_types) && (
                  <HeuristicDirect
                    devicesExtraInfo={devices_extra_info}
                    handleUpdateReset={(id, field, value) =>
                      update_reset(
                        id,
                        field,
                        value,
                        resetsToShow,
                        setResetsToShow,
                        props
                      )
                    }
                    heuristicTypes={heuristic_reset_types}
                    isReset={true}
                    reset={reset}
                    setHasChanges={setHasChanges}
                  />
                )}
                {is_type(reset.type, "Repeated", heuristic_reset_types) && (
                  <HeuristicRepeated
                    devicesExtraInfo={devices_extra_info}
                    handleUpdateReset={(id, field, value) =>
                      update_reset(
                        id,
                        field,
                        value,
                        resetsToShow,
                        setResetsToShow,
                        props
                      )
                    }
                    heuristicTypes={heuristic_reset_types}
                    isReset={true}
                    reset={reset}
                    timeUnits={time_units}
                    setHasChanges={setHasChanges}
                  />
                )}
                {is_type(
                  reset.type,
                  "Counter value",
                  heuristic_reset_types
                ) && (
                  <HeuristicCounter
                    devicesExtraInfo={devices_extra_info}
                    handleUpdateReset={(id, field, value) =>
                      update_reset(
                        id,
                        field,
                        value,
                        resetsToShow,
                        setResetsToShow,
                        props
                      )
                    }
                    heuristicTypes={heuristic_reset_types}
                    isReset={true}
                    reset={reset}
                    relationalOperators={relational_operators}
                    setHasChanges={setHasChanges}
                  />
                )}
                {is_type(reset.type, "Temporary", heuristic_reset_types) && (
                  <HeuristicTemporary
                    devicesExtraInfo={devices_extra_info}
                    handleUpdateReset={(id, field, value) =>
                      update_reset(
                        id,
                        field,
                        value,
                        resetsToShow,
                        setResetsToShow,
                        props
                      )
                    }
                    heuristicTypes={heuristic_reset_types}
                    isReset={true}
                    reset={reset}
                    timeUnits={time_units}
                    setHasChanges={setHasChanges}
                  />
                )}
                {is_type(
                  reset.type,
                  "Operations without error",
                  heuristic_reset_types
                ) && (
                  <HeuristicError
                    devicesExtraInfo={devices_extra_info}
                    handleUpdateReset={(id, field, value) =>
                      update_reset(
                        id,
                        field,
                        value,
                        resetsToShow,
                        setResetsToShow,
                        props
                      )
                    }
                    heuristicTypes={heuristic_reset_types}
                    isReset={true}
                    reset={reset}
                    timeUnits={time_units}
                    setHasChanges={setHasChanges}
                  />
                )}
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Container style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    add_reset(
                      resetsToShow,
                      props,
                      setResetsToShow,
                      setResetText,
                      t
                    );
                    setHasChanges(true);
                  }}
                >
                  {resetText}
                </Button>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("manualHeuristic.manualHeuristicModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("manualHeuristic.manualHeuristicModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
      <InformationModal
        open={showNotAllFieldsFilledModal}
        handleClose={() => setShowNotAllFieldsFilledModal(false)}
        title={t("notAllFieldsFilledModal.title")}
        description={t("notAllFieldsFilledModal.description")}
        buttonDescription={t("notAllFieldsFilledModal.keepEditing")}
      />
    </div>
  );
};

export default ManualHeuristicModal;
