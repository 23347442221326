export const ATTEMPT_TO_LOGIN = "ATTEMPT_TO_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";

export const LOGOUT = "LOGOUT";

export const UPDATING_USER = "UPDATING_USER";
export const UPDATING_PASSWORD = "UPDATING_PASSWORD";
export const UPDATING_REMEMBER_ME = "UPDATING_REMEMBER_ME";

export const ATTEMPT_TO_RESET_PASSWORD = "ATTEMPT_TO_RESET_PASSWORD";
export const ERROR_RESET_PASSWORD = "ERROR_RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESSFUL = "RESET_PASSWORD_SUCCESSFUL";

export const ATTEMPT_TO_RESET_PASSWORD_CONFIRM =
  "ATTEMPT_TO_RESET_PASSWORD_CONFIRM";
export const RESET_PASSWORD_CONFIRM_SUCCESSFUL =
  "RESET_PASSWORD_CONFIRM_SUCCESSFUL";
export const ERROR_RESET_PASSWORD_CONFIRM = "ERROR_RESET_PASSWORD_CONFIRM";

export const CLEAR_LOGIN_ERROR_MSG = "CLEAR_LOGIN_ERROR_MSG";

export const INITIAL_STATE = {
  user: "",
  password: "",
  remember_me: false,
  is_logging_in: false,
  auth_token: "",
  error_msg: "",
  loading_password_change_request: false,
  successful_return_password_change_request: false,
  loading_password_change_confirm_request: false,
  successful_return_password_change_confirm_request: false,
};
