import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Close from "@material-ui/icons/Close";

import TextInput from "../../utils/components/TextInput";
import AcceptActionModal from "../../utils/components/AcceptActionModal";
import InformationModal from "../../utils/components/InformationModal";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import SearchField from "../../utils/components/SearchField";

import { useTranslation } from "react-i18next";

import { dict_contains_search_input } from "../../utils/js/common_functions";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "1040px",
    height: "90%",
    maxHeight: "900px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  groupsModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  groupNameInput: {
    marginLeft: "0px",
    width: "100%",
  },
  selectAll: {
    float: "left",
  },
  linkMachinesTitle: {
    fontWeight: "bold",
    float: "left",
    margin: "0px",
    fontSize: "16px",
  },
  groupsModalTable: {
    height: "400px",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  alignRight: {
    textAlign: "right",
  },
  closeButton: {
    padding: "8px 8px 0px 0px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  button: {
    margin: theme.spacing(1),
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 40px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
}));

const generateDictStatusOfAllMachines = (machines, status) => {
  let machines_dict = {};
  for (let i = 0; i < machines.length; ++i) {
    machines_dict[machines[i].machine_id] = status;
  }
  return machines_dict;
};

const generateDictOfSelectedMachines = (machines, group_id) => {
  let machines_dict = {};
  for (let i = 0; i < machines.length; ++i) {
    machines_dict[machines[i].machine_id] = machines[i].group === group_id;
  }
  return machines_dict;
};

const filter_machines_from_dist = (machine, dist) => {
  return machine.distributor.toString() === dist.toString();
};

const GroupsModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [groupName, setGroupName] = React.useState("");
  const [distributor, setDistributor] = React.useState(
    props.distributor_contract_plan.id
  );
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedElements, setSelectedElements] = React.useState({});
  const [searchInput, setSearchInput] = React.useState("");
  const [hasChanges, setHasChanges] = React.useState(false);
  const [showSureToCancelModal, setShowSureToCancelModal] =
    React.useState(false);
  const [showNoNameFilledModal, setShowNoNameFilledModal] =
    React.useState(false);

  const changeMachineState = (machine_id, new_state) => {
    let new_selected_elements_dict = { ...selectedElements };
    new_selected_elements_dict[machine_id] = new_state;
    setSelectedElements(new_selected_elements_dict);
  };

  const setNewState = React.useCallback(
    (group_data) => {
      setGroupName(group_data.name);
      if (group_data !== undefined) {
        setDistributor(group_data.distributor);
      } else {
        setDistributor(props.distributor_contract_plan.id);
      }
      setSelectedElements(
        generateDictOfSelectedMachines(
          props.machines.filter((machine) =>
            filter_machines_from_dist(machine, group_data.distributor)
          ),
          props.group.id
        )
      );
    },
    [props.group, props.machines, props.distributor_contract_plan.id]
  );

  const resetStateToDefault = React.useCallback(() => {
    setGroupName("");
    setDistributor(props.distributor_contract_plan.id);
    setSelectAll(false);
    setSelectedElements({});
    setHasChanges(false);
  }, [props.distributor_contract_plan.id]);

  const group_data = props.group;
  const distributors = props.distributors;

  React.useEffect(() => {
    if (group_data !== null) {
      setNewState(group_data);
    } else {
      resetStateToDefault();
    }
  }, [group_data, distributors, setNewState, resetStateToDefault]);

  let machines = props.machines;
  if (distributor !== undefined && distributor !== null) {
    machines = machines.filter((machine) =>
      filter_machines_from_dist(machine, distributor)
    );
  }
  machines = machines.filter((machine) =>
    dict_contains_search_input(machine, searchInput)
  );
  machines = machines.filter((machine) => machine.is_active);

  let distributors_dict = {};
  if (distributors != null) {
    distributors.forEach((item, i) => {
      distributors_dict[item["id"]] = distributors[i]["name"];
    });
  }

  const handleAcceptButton = () => {
    if (groupName.length === 0) {
      setShowNoNameFilledModal(true);
    } else {
      let linked_machines = [];
      for (let i = 0; i < machines.length; ++i) {
        if (
          machines[i].machine_id in selectedElements &&
          selectedElements[machines[i].machine_id]
        ) {
          linked_machines.push(machines[i].machine_id);
        }
      }

      let group_data = {};
      if (props.group != null) {
        group_data["id"] = props.group.id;
      }
      group_data["name"] = groupName;
      group_data["distributor"] = distributor;
      group_data["linked_machines"] = linked_machines;

      props.createOrUpdateGroup(group_data);
      resetStateToDefault();
      props.handleClose();
    }
  };

  let groupsCount = "";
  let activeGroupsCount = props.groups.filter((dist) => dist.is_active).length;
  if (props.distributor_contract_plan.contract_plan !== null) {
    if (props.distributor_contract_plan.contract_plan.max_groups === -1) {
      groupsCount = t("groupsView.unlimitedGroups");
    } else {
      groupsCount =
        activeGroupsCount +
        t("groupsView.of") +
        (props.distributor_contract_plan.contract_plan.max_groups +
          props.distributor_contract_plan.extra_groups) +
        t("groupsView.groups");
    }
  }

  const handleCancelButton = () => {
    if (hasChanges) {
      setShowSureToCancelModal(true);
    } else {
      resetStateToDefault();
      props.handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleCancelButton}
        className={classes.groupsModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("groupsView.groupsModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextInput
                className={classes.groupNameInput}
                label={t("groupsView.groupsModal.groupNameInput")}
                value={groupName}
                onChange={(value) => {
                  setHasChanges(true);
                  setGroupName(value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <h3 className={classes.alignRight}>{groupsCount}</h3>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.linkMachinesTitle}>
                {t("groupsView.groupsModal.linkMachines")}
              </p>
            </Grid>
            <Grid item xs={12}>
              <SearchField value={searchInput} onChange={setSearchInput} />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className={classes.selectAll}
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={(event) => {
                      setSelectAll(event.target.checked);
                      setSelectedElements(
                        generateDictStatusOfAllMachines(
                          machines,
                          event.target.checked
                        )
                      );
                    }}
                  />
                }
                label={t("groupsView.groupsModal.selectAll")}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactTable
                data={machines}
                columns={[
                  {
                    Header: null,
                    width: 55,
                    Cell: ({ row }) => (
                      <Checkbox
                        checked={selectedElements[row._original.machine_id]}
                        onChange={(value) => {
                          setHasChanges(true);
                          changeMachineState(
                            row._original.machine_id,
                            value.target.checked
                          );
                        }}
                      />
                    ),
                  },
                  {
                    Header: t("groupsView.groupsModal.ckserial"),
                    accessor: "ckserial",
                  },
                  {
                    Header: t("groupsView.groupsModal.model"),
                    accessor: "model",
                  },
                  {
                    Header: t("groupsView.groupsModal.instalationDate"),
                    accessor: "installation_date",
                  },
                  {
                    Header: t("groupsView.groupsModal.address"),
                    accessor: "address",
                  },
                ]}
                defaultPageSize={10}
                className="linkTable"
                showPageSizeOptions={false}
                previousText={t("tableTags.previous")}
                nextText={t("tableTags.next")}
                loadingText={t("tableTags.loading")}
                noDataText={t("tableTags.noData")}
                pageText={t("tableTags.page")}
                ofText={t("tableTags.of")}
                rowsText={t("tableTags.rows")}
              />
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("groupsView.groupsModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("groupsView.groupsModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={showSureToCancelModal}
        handleClose={() => setShowSureToCancelModal(false)}
        handleAcceptButton={() => {
          setShowSureToCancelModal(false);
          setHasChanges(false);
          props.handleClose();
        }}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
      <InformationModal
        open={showNoNameFilledModal}
        handleClose={() => setShowNoNameFilledModal(false)}
        title={t("noNameFilledModal.title")}
        description={t("noNameFilledModal.description")}
        buttonDescription={t("noNameFilledModal.keepEditing")}
      />
    </div>
  );
};

export default GroupsModal;
