import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";

import TextInput from "../utils/components/TextInput";
import DropdownInput from "../utils/components/DropdownInput";
import AcceptActionModal from "../utils/components/AcceptActionModal";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { languages } from "../utils/js/languages";

import {
  mapStateToPropsPersonalUserProfile,
  mapDispatchToPropsPersonalUserProfile,
} from "../utils/redux/mappers/general_mappers";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "720px",
    height: "90%",
    maxHeight: "480px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  userProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  closeButton: {
    padding: "8px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 20px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 50px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  buttonsContainer: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  textInput: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const UserProfileModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [first_name, setFirstName] = React.useState(
    props.user_profile.first_name
  );
  const [last_name, setLastName] = React.useState(props.user_profile.last_name);
  const [language, setLanguage] = React.useState(props.user_profile.language);
  const [email, setEmail] = React.useState(props.user_profile.email);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [sureToCancelModalState, setSureToCancelModalState] =
    React.useState(false);

  React.useEffect(() => {
    setFirstName(props.user_profile.first_name);
  }, [props.user_profile.first_name]);
  React.useEffect(() => {
    setLastName(props.user_profile.last_name);
  }, [props.user_profile.last_name]);
  React.useEffect(() => {
    setLanguage(props.user_profile.language);
  }, [props.user_profile.language]);
  React.useEffect(() => {
    setEmail(props.user_profile.email);
  }, [props.user_profile.email]);

  const handleAcceptButton = () => {
    let user_profile = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      language: language,
    };
    props.update_user_profile(user_profile, props.auth_token);
    setHasChanges(false);
    props.handleClose();
  };

  const handleCancelButton = () => {
    if (!hasChanges) {
      props.handleClose();
    } else {
      setSureToCancelModalState(true);
    }
  };

  const handlePreviousModalClose = () => {
    setHasChanges(false);
    setSureToCancelModalState(false);
    props.handleClose();
  };

  let distributor = "";
  if (props.distributors_with_groups[props.user_profile.distributor] != null) {
    distributor =
      props.distributors_with_groups[props.user_profile.distributor].name;
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.userProfile}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCancelButton}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="center"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("userProfileModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                disabled
                className={classes.textInput}
                label={t("userProfileModal.username")}
                value={props.user_profile.username}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                disabled
                className={classes.textInput}
                label={t("userProfileModal.distributor")}
                value={distributor}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("userProfileModal.firstName")}
                value={first_name}
                onChange={(value) => {
                  setFirstName(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("userProfileModal.lastName")}
                value={last_name}
                onChange={(value) => {
                  setLastName(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownInput
                className={classes.textInput}
                label={t("userProfileModal.language")}
                value={language}
                options={languages}
                onChange={(value) => {
                  setLanguage(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("userProfileModal.email")}
                value={email}
                onChange={(value) => {
                  setEmail(value);
                  setHasChanges(true);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Container className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAcceptButton}
                >
                  {t("userProfileModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancelButton}
                >
                  {t("userProfileModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <AcceptActionModal
        open={sureToCancelModalState}
        handleClose={() => setSureToCancelModalState(false)}
        handleAcceptButton={handlePreviousModalClose}
        title={t("sureToCancelModal.title")}
        description={t("sureToCancelModal.description")}
        acceptButtonDescription={t("sureToCancelModal.exit")}
        cancelButtonDescription={t("sureToCancelModal.keepEditing")}
      />
    </div>
  );
};

export default connect(
  mapStateToPropsPersonalUserProfile,
  mapDispatchToPropsPersonalUserProfile
)(UserProfileModal);
