import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Cancel from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  alertsDiv: {
    border: "2px solid rgb(255, 102, 102)",
    borderRadius: "4px",
    backgroundColor: "rgb(255, 204, 204)",
  },
  icon: {
    width: 30,
    height: 30,
    color: "rgb(255, 102, 102)",
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 20,
      height: 20,
    },
  },
  timestamp: {
    textAlign: "right",
    fontSize: 10,
  },
  idAddress: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "12px",
  },
  errorDescription: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "12px",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const AlertItem = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justify="center"
      className={classes.alertsDiv}
    >
      <Grid item lg={1} xs={1}>
        <Cancel className={classes.icon} />
      </Grid>
      <Grid item lg={11} xs={11}>
        <div className={classes.timestamp}>{props.alert.timestamp}</div>

        <div className={classes.idAddress}>
          <b>{props.machineIDText + ": "}</b>
          <span
            className={classes.link}
            onClick={() =>
              props.changeWindowOnCkserialClick(props.alert.machine_ckserial)
            }
          >
            {props.alert.machine_ckserial}
          </span>
          {" - " +
            props.alert.machine_alias +
            " - " +
            props.alert.machine_address}
        </div>
        <div className={classes.errorDescription}>
          <b>{props.componentText + ": "}</b>
          {props.alert.component_type +
            " - " +
            props.alert.component_serial +
            " - " +
            props.alert.error_desc}
        </div>
      </Grid>
    </Grid>
  );
};

export default AlertItem;
