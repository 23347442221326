import { v4 as uuidv4 } from "uuid";

export const convert_to_dict = (converted) => {
  let dict = {};
  Object.keys(converted).forEach((key) => {
    dict[key] = converted[key].description;
  });
  return dict;
};

export const convert_info_to_dict = (info) => {
  let dict = {};
  Object.keys(info).forEach((key) => {
    dict[key] = key + " - " + info[key].description;
  });
  return dict;
};

export const is_type = (type, name_to_check, heuristic_types) => {
  if (Object.keys(heuristic_types).length > 0 && type !== null) {
    if (heuristic_types[type].name === name_to_check) {
      return true;
    }
  }
  return false;
};

export const add_reset = (
  resetsToShow,
  props,
  setResetsToShow,
  setResetText,
  t
) => {
  setResetsToShow([
    ...resetsToShow,
    {
      id: uuidv4(),
      type: parseInt(Object.keys(props.heuristicTypes)[0]),
      device: parseInt(Object.keys(props.devicesExtraInfo)[0]),
      event: parseInt(
        Object.keys(
          props.devicesExtraInfo[Object.keys(props.devicesExtraInfo)[0]][
            "events"
          ]
        )[0]
      ),
      counter: parseInt(
        Object.keys(
          props.devicesExtraInfo[Object.keys(props.devicesExtraInfo)[0]][
            "counters"
          ]
        )[0]
      ),
      relational_operator: parseInt(Object.keys(props.relationalOperators)[0]),
      times: "1",
      time_interval: "1",
      time_unit: parseInt(Object.keys(props.timeUnits)[0]),
      value: "0",
      number_operations: "1",
    },
  ]);
  setResetText(t("manualHeuristic.manualHeuristicModal.or"));
};

export const update_reset = (
  id,
  field,
  value,
  resetsToShow,
  setResetsToShow,
  props
) => {
  let newResets = resetsToShow.map((reset) => {
    if (reset.id === id) {
      reset[field] = value;
      if (field === "type") {
        switch (props.heuristicTypes[reset.type].name) {
          case "Repeated":
            reset.event = parseInt(
              Object.keys(props.devicesExtraInfo[reset.device]["events"])[0]
            );
            reset.times = "1";
            reset.time_interval = "1";
            reset.time_unit = parseInt(Object.keys(props.timeUnits)[0]);
            break;
          case "Direct":
            reset.event = parseInt(
              Object.keys(props.devicesExtraInfo[reset.device]["events"])[0]
            );
            break;
          case "Counter value":
            reset.counter = parseInt(
              Object.keys(props.devicesExtraInfo[reset.device]["counters"])[0]
            );
            reset.relational_operator = parseInt(
              Object.keys(props.relationalOperators)[0]
            );
            reset.value = "0";
            break;
          case "Temporary":
            reset.time_interval = "1";
            reset.time_unit = parseInt(Object.keys(props.timeUnits)[0]);
            break;
          case "Operations without error":
            reset.number_operations = "1";
            break;
          default:
            break;
        }
      }
    }
    return reset;
  });
  setResetsToShow(newResets);
};

export const remove_reset = (
  id,
  setResetText,
  setResetsToDelete,
  setResetsToShow,
  resetsToShow,
  resetsToDelete,
  t
) => {
  const resets = [...resetsToShow];
  resets.splice(
    resets.findIndex((resets) => resets.id === id),
    1
  );
  if (resets.length === 0) {
    setResetText(t("manualHeuristic.manualHeuristicModal.addReset"));
  }
  setResetsToDelete([...resetsToDelete, { id: id }]);
  setResetsToShow(resets);
};

export const are_reset_filled = (resetsToShow, heuristic_types) => {
  let are_filled = true;
  resetsToShow.map((reset) => {
    switch (heuristic_types[reset.type].name) {
      case "Repeated":
        if (reset["time"].length === 0) {
          are_filled = false;
        }
        if (reset["time_interval"].length === 0) {
          are_filled = false;
        }
        break;
      case "Counter value":
        if (reset["value"].length === 0) {
          are_filled = false;
        }
        break;
      case "Temporary":
        if (reset["time_interval"].length === 0) {
          are_filled = false;
        }
        break;
      case "Operations without error":
        if (reset["number_operations"].length === 0) {
          are_filled = false;
        }
        break;
      default:
        break;
    }
    return reset;
  });
  return are_filled;
};

export const process_resets = (resetsToShow, heuristic_types) => {
  let parsedResets = resetsToShow.map((reset) => {
    let newReset = {
      id: reset.id,
      type: reset.type,
      device: reset.device,
    };
    switch (heuristic_types[reset.type].name) {
      case "Repeated":
        newReset["event"] = reset.event;
        newReset["times"] = parseInt(reset.times);
        newReset["time_interval"] = parseInt(reset.time_interval);
        newReset["time_unit"] = reset.time_unit;
        break;
      case "Direct":
        newReset["event"] = reset.event;
        break;
      case "Counter value":
        newReset["counter"] = reset.counter;
        newReset["relational_operator"] = reset.relational_operator;
        newReset["value"] = reset.value;
        break;
      case "Temporary":
        newReset["time_interval"] = parseInt(reset.time_interval);
        newReset["time_unit"] = reset.time_unit;
        break;
      case "Operations without error":
        newReset["number_operations"] = parseInt(reset.number_operations);
        break;
      default:
        break;
    }
    return newReset;
  });
  return parsedResets;
};
