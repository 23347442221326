import {
  ATTEMPT_TO_GET_USERS,
  ATTEMPT_TO_GET_DISTRIBUTORS,
  ATTEMPT_TO_GET_GROUPS,
  ATTEMPT_TO_GET_CONTRACT_PLANS,
  ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS,
  ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE,
  ATTEMPT_TO_GET_ALERT_CHANGE_TYPES,
  ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES,
  ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES,
  ATTEMPT_TO_GET_DEVICES_EXTRA_INFO,
  ATTEMPT_TO_GET_HEURISTIC_TYPES,
  ATTEMPT_TO_GET_ALERT_LEVELS,
  ATTEMPT_TO_GET_TIME_UNITS,
  ATTEMPT_TO_GET_STATE_DESCRIPTIONS,
  ATTEMPT_TO_GET_RELATIONAL_OPERATORS,
  ATTEMPT_TO_GET_HEURISTIC_RULES,
  REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
  CLEAR_ADMINISTRATION_MODALS_ERROR_MSG,
} from "../actions/administration_modals_action_types";
import {
  get_users,
  get_distributors,
  get_groups,
  get_contract_plans,
  get_non_active_distributors,
  get_non_active_distributor_machines,
  get_alert_change_types,
  get_machine_sectors_types,
  get_all_distributor_machines,
  get_devices_extra_info,
  get_heuristic_types,
  get_alert_levels,
  get_time_units,
  get_state_descriptions,
  get_relational_operators,
  get_heuristic_rules,
  create_or_update_user_profile,
  create_or_update_group,
  create_or_update_alert_change,
  create_or_update_distributor,
  create_or_update_machine_sectors,
  create_or_update_contract_plan,
  create_or_update_heuristic_rule,
  update_distributor_state,
  update_group_state,
  update_alert_change_state,
  update_machine_sectors_state,
  update_machines_state,
  update_user_state,
  update_contract_plan_state,
  update_heuristic_rule_state,
} from "../../api_calls/administration_modals_api_calls";
import { ATTEMPT_TO_RESET_PASSWORD } from "../actions/login_action_types";
import { reset_password } from "../../api_calls/login_api_calls";

export const mapStateToPropsAdministrationModal = (state, ownProps) => {
  let distributors_with_groups = JSON.parse(
    JSON.stringify(state.general.distributors_with_groups)
  );
  if (Object.keys(distributors_with_groups).length > 1) {
    delete distributors_with_groups["0"];
  }

  return {
    auth_token: state.login.auth_token,
    distributors_with_groups: distributors_with_groups,
    machines: state.machines.machines.get_machines(),
    logged_user: state.general.user_profile,

    users: state.administration_modals.users,
    loading_users: state.administration_modals.loading_users,
    loading_user_create_or_update:
      state.administration_modals.loading_user_create_or_update,
    successful_return_user_create_or_update:
      state.administration_modals.successful_return_user_create_or_update,
    loading_password_change_request:
      state.login.loading_password_change_request,
    successful_return_password_change_request:
      state.login.successful_return_password_change_request,

    distributors: state.administration_modals.distributors,
    loading_distributors: state.administration_modals.loading_distributors,
    non_active_distributors:
      state.administration_modals.non_active_distributors,
    non_active_distributor_machines:
      state.administration_modals.non_active_distributor_machines,
    loading_non_active_distributor_machines:
      state.administration_modals.loading_non_active_distributor_machines,

    groups: state.administration_modals.groups,
    loading_groups: state.administration_modals.loading_groups,

    alert_change_types: state.administration_modals.alert_change_types,
    loading_alert_change_types:
      state.administration_modals.loading_alert_change_types,
    new_state_options: state.administration_modals.new_state_options,

    machine_sectors_types: state.administration_modals.machine_sectors_types,
    loading_machine_sectors_types:
      state.administration_modals.loading_machine_sectors_types,

    contract_plans: state.administration_modals.contract_plans,
    loading_contract_plans: state.administration_modals.loading_contract_plans,

    all_distributor_machines:
      state.administration_modals.all_distributor_machines,
    loading_all_distributor_machines:
      state.administration_modals.loading_all_distributor_machines,

    devices_extra_info: state.administration_modals.devices_extra_info,
    valid_devices_extra_info:
      state.administration_modals.valid_devices_extra_info,
    loading_devices_extra_info:
      state.administration_modals.loading_devices_extra_info,

    heuristic_types: state.administration_modals.heuristic_types,
    valid_heuristic_types: state.administration_modals.valid_heuristic_types,
    loading_heuristic_types:
      state.administration_modals.loading_heuristic_types,

    alert_levels: state.administration_modals.alert_levels,
    valid_alert_levels: state.administration_modals.valid_alert_levels,
    loading_alert_levels: state.administration_modals.loading_alert_levels,

    time_units: state.administration_modals.time_units,
    valid_time_units: state.administration_modals.valid_time_units,
    loading_time_units: state.administration_modals.loading_time_units,

    state_descriptions: state.administration_modals.state_descriptions,
    valid_state_descriptions:
      state.administration_modals.valid_state_descriptions,
    loading_state_descriptions:
      state.administration_modals.loading_state_descriptions,

    relational_operators: state.administration_modals.relational_operators,
    valid_relational_operators:
      state.administration_modals.valid_relational_operators,
    loading_relational_operators:
      state.administration_modals.loading_relational_operators,

    heuristic_rules: state.administration_modals.heuristic_rules,
    valid_heuristic_rules: state.administration_modals.valid_heuristic_rules,
    loading_heuristic_rules:
      state.administration_modals.loading_heuristic_rules,

    distributor_contract_plan: state.general.distributor_contract_plan,
    error_msg: state.administration_modals.error_msg,
  };
};

export const mapDispatchToPropsAdministrationModal = (dispatch) => {
  return {
    get_users: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_USERS });
      get_users(token, dispatch);
    },
    get_distributors: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_DISTRIBUTORS });
      get_distributors(token, dispatch);
    },
    get_groups: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_GROUPS });
      get_groups(token, dispatch);
    },
    get_alert_change_types: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERT_CHANGE_TYPES });
      get_alert_change_types(token, dispatch);
    },
    get_machine_sectors_types: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_MACHINE_SECTORS_TYPES });
      get_machine_sectors_types(token, dispatch);
    },
    get_non_active_distributors: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTORS });
      get_non_active_distributors(token, dispatch);
    },
    get_non_active_distributor_machines: (token, distributor_id, signal) => {
      dispatch({ type: ATTEMPT_TO_GET_NON_ACTIVE_DISTRIBUTOR_MACHINES });
      get_non_active_distributor_machines(
        token,
        distributor_id,
        signal,
        dispatch
      );
    },
    get_contract_plans: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_CONTRACT_PLANS });
      get_contract_plans(token, dispatch);
    },
    get_all_distributor_machines: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALL_DISTRIBUTOR_MACHINES });
      get_all_distributor_machines(token, dispatch);
    },
    get_devices_extra_info: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_DEVICES_EXTRA_INFO });
      get_devices_extra_info(token, dispatch);
    },
    get_heuristic_types: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_HEURISTIC_TYPES });
      get_heuristic_types(token, dispatch);
    },
    get_alert_levels: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_ALERT_LEVELS });
      get_alert_levels(token, dispatch);
    },
    get_time_units: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_TIME_UNITS });
      get_time_units(token, dispatch);
    },
    get_state_descriptions: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_STATE_DESCRIPTIONS });
      get_state_descriptions(token, dispatch);
    },
    get_relational_operators: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_RELATIONAL_OPERATORS });
      get_relational_operators(token, dispatch);
    },
    get_heuristic_rules: (token) => {
      dispatch({ type: ATTEMPT_TO_GET_HEURISTIC_RULES });
      get_heuristic_rules(token, dispatch);
    },
    create_or_update_heuristic_rule: (token, heuristic_rule_data) => {
      create_or_update_heuristic_rule(token, heuristic_rule_data, dispatch);
    },
    update_heuristic_rule_state: (token, heuristic_rule_id, is_active) => {
      update_heuristic_rule_state(
        token,
        heuristic_rule_id,
        is_active,
        dispatch
      );
    },
    update_machines_state: (token, machines_dict) => {
      update_machines_state(token, machines_dict, dispatch);
    },
    update_user_state: (token, username, is_active) => {
      update_user_state(token, username, is_active, dispatch);
    },
    create_or_update_alert_change: (token, alert_change_data) => {
      create_or_update_alert_change(token, alert_change_data, dispatch);
    },
    update_alert_change_state: (token, alert_change_id, is_active) => {
      update_alert_change_state(token, alert_change_id, is_active, dispatch);
    },
    create_or_update_machine_sectors: (token, machine_sectors_data) => {
      create_or_update_machine_sectors(token, machine_sectors_data, dispatch);
    },
    update_machine_sectors_state: (token, machine_sectors_id, is_active) => {
      update_machine_sectors_state(
        token,
        machine_sectors_id,
        is_active,
        dispatch
      );
    },
    create_or_update_user_profile: (token, user_profile) => {
      dispatch({ type: ATTEMPT_TO_CREATE_OR_UPDATE_USER_PROFILE });
      create_or_update_user_profile(token, user_profile, dispatch);
    },
    update_distributor_state: (token, distributor_id, is_active) => {
      update_distributor_state(token, distributor_id, is_active, dispatch);
    },
    update_group_state: (token, group_id, is_active) => {
      update_group_state(token, group_id, is_active, dispatch);
    },
    update_contract_plan_state: (token, contract_plan_id, is_active) => {
      update_contract_plan_state(token, contract_plan_id, is_active, dispatch);
    },
    create_or_update_group: (token, group_data) => {
      create_or_update_group(token, group_data, dispatch);
    },
    create_or_update_distributor: (token, distributor_data) => {
      create_or_update_distributor(token, distributor_data, dispatch);
    },
    create_or_update_contract_plan: (token, contract_plan_data) => {
      create_or_update_contract_plan(token, contract_plan_data, dispatch);
    },
    remove_non_active_distributor_machines: () => {
      dispatch({
        type: REMOVE_NON_ACTIVE_DISTRIBUTOR_MACHINES,
      });
    },
    reset_password: (email) => {
      dispatch({ type: ATTEMPT_TO_RESET_PASSWORD });
      reset_password(email, dispatch);
    },
    clear_error_msg: () =>
      dispatch({ type: CLEAR_ADMINISTRATION_MODALS_ERROR_MSG }),
  };
};
