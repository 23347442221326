import Box from "@material-ui/core/Box";
import DropdownInput from "../utils/components/DropdownInput";
import IconButton from "@material-ui/core/IconButton";
import Dns from "@material-ui/icons/Dns";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  largeIcon: {
    width: 30,
    height: 30,
    color: "rgb(94, 94, 94)",
  },
  largeIconContainer: {
    padding: "12px 8px",
  },
  title: {
    padding: "0px 16px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  dropdown: {
    width: "300px",
    [theme.breakpoints.between("sm", "md")]: {
      width: "150px",
    },
  },
}));

const generate_dict_for_dropdown = (dictionary, t) => {
  let output = {};
  Object.keys(dictionary).forEach((key) => {
    if (key === "0") {
      return (output[key] = t(
        "administration_bar:distributor_dropdown.default"
      ));
    } else {
      return (output[key] = dictionary[key].name);
    }
  });
  return output;
};

const generate_group_dropdown_dict = (
  selected_distributor,
  distributors_with_groups,
  t
) => {
  let output = {};
  if (selected_distributor !== "-1") {
    Object.keys(distributors_with_groups[selected_distributor].groups).forEach(
      (key) => {
        if (
          distributors_with_groups[selected_distributor].groups[key].is_default
        ) {
          output[key] = t("administration_bar:group_dropdown.default");
        } else {
          output[key] =
            distributors_with_groups[selected_distributor].groups[key].name;
        }
      }
    );
    return output;
  } else return {};
};

const DistributorAndGroupSelector = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  let disable_distributor =
    Object.keys(props.distributors_with_groups).length <= 1;
  let disable_group =
    disable_distributor ||
    Object.keys(
      props.distributors_with_groups[props.selected_distributor].groups
    ).length <= 1;

  return (
    <React.Fragment>
      <Box display={{ xs: "none", lg: "block", md: "block" }}>
        <DropdownInput
          disabled={disable_distributor}
          options={generate_dict_for_dropdown(
            props.distributors_with_groups,
            t
          )}
          value={props.selected_distributor}
          onChange={props.on_select_distributor_change}
          removeBorder={true}
          className={classes.dropdown}
        />
        <DropdownInput
          disabled={disable_group}
          options={generate_group_dropdown_dict(
            props.selected_distributor,
            props.distributors_with_groups,
            t
          )}
          value={props.selected_group}
          onChange={props.on_select_group_change}
          removeBorder={true}
          className={classes.dropdown}
        />
      </Box>

      <Box display={{ xs: "block", lg: "none", md: "none" }}>
        <IconButton
          onClick={handleClick}
          color="inherit"
          className={classes.largeIconContainer}
        >
          <Dns className={classes.largeIcon} />
        </IconButton>
      </Box>

      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem className={classes.title}>
            {t("administration_bar:filterMenuTitle")}
          </MenuItem>
          <MenuItem>
            <DropdownInput
              sorted
              disabled={disable_distributor}
              options={generate_dict_for_dropdown(
                props.distributors_with_groups,
                t
              )}
              defaultValue={0}
              value={props.selected_distributor}
              onChange={props.on_select_distributor_change}
              removeBorder={true}
            />
          </MenuItem>
          <MenuItem>
            <DropdownInput
              sorted
              disabled={disable_group}
              options={generate_group_dropdown_dict(
                props.selected_distributor,
                props.distributors_with_groups,
                t
              )}
              defaultValue={0}
              value={props.selected_group}
              onChange={props.on_select_group_change}
              removeBorder={true}
            />
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default DistributorAndGroupSelector;
