import {
  ATTEMPT_TO_LOGIN,
  ERROR_LOGIN,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  UPDATING_USER,
  UPDATING_PASSWORD,
  UPDATING_REMEMBER_ME,
  ATTEMPT_TO_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFUL,
  ATTEMPT_TO_RESET_PASSWORD_CONFIRM,
  ERROR_RESET_PASSWORD_CONFIRM,
  RESET_PASSWORD_CONFIRM_SUCCESSFUL,
  INITIAL_STATE,
  CLEAR_LOGIN_ERROR_MSG,
} from "../actions/login_action_types";

export const login_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ATTEMPT_TO_LOGIN:
      return Object.assign({}, state, { is_logging_in: true, error_msg: "" });
    case ERROR_LOGIN:
      return Object.assign({}, state, {
        is_logging_in: false,
        error_msg: action.error_msg,
      });
    case LOGIN_SUCCESSFUL:
      return Object.assign({}, state, {
        is_logging_in: false,
        auth_token: action.token,
      });

    case LOGOUT:
      return INITIAL_STATE;

    case UPDATING_USER:
      return Object.assign({}, state, { user: action.value });
    case UPDATING_PASSWORD:
      return Object.assign({}, state, { passwd: action.value });
    case UPDATING_REMEMBER_ME:
      return Object.assign({}, state, { remember_me: action.value });

    case ATTEMPT_TO_RESET_PASSWORD:
      return Object.assign({}, state, {
        loading_password_change_request: true,
      });
    case ERROR_RESET_PASSWORD:
      return Object.assign({}, state, {
        loading_password_change_request: false,
        successful_return_password_change_request: false,
        error_msg: action.error_msg,
      });
    case RESET_PASSWORD_SUCCESSFUL:
      return Object.assign({}, state, {
        loading_password_change_request: false,
        successful_return_password_change_request: true,
      });

    case ATTEMPT_TO_RESET_PASSWORD_CONFIRM:
      return Object.assign({}, state, {
        loading_password_change_confirm_request: true,
      });
    case ERROR_RESET_PASSWORD_CONFIRM:
      return Object.assign({}, state, {
        loading_password_change_confirm_request: false,
        successful_return_password_change_confirm_request: false,
        error_msg: action.error_msg,
      });
    case RESET_PASSWORD_CONFIRM_SUCCESSFUL:
      return Object.assign({}, state, {
        loading_password_change_confirm_request: false,
        successful_return_password_change_confirm_request: true,
      });

    case CLEAR_LOGIN_ERROR_MSG:
      return Object.assign({}, state, {
        error_msg: "",
      });

    default:
      return state;
  }
};
