import AlertsSet from "../../js/AlertsSet";

export const ATTEMPT_TO_GET_PERSONAL_USER_PROFILE =
  "ATTEMPT_TO_GET_PERSONAL_USER_PROFILE";
export const UPDATE_PERSONAL_USER_PROFILE = "UPDATE_PERSONAL_USER_PROFILE";
export const ERROR_GET_PERSONAL_USER_PROFILE =
  "ERROR_GET_PERSONAL_USER_PROFILE";
export const ERROR_UPDATE_PERSONAL_USER_PROFILE =
  "ERROR_UPDATE_PERSONAL_USER_PROFILE";

export const ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS =
  "ATTEMPT_TO_GET_DISTRIBUTORS_WITH_GROUPS";
export const UPDATE_DISTRIBUTORS_WITH_GROUPS =
  "UPDATE_DISTRIBUTORS_WITH_GROUPS";
export const ERROR_GET_DISTRIBUTORS_WITH_GROUPS =
  "ERROR_GET_DISTRIBUTORS_WITH_GROUPS";

export const UPDATE_SELECTED_DISTRIBUTOR = "UPDATE_SELECTED_DISTRIBUTOR";
export const UPDATE_SELECTED_GROUP = "UPDATE_SELECTED_GROUP";

export const ATTEMPT_TO_GET_DISTRIBUTOR_CONTRACT_PLAN =
  "ATTEMPT_DISTRIBUTOR_CONTRACT_PLAN";
export const UPDATE_DISTRIBUTOR_CONTRACT_PLAN =
  "UPDATE_DISTRIBUTOR_CONTRACT_PLAN";
export const ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN =
  "ERROR_GET_DISTRIBUTOR_CONTRACT_PLAN";

export const ATTEMPT_TO_GET_ALERTS = "ATTEMPT_TO_GET_ALERTS";
export const UPDATE_ALERTS = "UPDATE_ALERTS";
export const ERROR_GET_ALERTS = "ERROR_GET_ALERTS";
export const ERROR_UPDATE_ALERT = "ERROR_UPDATE_ALERT";

export const ATTEMPT_TO_GET_NONACTIVE_ALERTS =
  "ATTEMPT_TO_GET_NONACTIVE_ALERTS";
export const UPDATE_NONACTIVE_ALERTS = "UPDATE_NONACTIVE_ALERTS";
export const ERROR_GET_NONACTIVE_ALERTS = "ERROR_GET_NONACTIVE_ALERTS";
export const ERROR_UPDATE_NONACTIVE_ALERT = "ERROR_UPDATE_NONACTIVE_ALERT";

export const ATTEMPT_TO_GET_ALERT_TYPES = "ATTEMPT_TO_GET_ALERT_TYPES";
export const UPDATE_ALERT_TYPES = "UPDATE_ALERT_TYPES";
export const ERROR_GET_ALERT_TYPES = "ERROR_GET_ALERT_TYPES";

export const ATTEMPT_TO_GET_ALERTS_HASH = "ATTEMPT_TO_GET_ALERTS_HASH";
export const UPDATE_ALERTS_HASH = "UPDATE_ALERTS_HASH";
export const ERROR_GET_ALERTS_HASH = "ERROR_GET_ALERTS_HASH";
export const ERROR_UPDATE_ALERT_HASH = "ERROR_UPDATE_ALERT_HASH";

export const ATTEMPT_TO_GET_FIRST_ALERTS_HASH =
  "ATTEMPT_TO_GET_FIRST_ALERTS_HASH";
export const UPDATE_FIRST_ALERTS_HASH = "UPDATE_FRIST_ALERTS_HASH";
export const ERROR_GET_FIRST_ALERTS_HASH = "ERROR_GET_FIRST_ALERTS_HASH";
export const ERROR_UPDATE_FIRST_ALERT_HASH = "ERROR_UPDATE_FIRST_ALERT_HASH";

export const UPDATE_SINGLE_ALERT_DATA = "UPDATE_SINGLE_ALERT_DATA";
export const UPDATE_ALERTS_PAGE = "UPDATE_ALERTS_PAGE";

export const ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES =
  "ATTEMPT_TO_GET_ACTIVE_ALERT_CHANGE_TYPES";
export const UPDATE_ACTIVE_ALERT_CHANGE_TYPES =
  "UPDATE_ACTIVE_ALERT_CHANGE_TYPES";
export const ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES =
  "ERROR_GET_ACTIVE_ALERT_CHANGE_TYPES";

export const UPDATE_SELECTED_MACHINE = "UPDATE_SELECTED_MACHINE";
export const UPDATE_SEARCH_INPUT = "UPDATE_SEARCH_INPUT";

export const INITIAL_STATE = {
  valid_user_profile: false,
  loading_user_profile: false,
  valid_distributors_with_groups: false,
  loading_distributors_with_groups: false,
  valid_distributor_contract_plan: false,
  loading_distributor_contract_plan: false,
  valid_alerts: false,
  loading_alerts: false,
  valid_nonactive_alerts: false,
  loading_nonactive_alerts: false,
  valid_alert_types: false,
  loading_alert_types: false,
  valid_alerts_hash: false,
  loading_alerts_hash: false,
  valid_fist_alert_hash: false,
  loading_first_alert_hash: false,
  valid_active_alert_change_types: false,
  loading_active_alert_change_types: false,
  user_profile: {},
  distributors_with_groups: {},
  distributor_contract_plan: {},
  alerts: new AlertsSet([]),
  nonactive_alerts: new AlertsSet([]),
  alert_types: [],
  alerts_page: 1,
  alerts_hash: "",
  first_alerts_hash: "",
  error_msg: "",
  selected_distributor: "-1",
  selected_group: "-1",
  selected_machine: -1,
  search_input: "",
  active_alert_change_types: [],
};
