import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";

import { useTranslation } from "react-i18next";
import WorkingRangeOkImage from "../media/images/working_range_ok.png";
import WorkingRangeExceededImage from "../media/images/working_range_exceeded.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
      fontSize: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
    },
    itemTitle: {
      fontWeight: "bold",
      textAlign: "right",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    itemContent: {
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    workingRangeImg: {
      height: 20,
      verticalAlign: "middle",
    },
  })
);

export default function MachineAdvancedData(props) {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  if (props.selectedMachine != null) {
    let warranty_date = t("machineAdvancedData.noWarranty");
    if (props.selectedMachine.warranty_date != null) {
      warranty_date = props.selectedMachine.warranty_date;
    }

    let working_range = (
      <div className={classes.workingRangeImgDiv}>
        <img
          className={classes.workingRangeImg}
          src={WorkingRangeOkImage}
          alt="Working Range Ok"
        />
        &nbsp;&nbsp;&nbsp;{props.selectedMachine.working_range}
      </div>
    );
    if (props.selectedMachine.working_range_exceeded) {
      working_range = (
        <div>
          <img
            className={classes.workingRangeImg}
            src={WorkingRangeExceededImage}
            alt="Working Range Exceeded"
          />
          &nbsp;&nbsp;&nbsp;{props.selectedMachine.working_range}
        </div>
      );
    }
    let last_timestamp = "-";
    if (props.selectedMachine.last_timestamp != null) {
      last_timestamp = props.selectedMachine.last_timestamp;
    }

    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("machineAdvancedData.title")}
        </Box>
        <Box className={classes.boxContainer}>
          <div>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid className={classes.itemTitle} item xs={6}>
                {t("machineAdvancedData.warrantyEnd") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {warranty_date}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                {t("machineAdvancedData.firmware") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {props.selectedMachine.firmware}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                <abbr title={t("machineAdvancedData.workingRangeLabel")}>
                  <InfoIcon fontSize="small" />
                </abbr>{" "}
                {t("machineAdvancedData.workingRange") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {working_range}
              </Grid>
              <Grid className={classes.itemTitle} item xs={6}>
                {t("machineAdvancedData.lastTimestamp") + ":"}
              </Grid>
              <Grid className={classes.itemContent} item xs={6}>
                {last_timestamp}
              </Grid>
            </Grid>
          </div>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("machineAdvancedData.title")}
        </Box>
      </Paper>
    );
  }
}
