import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    actionsToDoTitle: {
      padding: "10px",
      height: "100%",
    },
    actionsToDoGridContainer: {
      height: "80%",
      textAlign: "left",
    },
    actionsToDo: {
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "left",
    },
    action: {
      marginLeft: "10%",
      marginTop: 10,
    },
    noActions: {
      fontSize: 16,
      textAlign: "center",
    },
  })
);

const ComponentActionsToDo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("scoring");

  if (props.component != null) {
    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("componentActionsToDo.title")}
        </Box>
        <Box className={classes.actionsToDoTitle}>
          <Grid
            className={classes.actionsToDoGridContainer}
            container
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <Grid item className={classes.actionsToDo} xs={12}>
              {props.component.actions_to_do.map((action) => (
                <React.Fragment key={action.position}>
                  <div className={classes.action}>
                    <a
                      href={action.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {action.position + ". " + action.description}
                    </a>
                  </div>
                </React.Fragment>
              ))}
              {props.component.actions_to_do.length <= 0 && (
                <React.Fragment key={0}>
                  <Grid item className={classes.noActions} xs={12}>
                    {t("componentActionsToDo.noActions")}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Box className={classes.componentTitle}>
          {t("componentActionsToDo.title")}
        </Box>
      </Paper>
    );
  }
};

export default ComponentActionsToDo;
