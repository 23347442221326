import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import SearchField from "../utils/components/SearchField";
import AlertItem from "./alert_modal/AlertItem";

import { dict_contains_search_input } from "../utils/js/common_functions";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import {
  mapStateToPropsAlertsModal,
  mapDispatchToPropsAlertsModal,
} from "../utils/redux/mappers/general_mappers";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "90%",
    maxWidth: "800px",
    height: "90%",
    maxHeight: "620px",
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  alerts: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
    marginTop: "0px",
  },
  alertsDiv: {
    overflowY: "auto",
    height: "100%",
    padding: "4px",
  },
  alertsDivContainer: {
    position: "relative",
    border: "1px solid rgb(158, 158, 158)",
    height: "350px",
  },
  showClosedAlerts: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "left",
  },
  showClosedAlertsSwitch: {
    float: "left",
  },
  modalContent: {
    padding: "0px 32px 20px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 50px)",
    overflowY: "auto",
  },
  closeButton: {
    padding: "8px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  noAlertsAvailable: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  loadingAlerts: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  alertUrlButton: {
    marginTop: "4px",
    backgroundColor: "white",
  },
  stringPages: {
    marginTop: "12px",
  },
}));

const AlertsModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  const [switchOpen, switchSetOpen] = React.useState(false);
  const [searchInput, searchInputSetState] = React.useState("");

  const change_open = (event) => {
    switchSetOpen(event.target.checked);
    props.update_alerts_page(1);
    if (props.nonactive_alerts.alerts.length === 0) {
      props.get_nonactive_alerts(props.auth_token);
    }
  };

  let alerts = null;
  if (switchOpen) {
    alerts = props.nonactive_alerts.get_alerts(true);
  } else {
    alerts = props.alerts.get_alerts(true);
  }
  alerts = alerts.filter((alert) =>
    dict_contains_search_input(alert, searchInput)
  );
  let alerts_count = alerts.length;

  let alert_types_dict = {};

  props.alert_types.forEach((item, i) => {
    alert_types_dict[item.code] = item;
  });

  const alert_items = () => {
    if (props.are_alerts_loading || props.are_nonactive_alerts_loading) {
      return (
        <div className={classes.loadingAlerts}>
          <CircularProgress />
          <div>{t("alertsModal.loading")}</div>
        </div>
      );
    }

    if (props.alerts_page > Math.ceil(alerts_count / 10) && alerts_count > 0) {
      props.update_alerts_page(Math.ceil(alerts_count / 10));
    }
    alerts = props.alerts.get_paginated_alerts(alerts, props.alerts_page);

    let output_alerts = [];
    for (let i = 0; i < alerts.length; ++i) {
      output_alerts.push(
        <Grid key={alerts[i].alert_id} item xs={12}>
          <AlertItem
            alertChangeTypes={props.active_alert_change_types}
            alertTypes={alert_types_dict}
            updateAlert={(alert_change) => {
              props.update_alert(
                props.auth_token,
                alert_change,
                props.alerts_hash,
                props.nonactive_alerts.alerts.length
              );
            }}
            alert={alerts[i]}
          />
        </Grid>
      );
    }
    if (output_alerts.length === 0) {
      return (
        <div className={classes.noAlertsAvailable}>
          {t("alertsModal.noAlertsAvailable")}
        </div>
      );
    }

    return output_alerts;
  };

  let string_pages = null;
  if (
    !props.are_alerts_loading &&
    !props.are_nonactive_alerts_loading &&
    alerts_count > 0
  ) {
    string_pages =
      t("tableTags.page") +
      " " +
      props.alerts_page +
      " " +
      t("tableTags.of") +
      " " +
      Math.ceil(alerts_count / 10);
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.alerts}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="center"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>{t("alertsModal.title")}</h2>
              <Divider />
            </Grid>
            <Grid item xs={8} md={12}>
              <div className={classes.showClosedAlerts}>
                {t("alertsModal.showClosedAlerts")}
              </div>
            </Grid>
            <Grid item xs={4}>
              <Switch
                className={classes.showClosedAlertsSwitch}
                checked={switchOpen}
                onChange={(event) => {
                  change_open(event);
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <SearchField value={searchInput} onChange={searchInputSetState} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.alertsDivContainer}>
                <div className={classes.alertsDiv}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="flex-start"
                    justify="center"
                  >
                    {alert_items()}
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                className={classes.alertUrlButton}
                onClick={() => {
                  props.update_alerts_page(props.alerts_page - 1);
                }}
                disabled={props.alerts_page <= 1}
              >
                <div style={{ textAlign: "right" }}>
                  {t("tableTags.previous")}
                </div>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.stringPages}>{string_pages}</div>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                className={classes.alertUrlButton}
                onClick={() => {
                  props.update_alerts_page(props.alerts_page + 1);
                }}
                disabled={props.alerts_page >= Math.ceil(alerts_count / 10)}
              >
                <div style={{ textAlign: "right" }}>{t("tableTags.next")}</div>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToPropsAlertsModal,
  mapDispatchToPropsAlertsModal
)(AlertsModal);
