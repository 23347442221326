import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import DropdownInput from "../../utils/components/DropdownInput";
import LinkMachinesModal from "./LinkMachinesModal";
import TextInput from "../../utils/components/TextInput";

import { useTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: "640px",
    maxHeight: "400px",
    width: "90%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  distributorsModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  linkMachines: {
    float: "right",
    marginRight: "0px",
    [theme.breakpoints.down("md")]: {
      float: "left",
    },
  },
  closeButton: {
    padding: "8px",
    float: "right",
    color: "rgb(152, 152, 152)",
    cursor: "pointer",
  },
  modalContent: {
    padding: "0px 32px 32px 32px",
    width: "100%",
    margin: "0px",
    height: "calc(100% - 50px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "95%",
      overflowY: "auto",
    },
  },
  alignCenter: {
    marginTop: "0px",
  },
  button: {
    margin: theme.spacing(1),
  },
  textInput: {
    width: "100%",
  },
}));

const DistributorsModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("more_options_modals");

  let contract_plans_dict = {};
  let default_contract_plan = null;
  let contract_plans = props.contractPlans;
  if (contract_plans !== null) {
    contract_plans.forEach((item, i) => {
      if (contract_plans[i]["is_active"]) {
        contract_plans_dict[item["id"]] = contract_plans[i]["name"];
        if (item["is_default"]) {
          default_contract_plan = item["id"];
        }
      }
    });
  }

  const [linkMachinesModalOpen, linkMachinesModalSetState] =
    React.useState(false);
  const [nonActiveDistributor, setNonActiveDistributor] = React.useState(
    Object.keys(props.nonActiveDistributors)[0]
  );
  const [contractPlan, setContractPlan] = React.useState(default_contract_plan);
  const [extraUsers, setExtraUsers] = React.useState("0");

  const [activateAllMachines, setActivateAllMachines] = React.useState(false);
  const [abortController, abortControllerSetState] = React.useState(false);

  React.useEffect(() => {
    if (props.nonActiveDistributors !== null) {
      setNonActiveDistributor(Object.keys(props.nonActiveDistributors)[0]);
    } else {
      setNonActiveDistributor("");
    }
  }, [props.nonActiveDistributors]);
  React.useEffect(() => {
    if (props.contractPlans !== null) {
      setContractPlan(default_contract_plan);
    } else {
      setContractPlan(null);
    }
  }, [props.contractPlans, contract_plans_dict, default_contract_plan]);

  const handleAccept = () => {
    if (extraUsers === "") {
      setExtraUsers("0");
    }
    let distributor_data = {
      id: parseInt(nonActiveDistributor),
      activate_all_machines: activateAllMachines,
      contract_plan: contractPlan,
      extra_users: parseInt(extraUsers),
    };
    if (contractPlan === null) {
      distributor_data["contract_plan"] = Object.keys(contract_plans_dict)[0];
    }
    props.createOrUpdateDistributor(distributor_data);
    props.handleClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.distributorsModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={props.handleClose}>
            <Close />
          </div>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
            className={classes.modalContent}
          >
            <Grid item xs={12}>
              <h2 className={classes.alignCenter}>
                {t("distributorsView.distributorsModal.title")}
              </h2>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownInput
                sorted
                className={classes.textInput}
                label={t("distributorsView.distributorsModal.distributor")}
                options={props.nonActiveDistributors}
                value={nonActiveDistributor}
                onChange={setNonActiveDistributor}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownInput
                sorted={false}
                className={classes.textInput}
                label={t("distributorsView.distributorsModal.contract_plan")}
                options={contract_plans_dict}
                value={contractPlan}
                onChange={setContractPlan}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                label={t("distributorsView.distributorsModal.extra_users")}
                value={extraUsers}
                onChange={(value) => {
                  let lastChar;
                  if (value.length === 0) {
                    setExtraUsers(value);
                  } else {
                    lastChar = value.substr(value.length - 1);
                    if (!isNaN(parseInt(lastChar)) && parseInt(value) >= 0) {
                      setExtraUsers(value);
                    }
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              spacing={1}
              alignItems="flex-start"
              justify="flex-start"
            >
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.linkMachines}
                  control={
                    <Checkbox
                      value={activateAllMachines}
                      onChange={(value) =>
                        setActivateAllMachines(value.target.checked)
                      }
                    />
                  }
                  label={t("distributorsView.distributorsModal.allMachines")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.linkMachines}
                  onClick={() => {
                    const controller = new AbortController();
                    const signal = controller.signal;

                    abortControllerSetState(controller);
                    props.getDistributorMachines(nonActiveDistributor, signal);
                    linkMachinesModalSetState(true);
                  }}
                >
                  {t("distributorsView.distributorsModal.linkMachines")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleAccept}
                >
                  {t("distributorsView.distributorsModal.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={props.handleClose}
                >
                  {t("distributorsView.distributorsModal.cancel")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <LinkMachinesModal
        open={linkMachinesModalOpen}
        handleClose={() => {
          abortController.abort();
          props.removeNonActiveDistributorMachines();
          abortControllerSetState(null);
          linkMachinesModalSetState(false);
        }}
        handleAcceptButton={() => {
          abortController.abort();
          props.removeNonActiveDistributorMachines();
          abortControllerSetState(null);
          linkMachinesModalSetState(false);
          props.handleClose();
        }}
        distributorMachines={props.distributorMachines}
        activateAllMachines={activateAllMachines}
        createOrUpdateDistributor={props.createOrUpdateDistributor}
        loadingNonActiveDistributorMachines={
          props.loadingNonActiveDistributorMachines
        }
        distributorId={nonActiveDistributor}
      />
    </div>
  );
};

export default DistributorsModal;
