import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Lock from "@material-ui/icons/Lock";
import Person from "@material-ui/icons/Person";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  userPasswdInput: {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
    marginLeft: "0.5em",
    color: "#000",
  },
  rememberMeCheckbox: {
    marginLeft: "0",
    width: "100%",
  },
  loginContainerBox: {
    margin: "1em",
    cursor: "pointer",
  },
  loginErrorMsg: {
    margin: "0.5em",
    color: "red",
    textAlign: "center",
    fontSize: "1em",
  },
}));

/**
 *  Base dropdown component for all the dropdowns of **Predik** application.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
const LoginForm = (props) => {
  const { t } = useTranslation("login");
  const classes = useStyles();

  const checkEnterKeyPressed = (e) => {
    if (e.key === "Enter") {
      props.onLoginClick();
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item>
          <Person />
        </Grid>
        <Grid item>
          <TextField
            onKeyPress={(e) => checkEnterKeyPressed(e)}
            error={props.errorMsg !== ""}
            className={classes.userPasswdInput}
            onChange={(value) => props.onUserInputChange(value)}
            label={t("User")}
            value={props.userValue}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item>
          <Lock />
        </Grid>
        <Grid item>
          <TextField
            onKeyPress={(e) => checkEnterKeyPressed(e)}
            error={props.errorMsg !== ""}
            className={classes.userPasswdInput}
            type="password"
            onChange={(value) => props.onPasswordInputChange(value)}
            label={t("Password")}
            value={props.passwordValue}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormHelperText className={classes.loginErrorMsg}>
          {props.errorMsg}
        </FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.loginContainerBox}>
          <FormControlLabel
            id={classes.rememberMeCheckbox}
            control={
              <Checkbox
                value={props.rememberMeValue}
                onChange={(value) => props.onRememberMeCheckBoxChange(value)}
              />
            }
            label={t("Remember me")}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.loginContainerBox}>
          <Fab
            variant="extended"
            aria-label="delete"
            className="asd"
            onClick={props.onLoginClick}
          >
            {t("Init Session")}
          </Fab>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.loginContainerBox}>
          <Link onClick={() => props.push("/reset")} color="inherit">
            {t("Forgot Password")}
          </Link>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default connect(null, { push })(LoginForm);
