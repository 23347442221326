import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { SERVER_URL } from "./utils/api_calls/common/routes";

import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

//Production loadPath: SERVER_URL + '/static/{{lng}}/{{ns}}.json'
//Development loadPath: 'locales/{{lng}}/{{ns}}.json'

const LOADPATH =
  process.env.NODE_ENV === "development"
    ? "locales/{{lng}}/{{ns}}.json"
    : SERVER_URL + "/static/{{lng}}/{{ns}}.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    backend: {
      loadPath: LOADPATH,
    },
    fallbackLng: {
      "en-US": ["en"],
      default: ["en"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
