import React from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NotificationImportant from "@material-ui/icons/NotificationImportant";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import NavigationLinks from "./NavigationLinks";

import { DRAWER_WIDTH } from "../utils/constants/common_constants";

import ChasKeeperLogoGrey from "../media/images/cashkeeper_grey.png";
import PredikClose from "../media/images/predik_close.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: "rgb(102, 102, 102)",
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: "1px solid rgb(152, 152, 152)",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    backgroundColor: "rgb(237, 237, 237)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "64px",
  },
  predikIconSidebar: {
    width: 160,
  },
  hidden: {
    display: "none",
  },
  predikClose: {
    width: 50,
  },
  predikCloseButton: {
    padding: "2px",
  },
  datisionDiv: {
    bottom: "0px",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
  },
  navigationLinksMenu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  divider: {
    height: "2px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  panal: {
    height: 40,
    width: 40,
    marginTop: 18,
    marginLeft: 10,
  },
  datisionLogo: {
    height: 15,
    width: 80,
    marginRight: 4,
  },
  panalDiv: {
    float: "left",
  },
  datisionFrameworkDiv: {
    float: "right",
    height: "76px",
    width: "185px",
    color: "rgb(152, 152, 152)",
    textAlign: "left",
    paddingTop: 20,
    paddingLeft: 10,
    cursor: "pointer",
  },
  datisionFrameworkDivClosed: {
    height: "76px",
    backgroundColor: "black",
    cursor: "pointer",
  },
  datisionSymbol: {
    height: 20,
    width: 25,
    marginTop: 25,
  },
  listItem: {
    paddingLeft: "22px",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  link: {
    fontWeight: "bold",
    color: "rgb(152, 152, 152)",
    fontSize: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icons: {
    color: "rgb(255, 163, 15)",
    width: 30,
    height: 30,
  },
}));

const NavigationBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("navigation_bar");

  const handleUpdateAlerts = () => {
    props.get_alerts(props.auth_token);
    props.get_first_alerts_hash(props.auth_token);
    props.get_machines(props.auth_token);
  };

  let updateAlerts;
  if (
    props.alerts_hash !== undefined &&
    props.first_alerts_hash !== undefined
  ) {
    if (props.alerts_hash !== props.first_alerts_hash) {
      updateAlerts = (
        <div>
          <Divider className={classes.divider} />
          <ListItem
            className={clsx(classes.listItem, classes.alertButton)}
            button
            onClick={handleUpdateAlerts}
          >
            <ListItemIcon>
              <NotificationImportant className={classes.icons} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classes.link}
              primary={t("Alerts")}
            />
          </ListItem>
        </div>
      );
    }
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !props.open && classes.drawerPaperClose
        ),
      }}
      open={props.open}
    >
      <div className={classes.toolbarIcon}>
        <img
          className={clsx(
            classes.predikIconSidebar,
            !props.open && classes.hidden
          )}
          src={ChasKeeperLogoGrey}
          alt="fireSpot"
        />
        <IconButton
          className={clsx(!props.open && classes.hidden)}
          onClick={props.handleDrawerClose}
        >
          <ChevronLeftIcon />
        </IconButton>

        <IconButton
          className={clsx(
            classes.predikCloseButton,
            props.open && classes.hidden
          )}
          onClick={props.handleDrawerOpen}
        >
          <img
            className={classes.predikClose}
            src={PredikClose}
            alt="fireSpot"
          />
        </IconButton>
      </div>

      <div className={classes.navigationLinksMenu}>
        <div className={classes.navLinksDiv}>
          <Divider />
          <NavigationLinks
            view_path={props.view_path}
            distributor_contract_plan={props.distributor_contract_plan}
            isDistributorAdmin={props.isDistributorAdmin}
          />
        </div>
        {updateAlerts}
      </div>
    </Drawer>
  );
};

export default NavigationBar;
