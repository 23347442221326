import {
  MACHINES_URL,
  UPDATE_MACHINE_URL,
  MODEL_HISTORY_URL,
  ACTIVE_MACHINE_SECTORS_URL,
} from "./common/routes";
import { STATUS_OK, UNAUTHORIZED } from "./common/fetch_status_codes";
import {
  UPDATE_SINGLE_MACHINE_DATA,
  UPDATE_MACHINES,
  UPDATE_ACTIVE_MACHINE_SECTORS,
  UPDATE_MODEL_HISTORY,
  ERROR_UPDATE_MACHINES,
  ERROR_UPDATE_ACTIVE_MACHINE_SECTORS,
  ERROR_UPDATE_MACHINE_DATA,
  ERROR_UPDATE_MODEL_HISTORY,
} from "../redux/actions/machines_action_types";
import { logout } from "./login_api_calls";

export const get_machines = (token, dispatch) => {
  fetch(MACHINES_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_MACHINES,
          machines: data.body,
          sort_input: true,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_MACHINES,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_MACHINES,
        error_msg: "",
      });
    });
};

export const update_machine = (token, machine_data, dispatch) => {
  fetch(UPDATE_MACHINE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(machine_data),
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        let machine = machine_data;
        machine["longitude"] = data.body.new_machine_coordinates.longitude;
        machine["latitude"] = data.body.new_machine_coordinates.latitude;
        dispatch({
          type: UPDATE_SINGLE_MACHINE_DATA,
          machine: machine,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_MACHINE_DATA,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_MACHINE_DATA,
        error_msg: "",
      });
    });
};

export const get_active_machine_sectors = (token, dispatch) => {
  fetch(ACTIVE_MACHINE_SECTORS_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_ACTIVE_MACHINE_SECTORS,
          active_machine_sectors: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_ACTIVE_MACHINE_SECTORS,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_ACTIVE_MACHINE_SECTORS,
        error_msg: "",
      });
    });
};

export const get_model_history = (token, dispatch) => {
  fetch(MODEL_HISTORY_URL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then((data) => {
      if (data.status === STATUS_OK) {
        dispatch({
          type: UPDATE_MODEL_HISTORY,
          model_history: data.body,
        });
      } else if (data.status === UNAUTHORIZED) {
        logout(dispatch);
      } else {
        dispatch({
          type: ERROR_UPDATE_MODEL_HISTORY,
          error_msg: "",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_MODEL_HISTORY,
        error_msg: "",
      });
    });
};
