import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import clsx from "clsx";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "300px",
  },
}));

// Function that generate the options tag structure for the dropdown component. If sorted == true, the output list is sorted
// (at the cost of O(nlogn) to sort the dictionary and transform it into an array)
const generate_options = (dictionary, sorted) => {
  let output = [];
  if (sorted) {
    let output_aux = [];
    Object.keys(dictionary).forEach((key) =>
      output_aux.push([key, dictionary[key]])
    );
    output_aux.sort(function (a, b) {
      if (a[0] === "0") {
        return 1;
      } else if (b[0] === "0") {
        return 1;
      }
      return a[1].localeCompare(b[1]);
    });
    for (let i = 0; i < output_aux.length; ++i) {
      output.push(
        <option key={output_aux[i][0]} value={output_aux[i][0]}>
          {output_aux[i][1]}
        </option>
      );
    }
  } else {
    Object.keys(dictionary).forEach((key) =>
      output.push(
        <option key={key} value={key}>
          {dictionary[key]}
        </option>
      )
    );
  }
  return output;
};

/**
 *  Base dropdown component for all the dropdowns of **Predik** application.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function DropdownInput(props) {
  const classes = useStyles();

  let variant = "standard";
  if (!props.removeBorder) {
    variant = "outlined";
  }
  let sorted = true;
  if (props.sorted != null && !props.sorted) {
    sorted = false;
  }

  return (
    <TextField
      disabled={props.disabled}
      select
      label={props.label}
      value={props.value}
      className={clsx(classes.textField, props.className)}
      onChange={(event) => props.onChange(event.target.value)}
      SelectProps={{
        native: true,
      }}
      margin="normal"
      variant={variant}
    >
      {generate_options(props.options, sorted)}
    </TextField>
  );
}

DropdownInput.defaultProps = {
  removeBorder: false,
};

DropdownInput.propTypes = {
  /** If true, dropdown is disabled (is not possible to change the value of the dropdown).*/
  disabled: PropTypes.bool,
  /** Tag that it is going to be shown over the dropdown.*/
  label: PropTypes.string,
  /** Name of the component that is going to be used in order to change its style in Material-UI/.css.*/
  className: PropTypes.string,
  /** Function that is going to be called on dropdown change. It must be a monad, with the new key to use as value as the only parameter.
   * This function should change _value_ content.
   * */
  onChange: PropTypes.func,
  /** If true, the resulting dropdown is sorted based on values in _options_. The sorting is done alphabetically, from A to Z.*/
  sorted: PropTypes.bool,
  /** Dictionary that contains all the options of the dropdown. The keys of the dictionary are going to be used to point the
   * selected element, and the value of each key is the element that is going to be displayed.
   */
  options: PropTypes.object,
  /** If true, border is not shown in the dropdown */
  removeBorder: PropTypes.bool,
};
