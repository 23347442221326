import MachinesSet from "../../js/MachinesSet";

export const UPDATE_MACHINES = "UPDATE_MACHINES";
export const ATTEMPT_TO_GET_MACHINES = "ATTEMPT_TO_GET_MACHINES";
export const ERROR_UPDATE_MACHINES = "ERROR_UPDATE_MACHINES";
export const UPDATE_ACTIVE_MACHINE_SECTORS = "UPDATE_ACTIVE_MACHINE_SECTORS";
export const ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS =
  "ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS";
export const ERROR_UPDATE_ACTIVE_MACHINE_SECTORS =
  "ERROR_UPDATE_ACTIVE_MACHINE_SECTORS";
export const ERROR_UPDATE_MACHINE_DATA = "ERROR_UPDATE_MACHINE_DATA";
export const UPDATE_SINGLE_MACHINE_DATA = "UPDATE_SINGLE_MACHINE_DATA";
export const ATTEMPT_TO_GET_MODEL_HISTORY = "ATTEMPT_TO_GET_MODEL_HISTORY";
export const UPDATE_MODEL_HISTORY = "UPDATE_MODEL_HISTORY";
export const ERROR_UPDATE_MODEL_HISTORY = "ERROR_UPDATE_MODEL_HISTORY";

export const INITIAL_STATE = {
  valid_machines: false,
  loading_machines: false,
  machines: new MachinesSet([]),
  valid_machine_sectors: false,
  loading_machine_sectors: false,
  valid_model_history: false,
  loading_model_history: false,
  active_machine_sectors: {},
  model_history: {},
};
