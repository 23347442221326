import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Styles that are going to be used in the application (provided by Material-UI instead of .css)
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    border: "1px solid rgb(158, 158, 158)",
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
  },
}));

/**
 *  SearchBar used in all the components of the application that currently requires a search bar to search in registers.
 *
 * @version 1.0.0
 * @author Ferran Martínez (f.martinez@datision.com)
 *
 */
export default function SearchField(props) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={t("searchField.placeholder")}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
}

SearchField.propTypes = {
  /** Current value to show in the SearchField. Is the string that is going to be trying to find in the different components.*/
  value: PropTypes.string,
  /** Function that is going to be called on _value_ change. This function must change de value of _value_ prop and must be a monad with the
   * new value of _value_ as a parameter.
   * */
  onChange: PropTypes.func,
};
