import {
  UPDATE_MACHINES,
  ATTEMPT_TO_GET_MACHINES,
  ERROR_UPDATE_MACHINES,
  UPDATE_ACTIVE_MACHINE_SECTORS,
  ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS,
  ERROR_UPDATE_ACTIVE_MACHINE_SECTORS,
  ERROR_UPDATE_MACHINE_DATA,
  UPDATE_SINGLE_MACHINE_DATA,
  UPDATE_MODEL_HISTORY,
  ERROR_UPDATE_MODEL_HISTORY,
  INITIAL_STATE,
} from "../actions/machines_action_types";
import MachinesSet from "../../js/MachinesSet";

export const machines_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_MACHINES:
      let sort_input = false;
      if ("sort_input" in action) {
        sort_input = action.sort_input;
      }
      return Object.assign({}, state, {
        machines: new MachinesSet(action.machines, sort_input),
        loading_machines: false,
        valid_machines: true,
      });

    case ATTEMPT_TO_GET_MACHINES:
      return Object.assign({}, state, {
        machines: new MachinesSet([]),
        loading_machines: true,
      });

    case ERROR_UPDATE_MACHINES:
      return Object.assign({}, state, {
        valid_machines: false,
        loading_machines: false,
        error_msg: action.error_msg,
      });

    case ERROR_UPDATE_MACHINE_DATA:
      return Object.assign({}, state, {
        error_msg: action.error_msg,
      });

    case UPDATE_ACTIVE_MACHINE_SECTORS:
      return Object.assign({}, state, {
        active_machine_sectors: action.active_machine_sectors,
        loading_active_machine_sectors: false,
        valid_active_machine_sectors: true,
      });

    case ATTEMPT_TO_GET_ACTIVE_MACHINE_SECTORS:
      return Object.assign({}, state, {
        loading_active_machine_sectors: true,
      });

    case ERROR_UPDATE_ACTIVE_MACHINE_SECTORS:
      return Object.assign({}, state, {
        valid_active_machine_sectors: false,
        loading_active_machine_sectors: false,
        error_msg: action.error_msg,
      });

    case UPDATE_SINGLE_MACHINE_DATA:
      let machines_set = state.machines;
      machines_set.update_machine(action.machine);
      return Object.assign({}, state, {
        machines: new MachinesSet(machines_set.get_machines()),
      });

    case UPDATE_MODEL_HISTORY:
      return Object.assign({}, state, {
        model_history: action.model_history,
        valid_model_history: true,
        loading_model_history: false,
      });

    case ERROR_UPDATE_MODEL_HISTORY:
      return Object.assign({}, state, {
        valid_model_history: false,
        loading_model_history: false,
        error_msg: action.error_msg,
      });

    default:
      return state;
  }
};
