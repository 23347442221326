import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import { SERVER_URL } from "../utils/api_calls/common/routes";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    border: "1px solid black",
    borderRadius: "0px",
    height: "255px",
  },
  machineImage: {
    maxHeight: "160px",
    maxWidth: "160px",
    [theme.breakpoints.between("md", "lg")]: {
      maxHeight: "120px",
      maxWidth: "120px",
    },
  },
  machineTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    marginTop: "4px",
    wordBreak: "break-all",
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "20px",
    },
  },
}));

const MachineImage = (props) => {
  const classes = useStyles();

  const borderStyle = {
    borderLeft: "8px solid " + props.stateColor,
    width: "100%",
    height: "110%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Card className={classes.card}>
      <div style={borderStyle}>
        <div className={classes.machineImageInnerContainer}>
          <img
            className={classes.machineImage}
            src={SERVER_URL + props.img_path}
            alt={"comp"}
          />
          <div className={classes.machineTitle}>{props.title}</div>
        </div>
      </div>
    </Card>
  );
};

export default MachineImage;
