import React from "react";

import clsx from "clsx";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
      fontSize: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
    },
    root: {
      height: "255px",
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid black",
    },
    componentTitle: {
      backgroundColor: "rgb(158, 158, 158)",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "6px",
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(255px - 40px)",
      fontSize: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
    },
    icon: {
      height: 180,
      width: 180,
      [theme.breakpoints.down("xs")]: {
        height: 70,
        width: 70,
      },
      [theme.breakpoints.down("md")]: {
        height: 90,
        width: 90,
      },
    },
    header: {
      textAlign: "right",
      paddingRight: "6px",
      fontWeight: "bold",
    },
    content: {
      textAlign: "left",
      paddingLeft: "6px",
    },
    headerContentTextSize: {
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  })
);

const ComponentModelData = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("predictive");

  const generate_risk_displayer = () => {
    if (props.component != null) {
      return (
        <div>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.header)}
              xs={6}
            >
              {t("componentModelData.R2")}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.content)}
              xs={6}
            >
              {props.modelHistory !== undefined && props.modelHistory.r2}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.header)}
              xs={6}
            >
              {t("componentModelData.MSE")}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.content)}
              xs={6}
            >
              {props.modelHistory !== undefined && props.modelHistory.mse}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.header)}
              xs={6}
            >
              {t("componentModelData.pearson")}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.content)}
              xs={6}
            >
              {props.modelHistory !== undefined &&
                props.modelHistory.pearson_corr}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.header)}
              xs={6}
            >
              {t("componentModelData.validation")}
            </Grid>
            <Grid
              item
              className={clsx(classes.headerContentTextSize, classes.content)}
              xs={6}
            >
              {props.modelHistory !== undefined && props.modelHistory.num_test}
            </Grid>
          </Grid>
        </div>
      );
    } else return null;
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.componentTitle}>
        {t("componentModelData.title")}
      </Box>
      <CardContent className={classes.boxContainer}>
        {generate_risk_displayer()}
      </CardContent>
    </Paper>
  );
};

export default ComponentModelData;
